<template>
  <div class="splitTransactionMain">
    <b-col class="col-12 transactionDetails">
        <b-col md="12">
          <b class="mr-2">{{ transactionDate }} ,</b>
          <b class="mr-2">{{ transactionTitle }} ,</b>
          <b class="mr-2">{{ transactionAmount | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso) }}</b>
        </b-col>
    </b-col>
    <b-col>
      <template v-for="(transactionItem, transactionKey) in transactions">
        <b-row :key="transactionKey">
          <b-col md="3">
            <b-form-group
              label-cols-md="12"
              :label="$t(translationPath + 'labels.title')"
              label-align="top"
              label-for="transactiondescription"
              required
            >
              <b-form-input type="text" v-model="transactionItem.title"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3" class="pl-0">
            <b-form-group
              label-cols="12"
              label-align="top"
              :label="$t(translationPath + 'labels.type')"
            >
              <b-form-select
                v-model="transactionItem.type"
                :options="transactionTypeOptions"
                class="marginLeft-2"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="3" class="pl-0">
            <b-form-group
                label-cols="12"
                label-align="top"
                :label="currentUser.strict_accounting_mode ? $t(translationPath + 'labels.account') : $t(translationPath + 'labels.category')"
              >
                <table-custom-multiselect
                  :placeHolder="currentUser.strict_accounting_mode ? $t('common.select_account') : $t('common.select_category')"
                  :searchPlaceholder="$t('common.type_to_filter')"
                  :noResultText="currentUser.strict_accounting_mode ? $t('common.no_account_found') : $t('common.no_category_found')"
                  :accounttype="transactionItem.type"
                  v-model="transactionItem.account"
                  @account-selected="(value) => transactionItemAccount(transactionKey, value)"
                  :key="'mass-edit-select_' + transactionKey"
                />
              </b-form-group>
          </b-col>
          <b-col md="3" class="pl-0">
            <b-form-group class="mb-0" label-cols="12" label-align="top" :label="$t('common.amount')">
                <currency-input
                  :ref="'CurrencyInputSplittedTransaction' + transactionKey"
                  v-model="transactionItem.amount"
                  :distraction-free="options.amount"
                  @input="handleChangeAmount"
                />
              </b-form-group>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col class="text-right">
          {{ $t('common.difference')}} : {{ transactionAmountDiff | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso) }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          {{ $t('common.total')}} : {{ totalTransactionAmount | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso) }}
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import CurrencyInput from '@/components/common/CurrencyInput'
import formatAmount from '@/assets/filters/formatAmount'
import TableCustomMultiselect from '@/components/common/TableCustomMultiselect'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'

export default {
  name: 'SplitTransactionComponent',
  components: {
    TableCustomMultiselect,
    CurrencyInput
  },
  mixins: [useComponentCurrencyInputMixin],
  filters: {
    formatAmount
  },
  props: {
    offsetAccountName: {
      type: String,
      default: null
    },
    currentUser: {
      type: Object,
      default: function () {
        return {}
      }
    },
    currentCOA: {
      type: Object,
      default: function () {
        return {}
      }
    },
    transactionAmount: {
      type: Number,
      default: 0
    },
    transactionDate: {
      type: String,
      default: '20-10-2021'
    },
    transactionTitle: {
      type: String,
      default: null
    },
    transactionType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      transactions: [
        {
          title: null,
          type: null,
          amount: 0,
          account: null
        },
        {
          title: null,
          type: null,
          amount: 0,
          account: null
        }
      ],
      translationPath: 'common.masseditform.',
      accountsOption: [],
      loadingAccounts: false,
      options: {
        amount: {
          hideCurrencySymbol: true
        }
      }
    }
  },
  computed: {
    validAmount () {
      return this.transactionAmount === this.totalTransactionAmount
    },
    totalTransactionAmount () {
      return this.transactions.map((item) => item.amount).reduce((acc, item) => acc + item)
    },
    transactionAmountDiff () {
      return this.transactionAmount - this.totalTransactionAmount
    },
    disableSave () {
      return !this.validAmount || this.transWithoutCategoryExists
    },
    transWithoutCategoryExists () {
      const transWithoutCategory = this.transactions.filter((item) => !item.account)
      return transWithoutCategory.length > 0
    },
    transactionTypeOptions () {
      return [
        { value: 'income', text: this.$t('common.transaction_types.deposit') },
        {
          value: 'expense',
          text: this.$t('common.transaction_types.withdrawal')
        },
        { value: 'refund', text: this.$t('common.transaction_types.refund') },
        {
          value: 'transfer_to',
          text: this.$t(
            this.translationPath + 'transaction_type_options.transfer_to',
            { offsetAccountName: this.offsetAccountName }
          )
        },
        {
          value: 'transfer_from',
          text: this.$t(
            this.translationPath + 'transaction_type_options.transfer_from',
            { offsetAccountName: this.offsetAccountName }
          )
        }
      ]
    }
  },
  methods: {
    handleChangeAmount (value) {
      const diffAmount = Math.round((this.transactionAmount - value) * 100) / 100 // Round to fix floating point issue
      this.transactions = this.transactions.map((el) => {
        if (el.amount === value) return el
        return Object.assign({}, el, { amount: diffAmount > 0 ? diffAmount : 0 })
      })
      this.rerenderCurrencyInputs()
    },
    emitSaveEvent (currentId = null) {
      const updatedTransactions = this.transactions.map((item) => {
        const { account, title, type, amount } = item
        return {
          title,
          raw_string: title,
          type,
          amount,
          account,
          date: this.transactionDate
        }
      })
      this.$emit('save', {
        transactions: updatedTransactions,
        currentId
      })
    },
    transactionItemAccount (key, value) {
      this.transactions[key].account = value.accounts
    }
  },
  watch: {
    transactionTitle: {
      handler: function (newValue) {
        if (newValue) {
          this.transactions[0].title = newValue
          this.transactions[1].title = newValue
        }
      },
      immediate: true
    },
    transactionAmount: {
      handler: function (newValue) {
        if (newValue) {
          const transValue = newValue
          const transDividedAmount = Math.round(parseFloat(transValue) / 2)
          const transDifference = parseFloat(transValue) - parseFloat(transDividedAmount)
          this.transactions[0].amount = transDividedAmount
          this.transactions[1].amount = transDifference
        }
      },
      immediate: true
    },
    transactionType: {
      handler: function (newValue) {
        if (newValue) {
          this.transactions[0].type = newValue
          this.transactions[1].type = newValue
        }
      },
      immediate: true
    },
    disableSave: {
      handler: function () {
        this.$emit('disable-save', this.disableSave)
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.transactionDetails {
    background: #86cbac;
    padding: 10px 30px;
    color: #fff;
}
.splitTransactionMain {
  input {
    max-width: 100% !important;
    padding: 7px 5px 5px 5px !important;
  }
}
</style>
