<template>
    <custom-select
        v-model="option_value"
        :options="options"
        grouping-values="accounts"
        grouping-label="title"
        :label="'title'"
        :loading="loadingAccounts"
        @select="optionSelected"
        @open="openHandler"
        :placeholder="placeHolder"
        :search-placeholder="searchPlaceholder"
        :noResultText="noResultText"
        :sortGroupLabel="sortGroupLabel"
        :extraWidth="extra"
        :enableReload="enableReload"
        @reload-content="fetchAccounts(true)"
    >
    </custom-select>
</template>
<script>
// import axios from 'axios'
import { SET_ACCOUNT_GROUPS_MULTISELECT } from '@/store/actions/settings'
import _ from 'lodash'
import CustomSelect from '@/components/common/CustomSelect'
import { mapState } from 'vuex'
export default {
  name: 'tableCustomMultiselect',
  props: {
    accounttype: {
      type: String,
      default: 'asset'
    },
    selectedType: {
      type: String,
      default: null
    },
    initialValue: {
      type: Array,
      default: function () {
        return []
      }
    },
    noResultText: {
      type: String,
      default: 'No result found'
    },
    value: {
      type: null,
      default () {
        return null
      }
    },
    sortLocal: {
      type: Boolean,
      default: false
    },
    placeHolder: {
      type: String,
      default: 'Select option'
    },
    searchPlaceholder: {
      type: String,
      default: 'Type to search'
    },
    extraWidth: {
      type: Number,
      default: 0
    },
    enableReload: {
      type: Boolean,
      default: false
    },
    disableRequest: {
      type: Boolean,
      default: false
    }
  },
  components: { CustomSelect },
  data () {
    return {
      options: [],
      transaction_type: null,
      loadingAccounts: false,
      option_value: this.value,
      enableMultipleAccount: false,
      optionsArray: [],
      sortGroupLabel: true,
      extra: 0
    }
  },
  created () {
    if (this.initialValue && typeof this.initialValue === 'object' && Array.isArray(this.initialValue) && this.initialValue.length > 0) {
      this.options = this.initialValue
    } else if (!this.initialValue && this.accounttype) {
      this.transaction_type = this.accounttype
      this.fetchAccounts()
    }
  },
  computed: {
    ...mapState({
      accountGroups: state => state.settings.accountGroups
    }),
    local () {
      return this.$i18n.locale
    },
    accountTypeComputed: {
      get: function () {
        return this.accounttype
      },
      set: function (value) {
        this.transaction_type = value
      }
    }
  },
  methods: {
    detectAccountType (val) {
      let AccountType = null
      switch (val) {
        case 'income':
          AccountType = 'income'
          break
        case 'refund':
          AccountType = 'expense'
          break
        case 'expense':
          AccountType = 'expense'
          break
        default:
          AccountType = 'asset,liability'
      }
      return AccountType
    },
    async fetchAccounts (reload = false) {
      this.loadingAccounts = true
      this.optionsArray = []
      let AccountType = null
      if (this.accountTypeComputed) {
        AccountType = this.detectAccountType(this.accountTypeComputed)
        let optionsArray = []
        if (reload) {
          await this.$store.dispatch(SET_ACCOUNT_GROUPS_MULTISELECT)
        }
        optionsArray = typeof this.accountGroups[AccountType] !== 'undefined' &&
            this.accountGroups[AccountType] ? this.accountGroups[AccountType] : null
        if ((!optionsArray && AccountType === 'asset,liability')) {
          if ((!this.accountGroups.asset || !this.accountGroups.liability)) {
            await this.$store.dispatch(SET_ACCOUNT_GROUPS_MULTISELECT)
          }
          this.$nextTick(() => {
            this.optionsArray = AccountType !== 'asset,liability' ? this.accountGroups[AccountType] : [
              ...this.accountGroups.asset,
              ...this.accountGroups.liability
            ]
          })
        } else if (!optionsArray) {
          await this.$store.dispatch(SET_ACCOUNT_GROUPS_MULTISELECT)
          this.$nextTick(() => {
            this.optionsArray = this.accountGroups[AccountType]
          })
        } else {
          this.$nextTick(() => {
            this.optionsArray = optionsArray
          })
        }
        this.loadingAccounts = false
      }
    },
    optionSelected (selectedVal) {
      this.$emit('account-selected', { accounts: selectedVal })
    },
    removeSelected (selectedVal) {
      this.$emit('account-remove')
    },
    openHandler () {
      if (!this.transaction_type && this.accounttype && this.options.length === 0) {
        this.transaction_type = this.accounttype
        this.fetchAccounts()
      }
    }
  },
  watch: {
    accounttype: function (newVal) {
      if (newVal && this.disableRequest === false) {
        this.transaction_type = newVal
        this.fetchAccounts()
      }
    },
    optionsArray: {
      handler: function (val) {
        if (val) {
          const optionArray = []
          const AccountType = this.detectAccountType(this.accountTypeComputed)
          Object.keys(val).forEach(function (key) {
            const AssociateAccounts = val[key].accounts.filter((el) => el.status !== 'suspended')
            if (AssociateAccounts.length > 0) {
              if (AccountType === 'asset,liability') {
                optionArray.push({ title: (this.$te('common.' + val[key].type.toLowerCase()) ? this.$t('common.' + val[key].type.toLowerCase()) : val[key].type) + ' / ' + val[key].title, type: val[key].type, accounts: AssociateAccounts })
              } else {
                optionArray.push({ title: val[key].title, type: val[key].type, accounts: AssociateAccounts })
              }
            }
          }.bind(this))
          this.options = AccountType === 'asset,liability' ? optionArray.sort(function (a, b) {
            const nameA = a.type
            const nameB = b.type
            if (this.sortLocal) return nameA.localeCompare(nameB, this.local)
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }.bind(this)) : optionArray
          if (this.selectedType) {
            const filterOptions = this.options.filter(item => item.accounts).map(items => items.accounts)
            const accountsArray = _.flattenDeep(filterOptions)
            const filterAccountsArray = accountsArray.filter(item => this.option_value === item.id)
            this.option_value = filterAccountsArray.length > 0 ? filterAccountsArray[0] : null
          }
          this.$emit('update-inital', {
            type: this.transaction_type,
            options: this.options
          })
        }
      },
      immediate: true,
      deep: true
    },
    local: function (newVal) {
      if (this.options.length > 0 && newVal) {
        const optionArray = []
        const accountGroups = _.clone(this.accountGroups)
        Object.keys(accountGroups).forEach(function (key) {
          const AssociateAccounts = accountGroups[key].accounts.filter((el) => el.status !== 'suspended')
          if (AssociateAccounts.length > 0) {
            if (['income', 'expense', 'refund'].indexOf(this.transaction_type) === -1) {
              optionArray.push({ title: (this.$te('common.' + accountGroups[key].type.toLowerCase()) ? this.$t('common.' + accountGroups[key].type.toLowerCase()) : accountGroups[key].type) + ' / ' + accountGroups[key].title, type: accountGroups[key].type, accounts: AssociateAccounts })
            } else {
              optionArray.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: AssociateAccounts })
            }
          }
        }.bind(this))
        this.options = ['income', 'expense', 'refund'].indexOf(this.transaction_type) === -1 ? optionArray.sort(function (a, b) {
          const nameA = a.type
          const nameB = b.type
          if (this.sortLocal) return nameA.localeCompare(nameB, this.local)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        }.bind(this)) : optionArray
      }
    },
    initialValue: function (newVal) {
      this.options = newVal
    },
    value: function (newVal) {
      this.option_value = newVal
    },
    extraWidth: function (newVal) {
      if (newVal) {
        this.extra = newVal
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tableCustomMultiselect {
  .multiselect__placeholder {
    margin-bottom: 6px;
  }
}
</style>
