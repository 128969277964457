<template>
  <b-modal ref="addTransactionModal" size="lg" centered hide-footer no-close-on-backdrop
           modal-class="spirecta-modal transactionModel">
    <div slot="modal-header" class="w-100 mx-auto text-center">
      <button type="button" class="close" aria-label="Close" @click="closeModal">
        <span aria-hidden="true">×</span>
      </button>
      <div class="mt-3 mt-lg-4 border-bottom pb-2 pb-lg-3 mb-2 mb-lg-4">
        <h1 class="modal-header-primary">{{$t(translateKey + 'heading')}}</h1>
      </div>
      <div v-if="unparsedRows.length" class="border-bottom pb-3">
        <b-button v-if="unparsedRows.length" class="btn-sm mx-1" pill variant="warning" @click="isFailedShown = !isFailedShown">{{ $t(translateKey + 'labels.show_failed') }}</b-button>
        <b-alert variant="warning" :show="isFailedShown" class="mt-3">
          <ul class="text-left" style="max-height: 200px">
            <li v-for="(item, index) in unparsedRows" v-bind:key="index">
              <code>{{item.raw_data}}</code>
            </li>
          </ul>
        </b-alert>
      </div>
    </div>
    <div slot="default">
      <b-row>
        <b-col lg="8" offset-lg="2">
          <b-form @submit="onSubmit" :validated="validated" ref="editFormModal">
            <b-form-group
                label-cols-md="4"
                :label="$t(translateKey + 'labels.date')"
                label-align-md="right"
                label-align-sm="left"
                label-for="transactiondate"
                :state="dateState"
                :invalid-feedback="invalidDateFeed"
                required
            >
                <datepicker
                  v-model="date"
                  :state="dateState"
                  :typeable="true"
                  :placeholder="$t(translateKey + 'placeholders.date')"
                  :format="'yyyy-MM-dd'"
                  :monday-first="startWeekByMonday"
                  v-bind:input-class="{'form-control': true, 'is-valid': dateState === true, 'is-invalid': dateState === false}"
                ></datepicker>
            </b-form-group>
            <b-form-group
                label-cols-md="4"
                :label="$t(translateKey + 'labels.title')"
                label-align-md="right"
                label-align-sm="left"
                label-for="transactiontitle"
                :state="titleState"
                :invalid-feedback="invalidTitleFeed"
                required
            >
                <b-form-input type="text" id="transactiondate" v-model="title" :state="titleState"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              :label="$t(translateKey + 'labels.type')"
              label-align-md="right"
              label-align-sm="left"
              label-for="type"
              :state="typeState"
              :invalid-feedback="invalidTypeFeed"
              required
              >
              <b-form-select v-model="type" :options="transactionTypeOptions" :state="typeState"></b-form-select>
            </b-form-group>
            <template v-if="accountOptions.enable">
              <b-form-group
                label-cols-md="4"
                label-align-md="right"
                label-align-sm="left"
                :state="accountGroupState"
                :invalid-feedback="accountGroupFeedback"
                :label="currentUser.strict_accounting_mode ? $t(translateKey + 'labels.account') : $t(translateKey + 'labels.category')"
              >
              <custom-select
                v-model="account_group"
                :options="accountsGroupOption"
                class="marginLeft-2"
                v-bind:class="{'is-valid': accountGroupState === true, 'is-invalid': accountGroupState === false}"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="currentUser.strict_accounting_mode ? $t('common.select_account') : $t('common.select_category')"
                :noResultText="currentUser.strict_accounting_mode ? $t('common.no_account_found') : $t('common.no_category_found')"
                @select="syncPercentage" />
              </b-form-group>
            </template>
            <b-form-group
              label-cols-md="4"
              :label="$t(translateKey + 'labels.amount')"
              label-align-md="right"
              label-align-sm="left"
              label-for="transactionamount"
              :state="amountState"
              :invalid-feedback="invalidAmountFeed"
              required
            >
              <currency-input
                v-model.number="amount"
                :min="0"
                :state="amountState"
                :input-class="{'is-invalid': amountState === false, 'is-valid': amountState}"
              />
            </b-form-group>
            <template v-if="percentageOptions.enable">
              <b-form-group
                label-for="account_percentage"
                label-class="customPadding"
                label-cols-md="4"
                label-align-md="right"
                label-align-sm="left"
                :state="percentageState"
              >
                <template slot="label">{{$t(translateKey + 'labels.percentage')}}</template>
                <template slot="description">{{ $t(translateKey + 'percentage_help_text' , {active: percentage, passive: passivePercentageValue })}}</template>
                <b-col sm="12" md="10" class="float-left paddingLeftHide mobilePaddingLR-0 mb-2 mb-md-0 pb-2 pt-2">
                  <b-form-input
                    id="account_percentage_slider"
                    class="slider"
                    type="range"
                    min="0"
                    max="100"
                    step="5"
                    :state="percentageState"
                    v-model="percentage"
                  ></b-form-input>
                </b-col>
                <b-col sm="12" md="2" class="float-left mb-2 paddingLeftHide mobilePaddingLR-0 pr-0">
                  <b-form-input
                    id="account_percentage"
                    type="number"
                    min="0"
                    max="100"
                    :state="percentageState"
                    v-model="percentage"
                    :disabled="true"
                  ></b-form-input>
                </b-col>
              </b-form-group>
            </template>
            <div class="modal-form-button-group text-center mt-4 mb-3">
              <b-button class="mx-1" pill variant="outline-danger" @click="closeModal">{{$t('common.cancel')}}</b-button>
              <b-button class="mx-1" pill type="submit" variant="primary">{{$t( this.translateKey + 'add_transaction')}}</b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import CurrencyInput from '@/components/common/CurrencyInput'
import { required, minLength } from 'vuelidate/lib/validators'
import formatDate from '@/assets/filters/formatDate'
import { mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import CustomSelect from '@/components/common/CustomSelect'
import axios from 'axios'

const DecimalNumberCheck = (value) => (/^\d+(\.\d{1,2})?$/g).test(value) || (/^\d+(,\d{1,2})?$/g).test(value)
/* const validDateNotFuture = (value) => {
  let np = new Date()
  let today = new Date(np.getFullYear(), np.getMonth(), np.getDate())

  let chp = new Date(value)
  let check = new Date(chp.getFullYear(), chp.getMonth(), chp.getDate())

  if (check <= today) {
    return true
  }
  return false
} */

export default {
  name: 'addTransactionModal',
  components: { Datepicker, CurrencyInput, CustomSelect },
  props: {
    accountOptions: {
      type: Object,
      default: function () {
        return {
          enable: false,
          required: false
        }
      }
    },
    percentageOptions: {
      type: Object,
      default: function () {
        return {
          enable: false,
          required: false,
          defaultValue: 0
        }
      }
    },
    offsetAccountName: {
      type: String,
      default: null
    },
    unparsedRows: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      // translateKey: 'incomes_expenses.import.import_wizard.step4.modal',
      translateKey: 'common.add_transaction_modal.',
      validated: false,
      date: null,
      title: null,
      amount: 0,
      type: null,
      account_group: null,
      loadingAccounts: false,
      accountsGroupOption: [],
      percentage: 0,
      isFailedShown: false,
      money: {
        decimal: ',',
        thousands: ' ',
        precision: 2
      }
    }
  },
  validations: {
    date: { required },
    title: { required, minLength: minLength(2) },
    amount: { required, DecimalNumberCheck },
    type: { required },
    account_group: {
      requiredValue: function () {
        if (this.accountOptions && Object.keys(this.accountOptions).length > 0) {
          if (Object.keys(this.accountOptions).indexOf('enable') > -1 && typeof this.accountOptions.enable === 'boolean' && this.accountOptions.enable === true) {
            if (Object.keys(this.accountOptions).indexOf('required') > -1 && typeof this.accountOptions.required === 'boolean' && this.accountOptions.required === true) {
              return this.account_group !== null
            }
            return true
          }
        }
        return true
      }
    },
    percentage: {
      requiredValue: function () {
        if (this.percentageOptions && Object.keys(this.percentageOptions).length > 0) {
          if (Object.keys(this.percentageOptions).indexOf('enable') > -1 && typeof this.percentageOptions.enable === 'boolean' && this.percentageOptions.enable === true) {
            if (Object.keys(this.percentageOptions).indexOf('required') > -1 && typeof this.percentageOptions.required === 'boolean' && this.percentageOptions.required === true) {
              return this.percentage !== null
            }
            return true
          }
        }
        return true
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    passivePercentageValue () {
      return 100 - this.percentage
    },
    disabledDates () {
      const currentDate = new Date()
      return { from: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1) }
    },
    transactionTypeOptions () {
      return [
        { value: 'income', text: this.$t('common.transaction_types.deposit') },
        { value: 'expense', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'refund', text: this.$t('common.transaction_types.refund') },
        { value: 'transfer_to', text: this.offsetAccountName ? this.$t('common.transaction_types.transfer_to_account', { offsetAccountName: this.offsetAccountName }) : this.$t('common.transaction_types.transfer_to') },
        { value: 'transfer_from', text: this.offsetAccountName ? this.$t('common.transaction_types.transfer_from_account', { offsetAccountName: this.offsetAccountName }) : this.$t('common.transaction_types.transfer_from') }
        // { value: 'transfer', text: this.$t('common.transaction_types.transfer') }
      ]
    },
    dateState () {
      // return this.$v.date.$dirty ? !this.$v.date.$anyError : null
      if (this.$v.date.$dirty && this.$v.date.$anyError) {
        return false
      } else if (this.$v.date.$dirty && !this.$v.date.$anyError) {
        return true
      } else {
        return null
      }
    },
    invalidDateFeed () {
      if (this.$v.date.$dirty && !this.$v.date.required) {
        return this.$t(this.translateKey + 'errors.date_required')
      } else {
        return ''
      }
    },
    titleState () {
      return this.$v.title.$dirty ? !this.$v.title.$anyError : null
    },
    invalidTitleFeed () {
      if (this.$v.title.$anyError && !this.$v.title.required) {
        return this.$t(this.translateKey + 'errors.title_required')
      } else if (this.$v.title.$anyError && !this.$v.title.minLength) {
        return this.$t(this.translateKey + 'errors.title_minlength')
      } else {
        return ''
      }
    },
    amountState () {
      return this.$v.amount.$dirty ? !this.$v.amount.$anyError : null
    },
    invalidAmountFeed () {
      if (this.$v.amount.$anyError && !this.$v.amount.required) {
        return this.$t(this.translateKey + 'errors.amount_required')
      } else if (this.$v.amount.$anyError && !this.$v.amount.DecimalNumberCheck) {
        return this.$t(this.translateKey + 'errors.amount_invalid')
      } else {
        return ''
      }
    },
    typeState () {
      return this.$v.type.$dirty ? !this.$v.type.$anyError : null
    },
    invalidTypeFeed () {
      if (this.$v.type.$anyError) {
        return this.$t(this.translateKey + 'errors.type_required')
      } else {
        return ''
      }
    },
    accountGroupState () {
      return this.$v.account_group.$dirty ? !this.$v.account_group.$anyError : null
    },
    accountGroupFeedback () {
      if (this.$v.account_group.$dirty && !this.$v.account_group.requiredValue) {
        return 'Please select an account'
      }
      return ''
    },
    percentageState () {
      return this.$v.percentage.$dirty ? !this.$v.percentage.$anyError : null
    }
  },
  methods: {
    closeModal () {
      this.date = null
      this.title = null
      this.amount = 0
      this.type = null
      if (Object.prototype.hasOwnProperty.call(this.accountOptions, 'enable') && this.accountOptions.enable === true) {
        this.account_group = null
      }
      if (Object.prototype.hasOwnProperty.call(this.percentageOptions, 'enable') && this.percentageOptions.enable === true) {
        this.percentage = this.percentageOptions.default || 0
      }
      this.$v.$reset()
      this.$refs.addTransactionModal.hide()
    },
    loadAccountsApiMethod () {
      // this.loadingAccounts = true
      let AccountType = null
      switch (this.type) {
        case 'income':
          AccountType = 'income'
          break
        case 'expense':
          AccountType = 'expense'
          break
        case 'refund':
          AccountType = 'expense'
          break
        default:
          AccountType = 'asset,liability'
      }
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=addTransactionModal`)
        .then(response => response.data.data)
        .then(responseData => {
          const optionArray = []
          const accountGroups = responseData
          Object.keys(accountGroups).forEach(function (key) {
            if (AccountType === 'asset,liability') {
              optionArray.push({ title: (this.$te('common.' + accountGroups[key].type.toLowerCase()) ? this.$t('common.' + accountGroups[key].type.toLowerCase()) : accountGroups[key].type) + ' / ' + accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            } else {
              optionArray.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            }
          }.bind(this))
          return AccountType === 'asset,liability' ? optionArray.sort(function (a, b) {
            const nameA = a.type
            const nameB = b.type
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }) : optionArray
        })
        .catch(error => {
          console.error(error)
          return []
        })
    },
    syncPercentage (selectedOpt) {
      this.percentage = selectedOpt.default_active_percentage
    },
    openModal () {
      this.$refs.addTransactionModal.show()
    },
    onSubmit (e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const date = this.$moment(this.date).format('YYYY-MM-DD')
        const { title, amount, type, percentage } = this
        const postObject = Object.assign({}, { date, title, amount, type })
        if (Object.prototype.hasOwnProperty.call(this.accountOptions, 'enable') && this.accountOptions.enable === true) {
          Object.assign(postObject, { accounts: this.account_group })
        }
        if (Object.prototype.hasOwnProperty.call(this.percentageOptions, 'enable') && this.percentageOptions.enable === true) {
          Object.assign(postObject, { percentage })
        }
        this.$emit('success-submit', postObject)
      }
    },
    dateFormatter (date) {
      const local = this.currentCOA.locale
      return this.$moment(date).locale(local).format('YYYY-MM-DD')
    }
  },
  watch: {
    loadingAccounts: function (newVal) {
      if (newVal === true) {
        this.loadAccountsApiMethod()
          .then(accounts => {
            this.accountsGroupOption = accounts
            this.loadingAccounts = false
          })
      }
    },
    type: function (newVal) {
      if (newVal && this.accountOptions.enable === true) {
        this.loadingAccounts = true
      }
    }
  },
  filters: {
    formatDate
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/modal.scss';
.custom-range {
    margin-top: 12px;
}
.transactionModel {
  .paddingLeftHide {
    @media screen and (min-width: 768px) {
      padding-left: 0;
    }
  }
  .mobilePaddingLR-0 {
    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  label {
    align-self: center;
    &.customPadding {
      padding-top: 12px;
      align-self: start;
    }
  }
  legend {
    font-weight: 300;
    align-self: center;
  }
}
.is-invalid {
    .vdp-datepicker{
        input {
            border-color: #EF5350;
        }
    }
}
</style>
