<template>
    <b-container class="import-account-statement-v2 bg-light page-wrapper">
        <div class="main-content-wrapper">
            <b-row no-gutters>
                <b-col>
                    <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
                </b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <h1 class="text-left mb-0">{{ $t( translationPath + 'title') }}</h1>
                </b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="step-container-v2">
                        <div class="stepper bg-light mx-auto text-regular">
                            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="importStep">
                                <tab-content :title="$t(translationPath + 'step_labels.step1')" :before-change="()=>validateStep('1')">
                                  <import-account-statement-wizard-step01 ref="importStep1" @validate-success="updateInitialData" @import-selected="setDisabledStatus" :parsers="parsers"></import-account-statement-wizard-step01>
                                </tab-content>
                                <tab-content :title="$t(translationPath + 'step_labels.step2')" :before-change="()=>validateStep('2')">
                                  <import-account-statement-wizard-step02 ref="importStep2"
                                   :offset-account-name="offsetAccountName"
                                   :selectedAccountId="selectedAccountId"
                                   :offset-account-id="offsetAccountId" :default-parser="offsetAccountParser" @validate-success="updateInitialData" :debug="debug" @reset-edit-state="resetEditState" :parsers="parsers" :parserOpt="parserOpt"
                                   @default-parser-set="setAccountDefaultParser"></import-account-statement-wizard-step02>
                                </tab-content>
                                <tab-content :title="$t(translationPath + 'step_labels.step3')" :before-change="()=>validateStep('3')">
                                  <import-account-statement-wizard-step03 ref="importStep3" show-add-new-category-link :finalModel="initialData" @validate-success="updateInitialData" @update-transactions="updateInitialData" @delete-transactions="updateInitialData" @add-transaction="updateInitialData"></import-account-statement-wizard-step03>
                                </tab-content>
                                <tab-content :title="$t(translationPath + 'step_labels.step4')" :before-change="()=>validateStep('4')">
                                  <import-account-statement-wizard-step04 ref="importStep4" :finalModel="initialData" @validate-success="updateInitialData" @delete-transactions="updateInitialData" @disable-save="disableSave"></import-account-statement-wizard-step04>
                                </tab-content>
                                <template slot="footer" slot-scope="props">
                                  <div :class="{ hasSpace: props.activeTabIndex != 0 }">
                                    <div class="wizard-footer-left">
                                      <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                                        {{ $t('common.previous') }}
                                      </wizard-button>
                                    </div>
                                    <div class="line" v-if="props.activeTabIndex != 0"></div>
                                    <div class="wizard-footer-right">
                                      <wizard-button tabindex="5" v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">
                                        {{ $t('common.next') }}
                                      </wizard-button>
                                      <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" tabindex="5" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">{{$t('common.finalize_the_import')}}</wizard-button>
                                    </div>
                                  </div>
                                </template>
                            </form-wizard>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <success-modal
              :title="$t(translationPath+'success_modal.create_title')"
              :message="$t(translationPath+'success_modal.create_message')"
              :button="$t(translationPath+'success_modal.create_button_text')"
              :redirect="'/reports/performance/accounts/' + (initialData.offset_account ? initialData.offset_account.id : '') + '/view'"
              :orLinkText="$t(translationPath+'success_modal.create_button_secondary_text')"
              :orLink="'/redirect?to=importAccountStatementv2'"
              @hide="onHideSuccessModal"
            ></success-modal>
            <!--confirm-modal-->
            <confirm-modal
              ref="ConfirmModal"
              :title="$t('common.please_confirm')"
              :message="$t('reports.other.account_plan.confirm_page_leave_message')"
              variant="primary"
              @confirmed="onLeavePageConfirmed"
            ></confirm-modal>
            <b-row v-if="debug">
              <b-col>
                <b-alert show variant="success">
                  <h4>DEBUGGER: </h4>
                  <pre>{{ initialData }}</pre>
                </b-alert>
              </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import ImportAccountStatementWizardStep01 from './ImportAccountStatementWizardStep01'
import ImportAccountStatementWizardStep02 from './ImportAccountStatementWizardStep02'
import ImportAccountStatementWizardStep03 from './ImportAccountStatementWizardStep03'
import ImportAccountStatementWizardStep04 from './ImportAccountStatementWizardStep04'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import SuccessModal from '@/components/modals/SuccessModal'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import titleMixins from '@/mixins/title'
import axios from 'axios'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'ImportAccountStatementV2',
  mixins: [titleMixins],
  components: {
    SuccessModal,
    FormWizard,
    TabContent,
    WizardButton,
    ImportAccountStatementWizardStep01,
    ImportAccountStatementWizardStep02,
    ImportAccountStatementWizardStep03,
    ImportAccountStatementWizardStep04,
    ConfirmModal
  },
  data () {
    return {
      debug: false,
      initialData: {},
      nextDisabled: {
        0: true,
        1: false,
        2: false,
        3: false
      },
      next: null,
      hasUnsavedChanges: false,
      parserOpt: [],
      parsers: {},
      translationPath: 'transactions.import.import_wizard_v2.'
    }
  },
  async created () {
    await this.parserOptions()
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]
    },
    selectedOffsetAccount () {
      return this.initialData && Object.prototype.hasOwnProperty.call(this.initialData, 'offset_account') && this.initialData.offset_account ? this.initialData.offset_account : {}
    },
    offsetAccountId () {
      return this.selectedOffsetAccount && Object.prototype.hasOwnProperty.call(this.selectedOffsetAccount, 'id') && this.selectedOffsetAccount.id ? this.selectedOffsetAccount.id : 0
    },
    offsetAccountName () {
      return this.selectedOffsetAccount && Object.prototype.hasOwnProperty.call(this.selectedOffsetAccount, 'title') && this.selectedOffsetAccount.title ? this.selectedOffsetAccount.title : ''
    },
    offsetAccountParser () {
      return this.selectedOffsetAccount && Object.prototype.hasOwnProperty.call(this.selectedOffsetAccount, 'importable_default_parser') && this.selectedOffsetAccount.importable_default_parser ? this.selectedOffsetAccount.importable_default_parser : null
    },
    titleMeta () {
      return this.$t('transactions.import._common.import_account_statement')
    },
    selectedAccountId () {
      return Object.prototype.hasOwnProperty.call(this.initialData, 'offset_account') && Object.prototype.hasOwnProperty.call(this.initialData.offset_account, 'id') ? this.initialData.offset_account.id : null
    }
  },
  methods: {
    onLeavePageConfirmed () {
      this.hasUnsavedChanges = false
      this.next()
      this.next = null
    },
    setAccountDefaultParser (value) {
      this.$refs.importStep1.loadAccounts(this.selectedAccountId)
    },
    async parserOptions () {
      await axios.get(`${process.env.VUE_APP_ROOT_API}/bankstatements/parsers`)
        .then(response => response.data.data)
        .then(response => {
          const autoParser = {
            class_name: 'auto',
            parser_nice_name: 'bankparser.auto_parse',
            bank_name: 'Auto',
            description: 'auto',
            countries: [],
            login_url: 'auto',
            use_in_autoparse: true
          }
          this.parserOpt = response.concat(autoParser) // Add auto parser as first.
          this.parsers = {}
          this.parserOpt.auto = autoParser
          response.map(item => {
            this.parsers[item.class_name] = item
          })
        })
        .catch(error => console.error(error))
    },
    disableSave (value) {
      this.nextDisabled[3] = value
      this.hasUnsavedChanges = !value
    },
    onHideSuccessModal () {
      this.initialData = {}
      this.nextDisabled = {
        0: true,
        1: false,
        2: false,
        3: false
      }
      this.$refs.importStep.reset()
      this.$refs.importStep1.reset()
      // this.$router.push('/redirect?to=importAccountStatement')
    },
    setDisabledStatus (value) {
      this.nextDisabled[0] = value
      this.$refs.importStep.nextTab()
    },
    updateInitialData (values) {
      this.initialData = Object.assign({}, this.initialData, values)
      if (Object.prototype.hasOwnProperty.call(this.initialData, 'transactions') && this.initialData.transactions.length > 0) {
        this.hasUnsavedChanges = true
      }
    },
    async validateStep (step) {
      const reference = this.$refs['importStep' + step]
      switch (step) {
        case '1':
          this.$refs.importStep2.reset()
          break
      }
      const validateStatus = await reference.validate()
      if (Number(step) === 2 && validateStatus) {
        this.$refs.importStep3.scrollToTop()
      }
      return validateStatus
    },
    resetEditState () {
      this.$refs.importStep3.reset()
    },
    async onComplete () {
      const validateTransaction = await this.validateStep('4')
      if (validateTransaction) {
        this.$bvModal.show('successModal')
      }
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.hasUnsavedChanges) {
      this.next = next
      this.$refs.ConfirmModal.show()
    } else {
      next()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
.import-account-statement-v2{
  .wizard-tab-content{
    padding-left:0;
    padding-right:0;
  }
  h1 {
    color: #545b7a;
  }
  h2 {
    color: #545b7a;
  }
  h4 {
    color: #545b7a;
  }
  .FormLabels {
    color: #545b7a;
    font-weight: 600;
  }
  legend {
    color: #545b7a;
    font-weight: 600;
  }
  label {
    color: #545b7a;
    font-weight: 600;
  }
  .btn-add-new-account {
    font-size: large;
  }
  /*
  p {
    color: #9094a8 !important;
    font-weight: 400;
    a {
      color: #545b7a;
      font-weight: 600;
    }
  }*/
}
</style>
