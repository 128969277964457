<template>
  <tab-basic :title="$t(translationPath + 'title')">
    <template v-slot:heading>
      {{ $t(translationPath + 'title')}}
    </template>
    <template v-slot:description>
      {{ $t(translationPath + 'description')}}
    </template>
    <template v-slot:content>
      <div class="alert alert-light">
        <code>{{ rawString ? rawString : $t(translationPath + 'no_raw_information') }}</code>
      </div>
    </template>
  </tab-basic>
</template>

<script>
import TabBasic from './TabBasic'
export default {
  name: 'RawDataTab',
  components: {
    TabBasic
  },
  props: {
    rawString: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_wizard_v2.tab.raw.'
    }
  }
}
</script>
