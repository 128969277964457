<template>
  <b-modal
    ref="splitTransactionModalRef"
    modal-class="spirecta-modal splitTransactionModel"
    dialog-class="splitTransactionModelDialogue"
  >
    <template slot="modal-title">
      {{ $t('common.split_transaction')}}
    </template>
    <!--Split transaction Component"-->
    <split-transaction-component ref="splitTransactionComponentRef" v-bind:offsetAccountName="offsetAccountName" v-bind:currentUser="currentUser" v-bind:currentCOA="currentCOA" v-bind:transactionAmount="transactionAmount" v-bind:transactionDate="transactionDate" v-bind:transactionTitle="transactionTitle" v-bind:transactionType="transactionType" @disable-save="disableSaveHandler" @save="(value) => $emit('save', value)"/>
    <!--Action button"-->
    <template slot="modal-footer">
      <b-button variant="outline" class="float-left" @click="close">{{
        $t("common.cancel")
      }}</b-button>
      <b-button variant="primary" class="float-left" @click="emitOnSave" :disabled="disableSave">{{
        $t("common.save")
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import SplitTransactionComponent from '../component/SplitTransactionComponent'

export default {
  name: 'SplitTransactionModal',
  components: {
    SplitTransactionComponent
  },
  props: {
    offsetAccountName: {
      type: String,
      default: null
    },
    currentUser: {
      type: Object,
      default: function () {
        return {}
      }
    },
    currentCOA: {
      type: Object,
      default: function () {
        return {}
      }
    },
    transactionAmount: {
      type: Number,
      default: 0
    },
    transactionDate: {
      type: String,
      default: '20-10-2021'
    },
    transactionTitle: {
      type: String,
      default: null
    },
    transactionType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'common.masseditform.',
      disableSave: false
    }
  },
  methods: {
    open () {
      this.$emit('split-open')
      console.log(this.$refs)
      this.$refs.splitTransactionModalRef.show()
    },
    close () {
      this.$refs.splitTransactionModalRef.hide()
      this.$emit('split-close')
    },
    disableSaveHandler (value) {
      this.disableSave = value
    },
    emitOnSave () {
      this.$refs.splitTransactionComponentRef.emitSaveEvent()
    }
  }
}
</script>
<style lang="scss">
.splitTransactionModelDialogue {
  max-width: 1100px;
}
.transactionDetails {
    background: #86cbac;
    padding: 10px 0;
    color: #fff;
}
</style>
