<template>
  <b-container class="import-wizard-step-container import-wizard-step-v2-03 pt-3 pt-md-0 px-0">
    <b-row no-gutters>
      <b-col>
        <b-row no-gutters class="detail-text pt-3 pb-2 pt-md-0 pb-md-0 px-2 px-md-0" id="import-wizard-3" align-v="end">
          <b-col class="col-12 col-sm-8">
            <h2 class="text-dark">{{ $t(translationPath + 'title', { accountname: offsetAccountName, number: parsedSuccessCount }) }} </h2>

            <i18n :path="translationPath + 'intro_p1_category'" tag="p" class="mb-1">
              <span slot="parserName">{{ finalModel && Object.prototype.hasOwnProperty.call(finalModel, 'parse_result') ? $t( 'common.' + finalModel.parse_result.parser_nice_name) : ''}}</span>
            </i18n>
            <p>
              <i18n :path="translationPath + 'intro_p2_tips'" tag="span" class="mb-1">
                <b-link slot="open_sidebar_for_tips" target="_blank" href="https://forum.spirecta.se/t/kategorisera-dina-inkomster-och-utgifter/945">{{$t(translationPath + 'intro_p2_tips_open_sidebar')}}&nbsp;</b-link>
              </i18n>
              <i18n :path="translationPath + 'intro_p2_parse_error'" tag="span" class="mb-1">
                <b-link slot="open_sidebar_for_parse_error" href="#" v-b-toggle.sidebar-step3-parse-error>{{$t(translationPath + 'intro_p2_parse_error_open_sidebar')}}</b-link>
              </i18n>
            </p>

            <b-sidebar id="sidebar-step3-parse-error" right shadow backdrop>
              <div class="px-3 py-2">
                <h3>{{ $t(translationPath + 'sidebar.parse_error.heading' ) }}</h3>
                <p>{{ $t(translationPath + 'sidebar.parse_error.p1' ) }}</p>
                <p>{{ $t(translationPath + 'sidebar.parse_error.p2' ) }}</p>
                <p>{{ $t(translationPath + 'sidebar.parse_error.p3' ) }}</p>
                <p><b-link href="https://forum.spirecta.se/t/saknas-din-bank-kort-eller-kunde-inte-ditt-kontoutdrag-tolkas/95" target="_blank"> {{ $t(translationPath + 'sidebar.parse_error.p4' ) }}</b-link></p>
              </div>
            </b-sidebar>

            <template v-if="finalModel && Object.prototype.hasOwnProperty.call(finalModel, 'parse_result') && finalModel.parse_result.success_rate !== 100">
              <div class="alert alert-warning mt-3">

                <i18n :path="translationPath + 'all_transcations_could_not_be_parsed'" tag="p">
                  <span slot="title" class="font-weight-bold">{{ $t(translationPath + 'all_transcations_could_not_be_parsed_title', {parserName: finalModel && Object.prototype.hasOwnProperty.call(finalModel, 'parse_result') ? $t( 'common.' + finalModel.parse_result.parser_nice_name) : ''} )}}</span>
                  <span slot="description">{{$t(translationPath + 'all_transcations_could_not_be_parsed_link_description')}}</span>
                  <b-link slot="show_hide" href="#" v-on:click="showCode = !showCode">{{$t(translationPath + 'all_transcations_could_not_be_parsed_link_title')}}.</b-link>
                </i18n>

                <div v-if="showCode">
                  <ul>
                    <li v-for="(item, index) in finalModel.parsed_account_statement.with_parse_error" v-bind:key="index">
                      <code>{{item.raw_data}}</code>
                    </li>
                  </ul>
                </div>
              </div>
            </template>

          </b-col>
          <b-col class="col-12 col-sm-4" >
            <b-button variant="outline-secondary" class="btn-sm mr-2 float-right header-btn" @click="$refs.addTransaction.openModal()">
              <i class="flaticon stroke plus"></i>{{$t(translationPath + 'add_transaction')}}
            </b-button>
            <b-button variant="outline-secondary" class="btn-sm mr-2 float-right header-btn" @click="$refs.categorizeLaterModal.openModal()">
              {{ $t('common.categorize_later') }}
            </b-button>
          </b-col>
        </b-row>

        <!--mass edit component-->
        <mass-edit-import-form ref="MassEditImportForm" :offsetAccountName="offsetAccountName" :currentUser="currentUser" :currentCOA="currentCOA" @save-changes="handleUpdateTransactions"/>
        <!--mass-actions-button-->
        <b-row class="my-3" no-gutters>
          <b-col>
            <b-button variant='outline-danger' class="btn-sm mr-2 float-right header-btn" @click="$refs.deleteImport.show(true)" v-show="!disabledButtons">
              <i class="flaticon solid trash-3"></i> {{$t('common.mass_delete')}}
            </b-button>
            <b-button variant='primary' class="btn-sm mr-2 float-right header-btn" @click="$refs.MassEditImportForm.show()" v-show="!disabledButtons">
              <i class="flaticon solid edit-3"></i> {{$t('common.mass_edit')}}
            </b-button>
          </b-col>
        </b-row>
        <b-row no-gutters class="mb-3 mb-md-0">
          <b-col>
            <b-table hover responsive striped
              :items="allTransactions"
              :fields="fieldValues"
              :filter="transaction_filter"
              :per-page="TransactionPerPage"
              :current-page="TransactionCurrentPage"
              stacked="lg"
              select-mode="multi"
              :emptyText="$t(translationPath + 'table.no_data')"
              @filtered="onFilteredTransactionAccounts"
              @row-hovered="rowHoveredHandler"
              @row-unhovered="rowUnHoveredHandler"
              :tbody-tr-class="rowClass"
              :busy="busyState"
              ref="transactionTableView"
              class="spirecta-import-step3-table" show-empty>
                <!--loader-->
                <template v-slot:table-busy>
                  <loader/>
                </template>
                <!--loader ends here-->
                <template slot="top-row">
                    <td colspan="10">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-search"></i>
                        <b-form-input v-model.trim="transaction_filter" size="sm" :placeholder="$t(translationPath + 'table.filter_text')" @input="resetSelectedRows" @focus="setSearchInAction(true)" @blur="setSearchInAction(false)"/>
                      </div>
                    </td>
                </template>
                <!--details arrow-->
                <template v-slot:cell(show_details)="row">
                  <div class="showDetailsMain" @click="showDuplicate(row)">
                    <i class='fa fa-caret-down displayHidden' :ref="'toggleRow_' + row.index">&nbsp;</i>
                    <i :class="'fa fa-caret-' + (row.detailsShowing ? 'down' : 'right')">&nbsp;</i>
                    <span class="d-inline d-lg-none">{{ $t('reports.other.ledger.report.table.show_more_info') }}</span>
                  </div>
                </template>
                <!--details row-->
                <template v-slot:row-details="row">
                  <b-card no-body class="details-card">
                    <b-tabs cards class="transparent-tabs" :ref="'tab_' + row.item.index">
                      <information-tab
                        :accounts="infoTabAccounts"
                        :accountOptions="accountOptions"
                        :percentageOptions="percentageOptions"
                        :offsetAccountName="offsetAccountName"
                        :transactionData="row.item"
                        :parser-meta="row.item.meta"
                        @success-submit="(value) => handleUpdateTabTransaction(value, row)"
                      />
                      <raw-data-tab
                        v-if="rawTabShown"
                        v-bind:rawString="row.item.raw_string"
                      />
                      <split-tab
                        v-bind:currentUser="currentUser"
                        v-bind:currentCOA="currentCOA"
                        v-bind:transactionAmount="row.item.amount"
                        v-bind:transactionDate="row.item.date"
                        v-bind:transactionTitle="row.item.title"
                        v-bind:transactionType="row.item.type"
                        @save="removeAndAddTrans"
                        @cancel-clicked="row.toggleDetails"
                        :currentRecordId="row.item.index"
                        :offsetAccountName="offsetAccountName"
                      />
                      <additional-tab
                        :parser-meta="row.item.meta"
                      />
                      <duplicate-information
                        v-if="row.item.duplicate_information && row.item.duplicate_information.length > 0"
                        :details="row.item.duplicate_information"
                        :currentCOA="currentCOA"
                      />
                    </b-tabs>
                    <b-button variant="outline-light" class="tab-close" @click="row.toggleDetails"><b-icon-x/></b-button>
                  </b-card>
                </template>
                <!--checkbox--->
                <template v-slot:head(checkbox)>
                <b-form-checkbox
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  @change="toggleAll"
                >
                </b-form-checkbox>
              </template>
              <template v-slot:cell(checkbox)="row">
                <b-form-checkbox-group v-model="selected">
                  <b-form-checkbox
                    :value="row.item"
                    @change="(values) => selectCheckBoxRow(values, row.index)"
                  >
                  </b-form-checkbox>
                  <!--:disabled="disableSingleRows.indexOf(row.item.index) == -1 && !row.item.suggestion"-->
                </b-form-checkbox-group>
              </template>
                <template v-slot:cell(date)="row">
                  <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
                    <b-form-group
                      :state="row.item.date?($moment(row.item.date).isValid() ? true : false) : false"
                      :invalid-feedback="row.item.date?($moment(row.item.date).isValid() ? '' : $t(translationPath + 'form.errors.invalid_date')) : $t(translationPath + 'form.errors.required_error')"
                      class="mb-0">
                      <datepicker
                        v-model="row.item.date"
                        :format="dateFormatter"
                        v-bind:input-class="{
                          'form-control form-control-date': true,
                          'is-invalid': (!row.item.date && !$moment(row.item.date).isValid()),
                          'is-valid': (row.item.date && $moment(row.item.date).isValid()) }"
                        :language="selectedLang"
                        :monday-first="startWeekByMonday"
                      />
                    </b-form-group>
                  </template>
                  <template v-else>
                    {{ row.value }}
                    <div class="notify" v-if="row.item.is_duplicate || row.item.is_duplicate_transfer">
                      <b-badge v-if="row.item.is_duplicate" variant="warning" class="mr-1 cursor-help" @click="showDuplicate(row)">{{ $t(translationPath + 'badge_duplicate') }} (?)</b-badge>
                      <b-badge v-if="row.item.is_duplicate_transfer" variant="warning" class="mr-1 cursor-help" @click="showDuplicate(row)">{{ $t(translationPath + 'badge_duplicate_transfer') }} (?)</b-badge>
                    </div>
                  </template>
                </template>
                <template v-slot:cell(title)="row">
                  <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
                    <b-form-group
                      :state="!row.item.title || row.item.title.length < 2 ? false : true"
                      :invalid-feedback="!row.item.title ? $t(translationPath + 'form.errors.required_error') : (row.item.title.length < 2 ? $t(translationPath + 'form.errors.minlength_error') : '')"
                      class="mb-0">
                      <b-form-input type="text" v-model.trim="row.item.title" :state="!row.item.title || row.item.title.length < 2 ? false : true"></b-form-input>
                    </b-form-group>
                  </template>
                  <template v-else>
                    <div class="titleValue">
                      <div class="titleText mr-2">{{ row.value }}</div>
                      <div class="titleButtonWrapper">
                        <div class="titleButton">
                          <div class="titleAction">
                            [ <b-link href="#" @click="enableEdit(row)">
                              <i class="flaticon solid edit-3 text-gray" :title="$t('common.edit')"></i> <!--{{ $t('common.edit')}}-->
                            </b-link> |
                          </div>
                          <div class="titleAction">
                            <b-link href="#" @click="row.toggleDetails">
                              <i class="flaticon stroke info-2 text-gray" :title="$t('common.toggle_more_info')"></i> <!--{{$t('common.delete')}}-->
                            </b-link> |
                          </div>
                          <div class="titleAction">
                            <b-link href="#"  @click="openSingleModal(row)">
                              <i class="flaticon solid trash-3 text-gray" :title="$t('common.delete')"></i> <!--{{$t('common.delete')}}-->
                            </b-link> ]
                          </div>
                        </div>
                      </div>
                      <div style="clear: both"></div>
                    </div>
                  </template>
                </template>

                <!-- TYPES column -->
                <template v-slot:cell(types)="row">
                  <template v-if="disableSingleRows.indexOf(row.item.index) == -1">
                    <b-form-select
                      v-model="row.item.type"
                      :state="row.item.type ? true : false"
                      @change="()=> row.item.accounts = null"
                      :options="transactionTypeOptions"
                      class="transaction_type_select"
                    />
                  </template>
                  <template v-else>
                    {{ $t('common.' + row.item.type) }}
                  </template>
                </template>

                <!-- ACCOUNT CELL -->
                <template v-slot:cell(account)="row">
                  <template v-if="disableSingleRows.indexOf(row.item.index) == -1" class="tableMultiselectDiv">
                    <b-form-group
                      :state="row.item.accounts ? true : false"
                      :invalid-feedback="!row.item.accounts ? $t(translationPath + 'form.errors.select_acccount_category') : ''"
                      class="mb-0"
                    >
                      <b-row no-gutters>
                        <b-col :cols="showAddNewCategoryLink ? 11 : 12">
                          <table-custom-multiselect
                            :ref="'CustomSelect' + row.index"
                            v-if="isMultiselectShown"
                            :placeHolder="placeHolder"
                            :searchPlaceholder="searchPlaceholder"
                            :noResultText="noResultText"
                            :accounttype="row.item.type"
                            :initialValue="optionsData[row.item.type].data"
                            :extraWidth="optionsData[row.item.type].maxLength"
                            v-model="row.item.accounts"
                            v-bind:class="{'is-valid': row.item.accounts, 'is-invalid': !row.item.accounts}"
                            @account-selected="(accountVal)=>updateAccounts(accountVal, row)"
                            @account-remove="()=>removeAccounts(row.item)"
                            :sortLocal="true"
                            :enableReload="true"
                            :key="row.index"
                            :disableRequest="searchInputFocused === true"
                          />
                        </b-col>
                        <b-col v-if="showAddNewCategoryLink" cols="1" class="mt-2">
                          <b-link href="#" class="btn-add-new-account ml-2" @click="onAddNewAccountClick(row)" :title="$t(translationPath + 'new_category')"> <i class="flaticon solid plus-1 text-primary"></i></b-link>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </template>
                  <template v-else>
                    {{ row.item.accounts ? row.item.accounts.title : null }}
                  </template>
                </template>

                <!-- ACCOUNT HEAD -->
                <template v-slot:head(account_action)>
                  <b-button variant='primary' class="text-regular mr-2 action-button tableheader-btn float-right" @click="updateAllTrans" v-if="!disableOkayAll">
                    {{$t(translationPath + 'ok_all')}}
                  </b-button>
                </template>

                <template v-slot:cell(account_action)="row">
                  <b-link class="btn-sm btn-primary small-ok-button" v-if="row.item.suggestion" @click="disableSuggestionRow(row)" :disabled="!row.item.accounts">{{ $t('common.ok')}}</b-link>
                </template>
                <template v-slot:cell(percentage)="row">
                  <template v-if="enableEditingDetails.indexOf(row.item.index) > -1" class="percentageDiv">
                      <b-form-group class="mb-0 percentageformgroup float-left float-lg-right" :state="row.item.percentage ? parseInt(row.item.percentage) >= 0 && parseInt(row.item.percentage) <= 100 : null">
                        <vue-numeric v-model="row.item.percentage" v-bind:class="{'form-control percentage_input': true, 'is-valid': row.item.percentage && parseInt(row.item.percentage) >= 0 && parseInt(row.item.percentage) <= 100, 'is-invalid': row.item.percentage && (parseInt(row.item.percentage) < 0 || parseInt(row.item.percentage) > 100) }" v-bind:min="0" v-bind:max="100" currency="%" currency-symbol-position="suffix"></vue-numeric>
                      </b-form-group>
                  </template>
                  <template v-else>
                    {{ row.item.percentage | percentageFilter }}
                  </template>
                </template>

                <template v-slot:cell(amount)="row">
                  <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
                    <b-form-group
                    class="mb-0 float-left float-lg-right"
                    :state="row.item.amount || parseFloat(row.item.amount) >= 0 ? true : false"
                    :invalid-feedback="!row.item.amount && parseFloat(row.item.amount) != 0 ? $t(translationPath + 'form.errors.required_error') : ''"
                    >
                      <currency-input
                        v-model.number="row.item.amount"
                        :input-class="{'form-control': true, 'text-left': true, 'text-lg-right': true, 'is-valid': row.item.amount || parseFloat(row.item.amount) >= 0, 'is-invalid': !row.item.amount && parseFloat(row.item.amount) != 0}"
                        />
                    </b-form-group>
                  </template>
                  <template v-else>
                    <span :class="'amount-' + (row.item.type !== 'transfer' && row.item.type !== 'transfer_from' && row.item.type !== 'transfer_to' ?  row.item.type : 'transfer') + (row.item.type === 'transfer' || row.item.type === 'transfer_from' || row.item.type === 'transfer_to' ? (row.item.type === 'transfer_from' ? ' transferSelf' : ' transferOther') : '')">{{Math.abs(row.item.amount) | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, false)}}</span>
                  </template>
                </template>

                <template v-slot:cell(action)="row">
                  <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
                    <!--:disabled="!row.item.accounts"-->
                    <div class="actionDiv">
                      <b-button class="plain-btn action-button-row float-left saveButton" @click="saveAllData(row)" :disabled="!row.item.accounts || row.item.amount === null || !row.item.title || row.item.title.length < 2">
                        <i class="flaticon stroke checkmark text-success"></i> <span class="d-block d-lg-none text-success">{{ $t('common.ok')}}</span>
                      </b-button>
                      <b-button class="plain-btn action-button-row float-left cancelButton" @click="cancelEdit(row)">
                        <i class="fa fa-times text-danger" alt="cancel"></i> <span class="d-block d-lg-none text-danger">{{$t('common.cancel')}}</span>
                      </b-button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="actionDiv text-xs-left text-sm-left text-md-left">
                        <b-link href="#" @click="enableEdit(row)" :title="$t('common.edit')"> <i class="flaticon solid edit-3 text-gray"></i></b-link>
                        <!--<b-link href="#" @click="openSplitTransaction(row.item)" :title="$t('common.split_transaction')"><i class="flaticon solid share-3 text-gray"></i></b-link>-->
                        <b-link href="#" @click="openSingleModal(row)" :title="$t('common.delete')"> <i class="flaticon solid trash-3  text-gray"></i></b-link>
                    </div>
                  </template>
                </template>

            </b-table>
          </b-col>
        </b-row>
        <spirecta-sidebar id="sidebar_is_duplicate" :p1="translationPath + 'sidebar_is_duplicate_p1'" :title="translationPath + 'sidebar_is_duplicate_title'"></spirecta-sidebar>
        <spirecta-sidebar id="sidebar_is_duplicate_transfer" :p1="translationPath + 'sidebar_is_duplicate_transfer_p1'" :title="translationPath + 'sidebar_is_duplicate_transfer_title'"></spirecta-sidebar>
      </b-col>
    </b-row>
    <!--delete-modal-component-->
    <delete-modal ref="deleteImport" @delete-multiple="deleteCurrentTransaction" @delete-single="deleteSingleTransaction" @hide-single="cancelDelete">
      <template v-slot:message v-if="selectedTransaction">
        <p>{{$t(translationPath + 'alert.confirm_delete')}} <b>{{ selectedTransaction.item.title }}</b></p>
      </template>
    </delete-modal>
    <!--add modal component-->
    <add-transaction-modal ref="addTransaction" @success-submit="addTransactionData" :accountOptions="accountOptions" :percentageOptions="percentageOptions" :offsetAccountName="offsetAccountName" :unparsedRows="finalModel.parsed_account_statement ? finalModel.parsed_account_statement.with_parse_error : []"/>
    <!--split transaction modal-->
    <split-transaction-modal ref="splitTansactionModal" :offsetAccountName="offsetAccountName" :currentUser="currentUser" :currentCOA="currentCOA" :transactionDate="transactionDate" :transactionTitle="transactionTitle" :transactionAmount="transactionAmount" :transactionType="transactionType" @split-close="resetSplitId" @save="removeAndAddTrans"/>
    <!--categorize later modal-->
    <categorize-later-transaction-modal ref="categorizeLaterModal" @success-submit="updateWithoutSuggestion"/>
    <!--debugger-->
    <template v-if="debug">
      <pre>{{ enableEditingDetails }}</pre>
      <pre>{{ disableSingleRows }}</pre>
    </template>

    <create-account-group-account-modal ref="CreateAccountModal" @performed="onCreateAccountModalPerformed" />
  </b-container>
</template>

<script>

/* Display the parese transaction
** @props finalModel (object)
*/

import addTransactionModal from './modals/addTransactionModal'
import categorizeLaterTransactionModal from './modals/categorizeLaterTransactionModal'
import Datepicker from 'vuejs-datepicker'
import CurrencyInput from '@/components/common/CurrencyInput'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import Loader from '@/components/common/Loader'
import MassEditImportForm from '@/components/common/MassEditImportForm'
import VueNumeric from 'vue-numeric'
import DeleteModal from './modals/DeleteModal'
import { mapState } from 'vuex'
import TableCustomMultiselect from '@/components/common/TableCustomMultiselect'
import _ from 'lodash'
import axios from 'axios'
import formatAmount from '@/assets/filters/formatAmount'
import SpirectaSidebar from '@/components/common/SpirectaSidebar'
import SplitTransactionModal from './modals/SplitTransactionModal'
import VueScrollTo from 'vue-scrollto'
import { BIconX } from 'bootstrap-vue'
import Vue from 'vue'
import InformationTab from './tabs/InformationTab.vue'
import RawDataTab from './tabs/RawDataTab.vue'
import SplitTab from './tabs/SplitTab.vue'
import DuplicateInformation from './tabs/DuplicateInformation.vue'
import AdditionalTab from './tabs/AdditionalTab.vue'
import CreateAccountGroupAccountModal from '@/components/modals/CreateAccountGroupAccountModal'

const tabs = require.context('./tabs', true, /\.vue$/i)
tabs.keys().filter((el) => el !== './TabBasic.vue').map(key =>
  Vue.component(
    key
      .split('/')
      .pop()
      .split('.')[0],
    tabs(key).default
  )
)

export default {
  name: 'ImportAccountStatementWizardStep03',
  props: {
    finalModel: {
      type: Object,
      default: function () {
        return {}
      }
    },
    rawTabShown: {
      type: Boolean,
      default: true
    },
    showAddNewCategoryLink: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SpirectaSidebar,
    MassEditImportForm,
    TableCustomMultiselect,
    VueNumeric,
    Loader,
    BIconX,
    Datepicker,
    CurrencyInput,
    addTransactionModal,
    DeleteModal,
    SplitTransactionModal,
    DuplicateInformation,
    categorizeLaterTransactionModal,
    CreateAccountGroupAccountModal,
    InformationTab,
    RawDataTab,
    SplitTab,
    AdditionalTab
  },
  data () {
    return {
      isMultiselectShown: true,
      splitId: null,
      transactionDate: null,
      transactionTitle: null,
      transactionType: null,
      transactionAmount: 0,
      searchInputFocused: false,
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      translationPath: 'transactions.import.import_wizard_v2.step3.',
      hiddenErrors: [],
      displayEdit: false,
      TransactionCurrentPage: 1,
      allSelected: false,
      indeterminate: false,
      massUpdate: false,
      addNewTransaction: false,
      TransactionPerPage: 0,
      account_group: null,
      selected: [],
      showCode: false,
      optionsValues: [],
      hoveredRow: [],
      submitted: false,
      restrictDeleteIndex: [],
      validated: false,
      triggerValidations: false,
      TransactionTotalRows: 0,
      transaction_filter: null,
      transaction_type: null,
      percentage: 0,
      enableMultipleAccount: false,
      Transactions: [],
      disableSingleRows: [],
      enableEditingDetails: [],
      accountsGroups: [],
      busyState: false,
      debug: false,
      selectedTransaction: null,
      infoTabAccounts: {
        income: null,
        expense: null,
        transfer: null
      },
      accountOptions: {
        enable: true,
        required: true
      },
      percentageOptions: {
        enable: true
      },
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      optionsData: {
        income: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        expense: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        transfer: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        transfer_to: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        transfer_from: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        refund: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    disableOkayAll () {
      const suggestionAccounts = this.Transactions.filter(item => item.suggestion === true)
      return suggestionAccounts.length === 0
    },
    parsedSuccessCount () {
      const parserTransactions = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'parse_result') ? this.finalModel.parse_result.entries_passed : 0
      return parserTransactions
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    allTransactions () {
      return this.Transactions
    },
    offsetAccountName () {
      return this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'offset_account') ? (this.finalModel.offset_account ? this.finalModel.offset_account.title : null) : null
      // return this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'offset_account') ? this.finalModel.offset_account.title : null
    },
    searchPlaceholder () {
      return this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'form.labels.type_to_filter_account') : this.$t(this.translationPath + 'form.labels.type_to_filter_category')
    },
    noResultText () {
      return this.currentUser.strict_accounting_mode ? this.$t('common.no_acount_found') : this.$t('common.no_category_found')
    },
    placeHolder () {
      return this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'form.labels.select_account') : this.$t(this.translationPath + 'form.labels.select_category')
    },
    transactionTypeOptions () {
      return [
        { value: 'income', text: this.$t('common.transaction_types.deposit') },
        { value: 'expense', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'refund', text: this.$t('common.transaction_types.refund') },
        { value: 'transfer_to', text: this.$t(this.translationPath + 'transaction_type_options.transfer_to', { offsetAccountName: this.offsetAccountName }) },
        { value: 'transfer_from', text: this.$t(this.translationPath + 'transaction_type_options.transfer_from', { offsetAccountName: this.offsetAccountName }) }
        // { value: 'transfer', text: this.$t('common.transaction_types.transfer') }
      ]
    },
    fieldValues () {
      const fields = [
        { key: 'show_details', label: '', sortable: false, show: true, checkboxLabel: 'show_details' },
        { key: 'date', label: this.$t(this.translationPath + 'table.head.date'), sortable: true, class: 'td-col-date' },
        { key: 'title', label: this.$t(this.translationPath + 'table.head.title'), class: 'td-col-title' },
        { key: 'types', label: this.$t(this.translationPath + 'table.head.type'), class: 'td-col-types' },
        { key: 'account', label: this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'table.head.account') : this.$t(this.translationPath + 'table.head.category'), class: 'td-col-account' }
      ]
      if (!this.disableOkayAll) {
        fields.push({ key: 'account_action', label: '', sortable: false, class: 'td-col-ok-buttons' })
      }
      return [...fields,
        { key: 'percentage', label: this.$t(this.translationPath + 'table.head.percentage'), class: 'text-left text-lg-right td-col-percentage' },
        { key: 'amount', label: this.$t(this.translationPath + 'table.head.amount'), class: 'text-left text-lg-right td-col-amount' },
        { key: 'action', label: '', class: 'text-center td-col-action' },
        { key: 'checkbox', label: this.$t('common.mulit_action_label'), sortable: false, class: 'td-col-checkbox' }
      ]
    },
    allCategorySelected () {
      const withoutAccount = this.Transactions.filter(item => !item.accounts)
      return withoutAccount.length
    },
    allTransactionsTitleExists () {
      const withoutTitle = this.Transactions.filter(item => !item.title || item.title.toString().trim().length === 0)
      return withoutTitle.length
    },
    disabledButtons () {
      return (this.selected).length === 0
    }
  },
  created () {
    this.onKeyDelete = this.onKeyDelete.bind(this)
    window.addEventListener('keypress', this.onKeyDelete)
    window.addEventListener('keyup', this.keyUpHandler)
    this.fetchAccountsByTypeCondition('income').then(IncomeData => {
      this.fetchAccountsByTypeCondition('expense').then(ExpenseData => {
        this.fetchAccountsByTypeCondition('transfer').then(TransferData => {
          const AllIncomes = _.flattenDeep(IncomeData)
          const AllExpense = _.flattenDeep(ExpenseData)
          const AllTransfer = _.flattenDeep(TransferData)
          this.accountsGroups = [...AllIncomes, ...AllExpense, ...AllTransfer]
          this.loadData()
        })
      })
    })
  },
  destroyed () {
    window.removeEventListener('keypress', this.onKeyDelete)
    window.removeEventListener('keyup', this.keyUpHandler)
  },
  methods: {
    updateWithoutSuggestion (value) {
      const filteredAdd = this.Transactions.filter(item => !item.raw_string)
      const filteredTrans = this.Transactions.filter(item => item.raw_string).map((el) => {
        const selectedSuggestionId = value[`${el.type}_category`] ? value[`${el.type}_category`].id : null
        if ((el.suggestion_account_id !== -1 && el.suggestion_account_id !== 0) || el.accounts || !selectedSuggestionId) {
          return el
        }
        return Object.assign({}, el, {
          suggestion_account_id: selectedSuggestionId
        })
      })
      this.$emit('update-transactions', { transactions: filteredTrans, user_transactions: filteredAdd })
      this.$refs.categorizeLaterModal.closeModal()
    },
    setSearchInAction (status) {
      this.searchInputFocused = status
    },
    showDuplicate (row) {
      row.toggleDetails()
      if (row.item.is_duplicate) {
        this.$nextTick(() => {
          if (typeof this.$refs['tab_' + row.item.index] !== 'undefined') {
            this.$refs['tab_' + row.item.index].$data.currentTab = 4
          }
        })
      }
    },
    scrollToTop () {
      VueScrollTo.scrollTo('#import-wizard-3', 100, {
        force: true,
        easing: 'ease-in'
      })
    },
    handleUpdateTabTransaction (item, row) {
      this.massUpdate = true
      this.Transactions = this.Transactions.map((el) => {
        if (item.index === el.index && item.realIndex === el.realIndex) {
          return item
        } else {
          return el
        }
      })
      this.disableDetailsEditing({
        item: {
          index: item.index
        }
      })
      const indexRawString = this.disableSingleRows.indexOf(item.index)
      if (indexRawString === -1) {
        this.disableSingleRows.push(item.index)
      }
      row.toggleDetails()
    },
    transactionTypeIdentify (type, identifyType = null) {
      const identifyTransactionType = identifyType && type === 'refund' ? 'expense' : type
      // transaction type
      const validTransactionTypes = identifyType ? ['income', 'expense', 'asset,liability'] : ['income', 'expense', 'transfer_to', 'transfer_from', 'refund']
      if (validTransactionTypes.indexOf(identifyTransactionType) === -1) {
        return validTransactionTypes[validTransactionTypes.length - 1]
      }
      return type
    },
    resetSplitId () {
      this.splitId = null
    },
    // remove and add transaction (split transaction)
    removeAndAddTrans (value) {
      const selectedTrans = typeof this.Transactions[value.currentId] !== 'undefined' ? this.Transactions[value.currentId] : null
      let transactions = this.Transactions.filter(item => item.raw_string)
      let userTransactions = this.Transactions.filter(item => !item.raw_string)
      if (selectedTrans !== null) {
        if (Object.prototype.hasOwnProperty.call(selectedTrans, 'raw_string') && selectedTrans.raw_string) {
          transactions = transactions.filter((item) => item.index !== selectedTrans.index)
        } else {
          userTransactions = userTransactions.filter((item) => item.index !== selectedTrans.index)
        }
      }
      const addedTrans = value.transactions.map((item) => {
        const currentDate = new Date()
        const {
          title,
          type,
          amount,
          account,
          date,
          description
        } = item
        return {
          is_new: true,
          title,
          time: currentDate.getTime(),
          type,
          percentage: account.default_active_percentage,
          amount,
          accounts: account,
          date,
          description: description || null
        }
      })
      this.massUpdate = true
      this.$emit('delete-transactions', { transactions: transactions, user_transactions: userTransactions })
      this.massUpdate = true
      this.$emit('add-transaction', { user_transactions: [...userTransactions, ...addedTrans] })
      this.$refs.splitTansactionModal.close()
    },
    // hanlde split transaction modal
    openSplitTransaction (item) {
      this.splitId = item.index
      this.transactionDate = item.date
      this.transactionTitle = item.title
      this.transactionType = item.type
      this.transactionAmount = item.amount
      this.$refs.splitTansactionModal.open()
    },
    rowHoveredHandler (item) {
      const selectedItem = []
      selectedItem.push(item)
      this.hoveredRow = selectedItem
    },
    rowUnHoveredHandler (item) {
      this.hoveredRow = []
    },
    keyUpHandler (e) {
      const editEnabled = this.enableEditingDetails && this.enableEditingDetails.length > 0
      if (e.key === 'Escape' && editEnabled) {
        const hoveredRowIndex = this.hoveredRow.length > 0 ? this.hoveredRow[0].realIndex : null
        if (hoveredRowIndex && this.enableEditingDetails.indexOf(hoveredRowIndex) > -1) {
          this.cancelEdit({
            item: this.hoveredRow[0],
            index: this.hoveredRow[0].realIndex
          })
        } else if (editEnabled) {
          const lastIndex = this.enableEditingDetails[this.enableEditingDetails.length - 1]
          const lastIndexRow = this.allTransactions.filter(item => parseInt(item.index) === parseInt(lastIndex))
          if (lastIndexRow.length > 0) {
            this.cancelEdit({
              item: lastIndexRow[0],
              index: lastIndexRow[0].realIndex
            })
          }
        }
      }
    },
    rowClass (item) {
      if (!item) return
      const suggestionClass = !item.suggestion ? 'no_suggestion' : null
      if (this.triggerValidations === false) return suggestionClass
      if (item && Object.prototype.hasOwnProperty.call(item, 'accounts') && !item.accounts) {
        return suggestionClass ? `invalid-row ${suggestionClass}` : 'invalid-row'
      } else if (item && Object.prototype.hasOwnProperty.call(item, 'accounts') && item.accounts) {
        if (!item.date || !item.title || (!item.amount && parseFloat(item.amount) !== 0) || !item.type) {
          return suggestionClass ? `invalid-row ${suggestionClass}` : 'invalid-row'
        }
        if (item.is_duplicate) {
          return suggestionClass ? `duplicate-row ${suggestionClass}` : 'duplicate-row'
        }
        return suggestionClass ? `valid-row ${suggestionClass}` : 'valid-row'
      }
    },
    getUpdatedTransactions (userTransactions, parserTrans) {
      const ParseTransaction = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'transactions') && this.finalModel.transactions ? this.finalModel.transactions : []
      const useTransaction = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'user_transactions') && this.finalModel.user_transactions ? this.finalModel.user_transactions : []
      const transactions = parserTrans || ParseTransaction
      const usrTransactions = userTransactions || useTransaction
      const MergeTransactions = [...transactions, ...usrTransactions]
      MergeTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      const mapTransactions = MergeTransactions.map((items, index) => {
        return Object.assign({}, items, {
          index: index,
          suggestion: items.suggestion || false
        })
      })
      return mapTransactions
    },
    getUpdatedId (userTransactions, parserTrans) {
      const mapTransactions = this.getUpdatedTransactions(userTransactions, parserTrans)
      const allUpdatedIds = mapTransactions.filter(item => item.is_new).map(item => item.index)
      return allUpdatedIds.length > 0 ? allUpdatedIds : []
    },
    getUpdatedIdData (userTransactions, parserTrans) {
      const mapTransactions = this.getUpdatedTransactions(userTransactions, parserTrans)
      const allUpdatedIds = mapTransactions.filter(item => item.is_new)
      return allUpdatedIds.length > 0 ? allUpdatedIds : []
    },
    addTransactionData (NewTransaction) {
      const newTransaction = NewTransaction
      const currentDate = new Date()
      newTransaction.time = currentDate.getTime()
      // check existance
      const userTransactionsFinal = Object.prototype.hasOwnProperty.call(this.finalModel, 'user_transactions') ? this.finalModel.user_transactions : []
      const transactionsFinal = Object.prototype.hasOwnProperty.call(this.finalModel, 'transactions') ? this.finalModel.transactions : []
      const userTransactionExists = userTransactionsFinal.filter(item => this.$moment(item.date).format('YYYY-MM-DD') === this.$moment(NewTransaction.date).format('YYYY-MM-DD') && item.title === NewTransaction.title && item.type === NewTransaction.type && item.amount === NewTransaction.amount)
      const transactionExists = transactionsFinal.filter(item => this.$moment(item.date).format('YYYY-MM-DD') === this.$moment(NewTransaction.date).format('YYYY-MM-DD') && item.title === NewTransaction.title && item.type === NewTransaction.type && item.amount === NewTransaction.amount)
      if (userTransactionExists.length === 0 && transactionExists.length === 0) {
        Object.assign(newTransaction, { is_new: true, description: null })
        const userTransactions = Object.prototype.hasOwnProperty.call(this.finalModel, 'user_transactions') ? [...this.finalModel.user_transactions, newTransaction] : [newTransaction]
        const selectedId = this.getUpdatedId(userTransactions)
        if (selectedId.length > 0) {
          this.refreshOnNewTransaction(selectedId)
        }
        Object.assign(newTransaction, { is_new: false })
        this.addNewTransaction = true
        this.$emit('add-transaction', { user_transactions: userTransactions })
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.transaction_added'), {
          title: this.$t(this.translationPath + 'toast.title'),
          variant: 'success',
          solid: true
        })
        this.$refs.addTransaction.closeModal()
      } else {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.transaction_exists'), {
          title: this.$t(this.translationPath + 'toast.title'),
          variant: 'danger',
          solid: true
        })
      }
    },
    disableSuggestionRow (row) {
      this.disableAccountRow(row)
    },
    disableAccountRow (row) {
      this.disableEditStatus(row, 'account')
    },
    saveAllData (row) {
      this.disableEditStatus(row, 'details')
      this.disableAccountRow(row)
    },
    checkIndexedRow (checkString, raw) {
      return raw === true ? this.selected.findIndex((value) => value.raw_string === checkString.raw_string) : this.selected.findIndex((value) => value.title === checkString.title && value.amount === checkString.amount && value.date === checkString.date && value.type === checkString.type && value.time === checkString.time)
    },
    enableEdit (dataRow) {
      const checkIndex = dataRow.item.raw_string ? this.checkIndexedRow(dataRow.item, true) : this.checkIndexedRow(dataRow.item, false)
      if (checkIndex > -1) {
        this.selected.splice(checkIndex, 1)
      }
      const details = Object.prototype.hasOwnProperty.call(dataRow, 'item') && dataRow.item ? dataRow.item : {}
      if (Object.prototype.hasOwnProperty.call(details, 'accounts') && details.accounts && Object.keys(details.accounts).length > 0 && this.disableSingleRows.indexOf(dataRow.item.index) > -1) {
        this.enableEditStatus(dataRow, 'account')
      }
      if (dataRow.item.suggestion) {
        dataRow.item.suggestion = false
      }
      this.enableEditStatus(dataRow)
    },
    onKeyDelete (e) {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (typeof this.$refs.deleteImport !== 'undefined' && !this.$refs.deleteImport.isHidden) {
          this.$refs.deleteImport.emitEventClick()
        } else {
          const editEnabled = this.enableEditingDetails && this.enableEditingDetails.length > 0
          if (editEnabled) {
            const hoveredRowIndex = this.hoveredRow.length > 0 ? this.hoveredRow[0].realIndex : null
            if (hoveredRowIndex && this.enableEditingDetails.indexOf(hoveredRowIndex) > -1) {
              if (this.hoveredRow[0].date && this.hoveredRow[0].title && this.hoveredRow[0].accounts && this.hoveredRow[0].type && this.hoveredRow[0].amount !== null) {
                this.saveAllData({
                  item: this.hoveredRow[0],
                  index: this.hoveredRow[0].realIndex
                })
              }
            } else if (editEnabled) {
              const lastIndex = this.enableEditingDetails[this.enableEditingDetails.length - 1]
              const lastIndexRow = this.allTransactions.filter(item => parseInt(item.index) === parseInt(lastIndex)).filter(item => item.date && item.title && item.accounts && item.type && item.amount !== null)
              if (lastIndexRow.length > 0) {
                this.saveAllData({
                  item: lastIndexRow[0],
                  index: lastIndexRow[0].realIndex
                })
              }
            }
          }
        }
      }
    },
    deleteCurrentTransaction () {
      const getFilteredTrans = this.selected.filter(item => item.raw_string).map(item => parseInt(item.index))
      const getFilteredAddTrans = this.selected.filter(item => getFilteredTrans.indexOf(parseInt(item.index)) === -1)
      const filteredTrans = this.Transactions.filter(item => item.raw_string).filter(item => getFilteredTrans.indexOf(parseInt(item.index)) === -1)
      const filteredAddTrans = this.Transactions.filter(item => !item.raw_string).filter(item => getFilteredAddTrans.indexOf(parseInt(item.index)) === -1)
      this.selected = []
      this.massUpdate = true
      this.$emit('delete-transactions', { transactions: filteredTrans, user_transactions: filteredAddTrans })
      this.resetSelectedRows()
      this.$refs.deleteImport.hide()
      this.successDeleteToast()
    },
    resetSelectedRows () {
      // this.allSelected = false
      // this.indeterminate = false
      this.toggleAll(false)
      this.$refs.transactionTableView.clearSelected()
    },
    deleteSingleTransaction () {
      if (this.selectedTransaction) {
        this.deleteSingleRow(this.selectedTransaction)
      }
      this.$refs.deleteImport.hide()
    },
    rearrangeSelection (selectedArray, deleteArray) {
      let arrayUpdate = []
      if (deleteArray.length === 0) {
        return arrayUpdate
      }
      for (let $i = 0; $i <= selectedArray.length - 1; $i++) {
        if ($i === 0) {
          const matchValue = selectedArray[$i]
          const indexValue = deleteArray.indexOf(matchValue)
          arrayUpdate = deleteArray.filter(item => {
            return item !== matchValue
          }).map((item, key) => {
            if (key >= indexValue && indexValue !== -1) {
              return item - 1
            } else if (matchValue < item) {
              return item - 1
            } else {
              return item
            }
          })
        } else {
          const matchValue = selectedArray[$i]
          const indexValue = arrayUpdate.indexOf(matchValue)
          arrayUpdate = arrayUpdate.filter(item => {
            return item !== matchValue
          }).map((item, key) => {
            if (key >= indexValue && indexValue !== -1) {
              return item - 1
            } else if (matchValue < item) {
              return item - 1
            } else {
              return item
            }
          })
        }
      }
      return arrayUpdate
    },
    refreshSelection (selectedArray, updatedArray) {
      let arrayUpdate = []
      if (updatedArray.length === 0) {
        return arrayUpdate
      }
      _.each(selectedArray, function (checkvalue, keyIndex) {
        const indexArra = updatedArray.indexOf(checkvalue)
        if (keyIndex === 0) {
          arrayUpdate = updatedArray.map((item, key) => {
            if (key >= indexArra && indexArra !== -1) {
              return item + 1
            } else if (item > checkvalue) {
              return item + 1
            } else {
              return item
            }
          })
        } else {
          arrayUpdate = arrayUpdate.map((item, key) => {
            if (key >= indexArra && indexArra !== -1) {
              return item + 1
            } else if (item > checkvalue) {
              return item + 1
            } else {
              return item
            }
          })
        }
      })
      return arrayUpdate
    },
    refreshOnNewTransaction (selectedId) {
      const enableEditModify = this.refreshSelection(selectedId, this.enableEditingDetails)
      this.enableEditingDetails = enableEditModify
      this.disableSingleRows = this.refreshSelection(selectedId, this.disableSingleRows)
      this.disableSingleRows = [...this.disableSingleRows, ...selectedId].sort().filter(item => enableEditModify.indexOf(item) === -1)
    },
    deleteSingleRow (rowItem) {
      const excludeRow = rowItem.item
      this.massUpdate = true
      const transactions = (this.Transactions).filter(item => item.raw_string).filter(item => {
        if (excludeRow.raw_string) {
          if (parseInt(excludeRow.index) !== parseInt(item.index)) {
            return item
          }
        } else {
          return item
        }
      })
      const newTransacton = (this.Transactions).filter(item => !item.raw_string).filter(item => {
        if (excludeRow.time) {
          if (parseInt(excludeRow.index) !== parseInt(item.index)) {
            return item
          }
        } else {
          return item
        }
      })
      this.$emit('delete-transactions', { transactions: transactions, user_transactions: newTransacton })
      this.successDeleteToast()
    },
    successDeleteToast () {
      this.$bvToast.toast(this.$t(this.translationPath + 'toast.transaction_deleted'), {
        title: this.$t(this.translationPath + 'toast.title'),
        variant: 'success',
        solid: true
      })
    },
    openSingleModal (row) {
      this.selectedTransaction = row
      this.$refs.deleteImport.show()
    },
    cancelDelete (row) {
      this.selectedTransaction = null
    },
    dateFormatter (date) {
      const local = this.currentCOA.locale
      return this.$moment(date).locale(local).format('YYYY-MM-DD')
    },
    selectCheckBoxRow (value, index) {
      if (value) {
        this.$refs.transactionTableView.selectRow(index)
      } else {
        this.$refs.transactionTableView.unselectRow(index)
      }
    },
    toggleAll (checked) {
      const transactionsItems = this.allTransactions
      if (checked) {
        transactionsItems.forEach(elem => {
          const selectedIndex = [...this.selected].map((valueItem) => valueItem.index)
          if (selectedIndex.indexOf(elem.index) === -1 && this.restrictDeleteIndex.indexOf(elem.index) === -1) {
            this.$nextTick(function () {
              this.selected.push(elem)
            })
          }
        })
      } else {
        this.selected = []
      }
    },
    getAllTransactionalData (filterIndex) {
      const ParseTransaction = this.finalModel && this.finalModel.transactions ? this.finalModel.transactions : []
      const AddedTrasactions = this.finalModel && this.finalModel.user_transactions ? this.finalModel.user_transactions : []
      const MergeTransactions = [...ParseTransaction, ...AddedTrasactions]
      MergeTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      const mapTransactions = MergeTransactions.map((items, index) => {
        return Object.assign({}, items, {
          index: index,
          suggestion: items.suggestion || false
        })
      }).filter(item => item.index === filterIndex)
      return mapTransactions.length > 0 ? mapTransactions[0] : null
    },
    cancelEdit (row) {
      const cancelIndex = row.item.index
      const refreshedData = this.getAllTransactionalData(cancelIndex)
      const updateTransactions = [...this.Transactions].map(item => {
        if (item.index === cancelIndex) {
          const cancelItem = Object.prototype.hasOwnProperty.call(item, 'raw_string') ? {
            amount: Number(refreshedData.amount),
            title: refreshedData.title,
            date: refreshedData.date,
            duplicate: refreshedData.duplicate,
            is_duplicate: refreshedData.duplicate === 1
          } : {
            amount: Number(refreshedData.amount),
            title: refreshedData.title,
            date: refreshedData.date
          }
          return Object.assign(item, cancelItem)
        } else {
          return item
        }
      })
      this.Transactions = updateTransactions
      this.disableEditStatusCancel(row)
    },
    disableEditStatusCancel (rows) {
      this.clearClickedRowSelection(rows.index)
      // this.disableDetailsEditing(rows)
      this.disableEditStatus(rows, 'details')
      if (rows.item.accounts) {
        this.disableEditStatus(rows)
      }
      return true
    },
    disableDetailsEditing (rows) {
      const enableEditIndex = this.enableEditingDetails.indexOf(rows.item.index)
      if (enableEditIndex > -1) {
        this.enableEditingDetails.splice(enableEditIndex, 1)
      }
    },
    disableEditStatus (rows, type) {
      this.clearClickedRowSelection(rows.index)
      const ObjectRow = typeof rows === 'object' && Object.prototype.hasOwnProperty.call(rows, 'item') && rows.item ? rows.item : rows
      if (type === 'details') {
        this.disableDetailsEditing(rows)
        return true
      }
      this.disableSingleRows.push(rows.item.index)
      this.disableSingleRows = this.disableSingleRows.sort()
      this.updateTransactionRow(ObjectRow)
      this.massUpdate = true
    },
    enableEditStatus (rows, type) {
      const actionType = type || 'details'
      this.clearClickedRowSelection(rows.index)
      if (actionType === 'details') {
        this.enableEditingDetails.push(rows.item.index)
        return true
      }
      const indexRawString = this.disableSingleRows.indexOf(rows.item.index)
      if (indexRawString > -1) {
        this.disableSingleRows.splice(indexRawString, 1)
      }
    },
    clearClickedRowSelection (index) {
      if (this.$refs.transactionTableView.isRowSelected(index)) {
        this.$refs.transactionTableView.unselectRow(index)
      }
    },
    onFilteredTransactionAccounts (filteredItems) {
      this.TransactionTotalRows = filteredItems.length
      this.TransactionCurrentPage = 1
      const filteredIndex = filteredItems.map((item) => item.index)
      this.restrictDeleteIndex = this.Transactions.map((item) => item.index).filter((item) => filteredIndex.indexOf(item) === -1)
    },
    removeAccounts (rowitem) {
      const transactionsUpdated = this.Transactions.filter(item => {
        if (item.raw_string) {
          return Object.assign({}, { amount: item.amount, balance: item.balance, date: item.date, raw_string: item.raw_string, title: item.title, transaction_category: item.transaction_category, transaction_type: item.transaction_type, type: item.type, percentage: item.percentage || 0, description: item.description || null, accounts: item.accounts || null })
        } else {
          return Object.assign({}, { amount: item.amount, date: item.date, time: item.time, title: item.title, type: item.type || null, percentage: item.percentage || 0, description: item.description || null, accounts: item.accounts || null })
        }
      })
      const filterParseTrans = transactionsUpdated.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'raw_string')) {
          return item
        }
      })
      const filterAddedTrans = transactionsUpdated.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'time')) {
          return item
        }
      })
      if (Object.keys(rowitem).indexOf('raw_string') > -1) {
        const updateTrans = filterParseTrans.findIndex(function (element) {
          return element.raw_string === rowitem.raw_string
        })
        rowitem.accounts = null
        filterParseTrans.splice(updateTrans, 1, rowitem)
      } else {
        const updateTrans = filterAddedTrans.findIndex(function (element) {
          return element.time === rowitem.time
        })
        rowitem.accounts = null
        filterAddedTrans.splice(updateTrans, 1, rowitem)
      }
      this.Transactions = [...filterParseTrans, ...filterAddedTrans]
    },
    updateAccounts (value, row) {
      const rowitem = row.item
      const transactionsUpdated = this.Transactions.filter(item => {
        if (item.raw_string) {
          return Object.assign({}, { amount: item.amount, balance: item.balance, date: item.date, raw_string: item.raw_string, title: item.title, transaction_category: item.transaction_category, transaction_type: item.transaction_type, type: item.type, percentage: item.percentage || 0, accounts: item.accounts || null, description: item.description || null })
        } else {
          return Object.assign({}, { amount: item.amount, date: item.date, time: item.time, title: item.title, type: item.type || null, percentage: item.percentage || 0, accounts: item.accounts || null, description: item.description || null })
        }
      })
      const filterParseTrans = transactionsUpdated.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'raw_string')) {
          return item
        }
      })
      const filterAddedTrans = transactionsUpdated.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'time')) {
          return item
        }
      })
      if (Object.keys(rowitem).indexOf('raw_string') > -1) {
        const updateTrans = filterParseTrans.findIndex(function (element) {
          if (typeof element.raw_string === 'undefined') {
            element.raw_string = ''
          }
          return element.raw_string.replace(' ', '') === rowitem.raw_string.replace(' ', '') && element.index === rowitem.index
        })
        rowitem.accounts = value.accounts ? value.accounts : null
        rowitem.percentage = value.accounts ? value.accounts.default_active_percentage || 0 : 0
        rowitem.suggestion = false
        filterParseTrans.splice(updateTrans, 1, rowitem)
      } else {
        const updateTrans = filterAddedTrans.findIndex(function (element) {
          return element.time === rowitem.time && element.index === rowitem.index
        })
        rowitem.accounts = value.accounts ? value.accounts : null
        rowitem.percentage = value.accounts ? value.accounts.default_active_percentage || 0 : 0
        rowitem.suggestion = false
        filterAddedTrans.splice(updateTrans, 1, rowitem)
      }
      const newTransactions = [...filterParseTrans, ...filterAddedTrans]
      newTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      this.Transactions = newTransactions
      if (this.enableEditingDetails.indexOf(rowitem.index) === -1) {
        this.clearClickedRowSelection(rowitem.index)
      }
      if (rowitem.title && rowitem.title.toString().trim().length > 0) {
        if (this.enableEditingDetails.indexOf(rowitem.index) === -1) {
          this.disableSingleRows.push(rowitem.index)
          this.disableSingleRows = this.disableSingleRows.sort()
        }
      }
      this.massUpdate = true
    },
    updateTransactionRow (row, all) {
      const allStatus = all || false
      const transactionsUpdated = this.Transactions.map(item => {
        if (item.raw_string) {
          if (row.raw_string && item.raw_string === row.raw_string && item.index === row.index) {
            const amountChanged = this.finalModel.transactions[item.realIndex].amount !== row.amount
            const titleChanged = this.finalModel.transactions[item.realIndex].title !== row.title
            const dateChanged = this.finalModel.transactions[item.realIndex].date !== row.date
            const updateDuplicate = amountChanged === true || titleChanged === true || dateChanged === true
            const duplicateValue = updateDuplicate === true ? 0 : item.duplicate
            const duplicate = item.duplicate === 1 ? duplicateValue : item.duplicate
            const isDuplicate = duplicate === 1
            return Object.assign({}, item, { amount: item.amount, balance: item.balance, date: this.$moment(item.date).format('YYYY-MM-DD'), raw_string: item.raw_string, title: item.title, transaction_category: item.transaction_category, transaction_type: item.transaction_type, type: row.type, percentage: row.percentage || 0, accounts: row.accounts || null, suggestion: item.suggestion && row.accounts ? false : item.suggestion, duplicate: duplicate, is_duplicate: isDuplicate, description: item.description || null })
          } else {
            if (row.title === item.title && row.suggestion === true && item.suggestion === false && !item.account && item.index === row.index) {
              return Object.assign({}, item, { amount: item.amount, balance: item.balance, date: this.$moment(item.date).format('YYYY-MM-DD'), raw_string: item.raw_string, title: item.title, description: item.description || null, transaction_category: item.transaction_category, transaction_type: item.transaction_type, type: row.type, percentage: row.percentage || 0, accounts: row.accounts || null, suggestion: allStatus === true ? (item.suggestion && row.accounts ? false : item.suggestion) : item.suggestion, duplicate: item.duplicate, is_duplicate: item.is_duplicate })
            }
            return Object.assign({}, item, { amount: item.amount, balance: item.balance, description: item.description || null, date: this.$moment(item.date).format('YYYY-MM-DD'), raw_string: item.raw_string, title: item.title, transaction_category: item.transaction_category, transaction_type: item.transaction_type, type: item.type, percentage: item.percentage || 0, accounts: item.accounts || null, suggestion: allStatus === true ? (item.suggestion && row.accounts ? false : item.suggestion) : item.suggestion, duplicate: item.duplicate, is_duplicate: item.is_duplicate })
          }
        } else {
          if (row.time && item.time === row.time && item.index === row.index) {
            return Object.assign({}, item, { amount: item.amount, date: this.$moment(item.date).format('YYYY-MM-DD'), time: item.time, title: item.title, type: row.type || null, percentage: row.percentage || 0, accounts: row.accounts || null, description: item.description || null })
          } else {
            if (row.title === item.title && row.suggestion === true && item.suggestion === false && !item.account && item.index === row.index) {
              return Object.assign({}, item, { amount: item.amount, date: this.$moment(item.date).format('YYYY-MM-DD'), time: item.time, title: item.title, type: row.type || null, percentage: row.percentage || 0, accounts: row.accounts || null, description: item.description || null })
            }
            return Object.assign({}, item, { amount: item.amount, date: this.$moment(item.date).format('YYYY-MM-DD'), time: item.time, title: item.title, type: item.type || null, percentage: item.percentage || 0, accounts: item.accounts || null, description: item.description || null })
          }
        }
      })
      const filteredTrans = transactionsUpdated.filter(item => item.raw_string)
      const filteredAdd = transactionsUpdated.filter(item => !item.raw_string)
      this.$emit('update-transactions', { transactions: filteredTrans, user_transactions: filteredAdd })
    },
    updateAllTrans () {
      // eslint-disable-next-line
      return new Promise ((resolve, reject) => {
        // eslint-disable-next-line
        this.Transactions.filter(item => item.accounts && item.title).map(items => {
          this.updateTransactionRow(items, true)
          return items
        }).map(items => items.index).forEach(items => {
          if ((this.disableSingleRows).indexOf(items) === -1) {
            this.disableSingleRows.push(items)
          }
          if ((this.enableEditingDetails).indexOf(items) > -1) {
            this.enableEditingDetails.splice((this.enableEditingDetails).indexOf(items), 1)
          }
        })
        resolve(true)
      })
    },
    validate () {
      this.triggerValidations = true
      return this.updateAllTrans().then(async (data) => {
        if (this.allCategorySelected === 0 && this.allTransactions.length > 0) {
          if (this.allTransactionsTitleExists === 0) {
            this.$emit('validate-success', this.finalModel)
            return true
          } else {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.message_title', { count: this.allTransactionsTitleExists }), {
              title: this.$t(this.translationPath + 'toast.title'),
              variant: 'danger',
              solid: true
            })
            return false
          }
        } else {
          // let InfoText = this.currentUser.strict_accounting_mode ? 'account' : 'category'
          if (this.allTransactions.length === 0) {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.no_transactions'), {
              title: this.$t(this.translationPath + 'toast.title'),
              variant: 'danger',
              solid: true
            })
          }
          return false
        }
      })
    },
    onRowSelected (items) {
      this.selected = items
    },
    editCurrentTransaction () {
      this.displayEdit = true
    },
    fetchGropSuggestions (trans) {
      const transactions = trans.map(item => {
        return Object.assign({}, { title: item.title, amount: item.amount, type: item.type !== 'transfer_to' && item.type !== 'transfer_from' && item.type !== 'transfer' ? item.type : 'asset' })
      })
      return axios.post(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/suggest-accounts`, { transactions })
        .then(response => response.data)
        .then(ResponseData => { return ResponseData.data })
        .catch(err => {
          console.error(err)
          return []
        })
    },
    fetchAccountRequestHandler (transactionType) {
      const AccountType = this.transactionTypeIdentify(transactionType, true)
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=ImportAccountStatementWizardStep03`)
        .then(response => {
          const optionArray = []
          const accountGroups = response.data.data
          Object.keys(accountGroups).forEach(function (key) {
            const AssociateAccounts = accountGroups[key].accounts.filter((el) => el.status !== 'suspended')
            if (AssociateAccounts.length > 0) {
              if (AccountType === 'asset,liability') {
                optionArray.push({ title: (this.$te('common.' + accountGroups[key].type.toLowerCase()) ? this.$t('common.' + accountGroups[key].type.toLowerCase()) : accountGroups[key].type) + ' / ' + accountGroups[key].title, type: accountGroups[key].type, accounts: AssociateAccounts })
              } else {
                optionArray.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: AssociateAccounts })
              }
            }
          }.bind(this))
          if (AccountType === 'asset,liability') {
            this.infoTabAccounts.transfer = optionArray
          } else {
            this.infoTabAccounts[AccountType] = optionArray
          }
          return optionArray
        })
        .catch(error => {
          console.error(error)
          return []
        })
    },
    async fetchAccountsByType (type) {
      return new Promise((resolve, reject) => {
        const AccountType = this.transactionTypeIdentify(type, true)
        if (type === 'asset') {
          type = 'transfer_to'
        } else if (type === 'liability') {
          type = 'transfer_from'
        }
        if (!this.optionsData[type].loadingstatus) {
          this.optionsData[type].loadingstatus = true
          this.optionsData[type].data = []
          this.fetchAccountRequestHandler(type)
            .then(optionArray => {
              this.optionsData[type].data = AccountType === 'asset,liability' ? optionArray.sort(function (a, b) {
                const nameA = a.type
                const nameB = b.type
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }
                return 0
              }) : optionArray
              if (this.optionsData[type].data && this.optionsData[type].data.length > 0) {
                const allAccounts = this.optionsData[type].data.map(item => item.accounts).reduce((val, acc) => Object.assign([], [...val, ...acc])).filter((el) => el.status !== 'suspended').map(itemAcc => itemAcc.title).map(itemAcc => itemAcc.length)
                const sortAllAccountLength = allAccounts.length > 0 ? allAccounts.sort((a, b) => {
                  if (a > b) {
                    return -1
                  }
                  if (a < b) {
                    return 1
                  }
                  return 0
                }) : allAccounts
                const maximumCharacters = sortAllAccountLength.length > 0 ? sortAllAccountLength[0] : 0
                this.optionsData[type].maxLength = maximumCharacters >= 30 ? maximumCharacters : 0
              }
              resolve(true)
            })
            .catch(err => {
              console.error(err)
              reject(err)
            })
            .finally(() => {
              this.optionsData[type].loadingstatus = false
            })
        }
      })
    },
    async fetchAccountsByTypeCondition (type) {
      return this.fetchAccountRequestHandler(type)
        .then((optionArray) => {
          return optionArray.map(items => items.accounts)
        })
    },
    handleUpdateTransactions (values) {
      this.submitted = true
      const submittedObj = Object.assign({}, values)
      if ((this.selected).length > 0) {
        _.forEach(this.selected, function (item, key) {
          if (Object.keys(submittedObj).indexOf('date_operator') > -1) {
            switch (submittedObj.date_operator) {
              case 'set_date':
                item.date = this.$moment(submittedObj.date_value).format('YYYY-MM-DD').toString()
                break
              case 'add_days':
                item.date = this.$moment(item.date).add(submittedObj.date_value, 'days').format('YYYY-MM-DD').toString()
                break
              case 'add_months':
                item.date = this.$moment(item.date).add(submittedObj.date_value, 'months').format('YYYY-MM-DD').toString()
                break
              case 'add_year':
                item.date = this.$moment(item.date).add(submittedObj.date_value, 'years').format('YYYY-MM-DD').toString()
                break
              case 'subtract_days':
                item.date = this.$moment(item.date).subtract(submittedObj.date_value, 'days').format('YYYY-MM-DD').toString()
                break
              case 'subtract_months':
                item.date = this.$moment(item.date).subtract(submittedObj.date_value, 'months').format('YYYY-MM-DD').toString()
                break
              case 'subtract_years':
                item.date = this.$moment(item.date).subtract(submittedObj.date_value, 'years').format('YYYY-MM-DD').toString()
                break
            }
          }
          if (Object.keys(submittedObj).indexOf('amount_operator') > -1) {
            if (item.type === 'income' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'expense'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            } else if (item.type === 'expense' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'income'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            } else if (item.type === 'transfer_to' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'transfer_from'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            } else if (item.type === 'transfer_from' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'transfer_to'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            }
            switch (submittedObj.amount_operator) {
              case 'set_amount': {
                item.amount = parseFloat(submittedObj.amount_value) >= 0 ? parseFloat(submittedObj.amount_value) : 0
                break
              }
              case 'multiply_with': {
                item.amount = parseFloat(item.amount) * parseFloat(submittedObj.amount_value)
                break
              }
              case 'divide_by': {
                item.amount = parseFloat(item.amount) / parseFloat(submittedObj.amount_value)
                break
              }
              case 'subtract_by': {
                const updatedAmount = parseFloat(item.amount) - parseFloat(submittedObj.amount_value)
                item.amount = updatedAmount < 0 ? 0 : updatedAmount
                break
              }
              case 'add_to': {
                item.amount = parseFloat(item.amount) + parseFloat(submittedObj.amount_value)
                break
              }
            }
          }
          if (submittedObj.title_operator && submittedObj.title_value) {
            switch (submittedObj.title_operator) {
              case 'remove_part':
                item.title = (item.title).replace(submittedObj.title_value, '')
                break
              case 'append_part':
                item.title = (item.title + ' ' + submittedObj.title_value).trim()
                break
              case 'set_title':
                item.title = submittedObj.title_value ? submittedObj.title_value : item.title
                break
              case 'prefix_part':
                item.title = (submittedObj.title_value + ' ' + item.title).trim()
                break
            }
          } else if (submittedObj.title_operator && !submittedObj.title_value) {
            switch (submittedObj.title_operator) {
              case 'lowercase':
                item.title = (item.title).toLowerCase()
                break
              case 'upper_first':
                item.title = _.upperFirst((item.title).toLowerCase())
                break
              case 'capitalize':
                item.title = (item.title).toUpperCase()
                break
            }
          }
          if (Object.keys(submittedObj).indexOf('accounts') > -1) {
            item.accounts = submittedObj.accounts
            item.percentage = submittedObj.accounts.default_active_percentage
          }
          if (Object.keys(submittedObj).indexOf('percentage') > -1) {
            item.percentage = submittedObj.percentage
          }
          if (Object.keys(submittedObj).indexOf('transaction_type') > -1) {
            item.type = submittedObj.transaction_type
          }
          if (Object.keys(submittedObj).indexOf('duplicate') > -1) {
            item.duplicate = submittedObj.duplicate
          }
          if (Object.keys(submittedObj).indexOf('is_duplicate') > -1) {
            item.is_duplicate = submittedObj.is_duplicate
          }
          if (item.accounts && item.suggestion === true) {
            item.suggestion = false
          }
        }.bind(this))
        const parseTransactions = this.Transactions.filter(item => item.raw_string)
        const addTransactions = this.Transactions.filter(item => item.time)
        this.massUpdate = true
        this.$emit('update-transactions', { transactions: parseTransactions, user_transactions: addTransactions })
        this.$refs.transactionTableView.refresh()
        this.selected = []
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_mulitple_transactions'), {
          variant: 'success',
          'auto-hide-delay': 5000,
          title: this.$t(this.translationPath + 'toast.title')
        })
      }
    },
    reset () {
      this.disableSingleRows = []
      this.enableEditingDetails = []
      this.triggerValidations = false
      this.busyState = false
    },
    displayLoader () {
      this.busyState = true
    },
    removeIndexFromDisable (rowData) {
      const disabledRows = this.disableSingleRows
      const findIndex = (rowData.index).toString().split(',').map(item => Number(item))
      if (findIndex.length === 1) {
        const findHandleIndex = disabledRows.indexOf(findIndex[0])
        if (findHandleIndex !== -1 && findHandleIndex <= disabledRows.length - 1) {
          let startIndex = findHandleIndex
          disabledRows.splice(startIndex, 1)
          if (disabledRows.length !== 0) {
            while (startIndex <= disabledRows.length - 1) {
              if (disabledRows[startIndex] !== 0) {
                disabledRows[startIndex] = disabledRows[startIndex] - 1
              } else {
                disabledRows.splice(startIndex, 1)
              }
              startIndex++
            }
          }
        }
      } else {
        if (disabledRows.length > 0) {
          const indexArray = []
          for (let i = 0; i <= findIndex.length - 1; i++) {
            if (disabledRows.indexOf(findIndex[i]) > -1) {
              indexArray.push(disabledRows.indexOf(findIndex[i]))
            }
          }
          let startIndex = 0
          let startIndexFilter = 0
          while (startIndex <= indexArray.length - 1) {
            let start = indexArray[startIndex] + 1
            while (start <= disabledRows.length - 1) {
              disabledRows[start] = disabledRows[start] - 1
              start++
            }
            startIndex++
          }
          indexArray.sort(function (a, b) {
            if (b > a) {
              return 1
            }
            return -1
          })
          while (startIndexFilter <= indexArray.length - 1) {
            disabledRows.splice(indexArray[startIndexFilter], 1)
            startIndexFilter++
          }
        }
      }
    },
    resetEditState () {
      this.disableSingleRows = []
    },
    loadData () {
      const ParseTransaction = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'transactions') && this.finalModel.transactions ? this.finalModel.transactions : []
      const AddedTrasactions = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'user_transactions') && this.finalModel.user_transactions ? this.finalModel.user_transactions : []
      const filterParse = ParseTransaction.map((item, index) => {
        const TransactionType = this.transactionTypeIdentify(item.type)
        const transactionObject = Object.assign({}, item, {
          type: TransactionType,
          suggestion: item.suggestion || false,
          realIndex: index
        })
        // get accounts options
        if (item.suggestion_account_id > -1 && !item.accounts) {
          const FilterSuggestGroup = this.accountsGroups.filter(accItem => accItem.id === item.suggestion_account_id)
          const selectedAccount = FilterSuggestGroup.length > 0 ? FilterSuggestGroup[0] : null
          Object.assign(transactionObject, {
            accounts: selectedAccount,
            percentage: selectedAccount && Object.prototype.hasOwnProperty.call(selectedAccount, 'default_active_percentage') && selectedAccount.default_active_percentage ? selectedAccount.default_active_percentage : 0,
            type: selectedAccount && Object.prototype.hasOwnProperty.call(selectedAccount, 'type') && selectedAccount.type && ['income', 'expense'].indexOf(selectedAccount.type) > -1 ? selectedAccount.type : item.type,
            suggestion: selectedAccount !== null
          })
        }
        if (!Object.prototype.hasOwnProperty.call(item, 'beforeUpdateIndex')) {
          Object.assign(transactionObject, { beforeUpdateIndex: null })
        }
        return transactionObject
      })
      const filterAdded = AddedTrasactions.map((item, index) => {
        const TransactionType = this.transactionTypeIdentify(item.type)
        return Object.assign({}, { amount: Number(item.amount), date: item.date, time: item.time, title: item.title, type: TransactionType || null, percentage: item.percentage || 0, accounts: item.accounts || null, description: item.description || null, duplicate: 0, realIndex: index, is_new: item.is_new || false, beforeUpdateIndex: item.beforeUpdateIndex || null })
      })
      const MergeTransactions = [...filterParse, ...filterAdded]
      const FilteredTypes = MergeTransactions.filter(item => item.type).map(item => { return item.type })
      const uniqueTypes = _.uniq(FilteredTypes)
      _.forEach(uniqueTypes, function (value) {
        this.fetchAccountsByType(value)
      }.bind(this))
      MergeTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      let mapTransactions = MergeTransactions.map((items, index) => {
        return Object.assign({}, items, {
          index: index,
          suggestion: items.suggestion || false,
          beforeUpdateIndex: items.beforeUpdateIndex == null ? index : items.beforeUpdateIndex
        })
      })
      if (this.massUpdate) {
        if (this.enableEditingDetails.length > 0) {
          const filterIndex = mapTransactions.filter(item => this.enableEditingDetails.indexOf(item.beforeUpdateIndex) > -1).map(item => item.index)
          this.enableEditingDetails = filterIndex
          const disableSingleRows = mapTransactions.filter(item => item.accounts).filter(item => item.suggestion === false).filter(item => filterIndex.indexOf(item.index) === -1).map(item => item.index)
          this.disableSingleRows = disableSingleRows
        } else {
          const disableSingleRows = mapTransactions.filter(item => item.accounts).filter(item => item.suggestion === false).map(item => item.index)
          this.disableSingleRows = disableSingleRows
        }
        this.massUpdate = false
        mapTransactions = mapTransactions.map(item => {
          return Object.assign(item, {
            beforeUpdateIndex: item.index
          })
        })
      }
      if (this.addNewTransaction) {
        mapTransactions = mapTransactions.map(item => {
          return Object.assign(item, {
            beforeUpdateIndex: item.index
          })
        })
      }
      this.Transactions = mapTransactions
      this.TransactionTotalRows = this.Transactions.length
      this.TransactionPerPage = this.Transactions.length
      this.busyState = false
    },
    onAddNewAccountClick (row) {
      let accountType = 'liability'
      switch (row.item.type) {
        case 'income':
          accountType = 'income'
          break
        case 'expense':
        case 'refund':
          accountType = 'expense'
          break
        case 'transfer_to':
        case 'transfer_from':
          accountType = 'asset'
          break
        default:
          accountType = 'income'
          break
      }
      this.$refs.CreateAccountModal.show({ type: accountType }, { row: row })
    },
    onCreateAccountModalPerformed (modalData) {
      switch (modalData.storedAccount.type) {
        case 'income':
          modalData.meta.row.item.type = 'income'
          break
        case 'expense':
          if (['expense', 'refund'].indexOf(modalData.meta.row.item.type) === -1) {
            modalData.meta.row.item.type = 'expense'
          }
          break
        case 'asset':
        case 'liability':
          if (['transfer_to', 'transfer_from'].indexOf(modalData.meta.row.item.type) === -1) {
            modalData.meta.row.item.type = 'transfer_to'
          }
          break
      }
      this.fetchAccountsByType(modalData.storedAccount.type)
        .then(() => {
          this.updateAccounts({ accounts: modalData.storedAccount }, modalData.meta.row)
        })
    }
  },
  watch: {
    selected: function (newVal, oldVal) {
      if (this.displayEdit === true && newVal.length === 0) {
        this.displayEdit = false
        this.submitted = false
      }
      if (newVal.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newVal.length === this.$refs.transactionTableView.items.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
    displayCategoryStatus: function (newVal, oldVal) {
      if (!newVal) {
        this.category = null
      }
    },
    finalModel: function () {
      this.loadData()
    }
  },
  filters: {
    formatAmount,
    percentageFilter (value) {
      return value + '%'
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/modal.scss';
@import '@/assets/scss/import-wizard-3.scss';
</style>
