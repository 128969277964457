<template>
  <b-row v-if="displayForm" class="bg-white depth-1 mb-3 mb-md-3 edit-row" no-gutters>
    <b-col cols="12">
      <h4>{{$t(translationPath + 'heading')}}</h4>
    </b-col>
    <b-col cols="12">
      <p>
        {{$t(translationPath + 'intro')}}
      </p>
    </b-col>
    <b-col cols="12">
      <b-form class="edit-form-import" @submit="submitForm">
        <!--date-->
        <b-row no-gutters class="mb-0 mb-md-3">
          <b-col cols="2" md="2" class="text-left">
            <legend class="col-form-label customPadding">{{$t(translationPath + 'labels.date')}}</legend>
          </b-col>
          <b-col cols="12" md="3" class="pr-0 pr-md-3">
            <b-form-group
              :state="dateState"
              :invalid-feedback="dateInvalidFeed"
              class="mb-3 mb-md-0"
            >
              <b-form-select
                :options="dateOptions"
                v-model="date_operator"
                :state="dateState"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :state="dateValueState"
              :invalid-feedback="dateValueInvalidFeed"
              class="mb-3 mb-md-0"
            >
              <b-form-input
                :type="date_operator!=='set_date'?'number':'text'"
                v-model="date_value"
                :state="dateValueState"
                :placeholder="date_operator!=='set_date'?'':'YYYY-MM-DD'"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <!--title-->
        <b-row no-gutters class="mb-0 mb-md-3">
          <b-col cols="2" md="2" class="text-left">
            <legend class="col-form-label customPadding">{{$t(translationPath + 'labels.title')}}</legend>
          </b-col>
          <b-col cols="12" md="3" class="pr-0 pr-md-3 mb-3 mb-md-0">
            <b-form-group
              class="mb-0"
              :state="titleState"
              :invalid-feedback="titleInvalidFeed"
            >
              <b-form-select
                :options="titleOptions"
                v-model="title_operator"
                :state="titleState"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="pr-0">
            <b-form-group
              class="mb-0"
              :state="titleValueState"
              :invalid-feedback="titleValueInvalidFeed"
            >
              <b-form-input
                type="text"
                v-model="title_value"
                :state="titleValueState"
                :disabled="disableTitleValue"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <!--transaction type-->
        <b-row no-gutters>
          <b-col>
            <b-form-group
              label-cols="12"
              label-cols-md="2"
              :label="$t(translationPath + 'labels.type')"
              :state="typeState"
            >
              <b-form-select
                v-model="transaction_type"
                :options="transactionTypeOptions"
                @change="resetAccountGroup"
                :state="typeState"
                class="marginLeft-2">
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <!--transaction category-->
        <b-row class="mt-2" no-gutters>
          <b-col>
            <b-form-group
              label-cols="12"
              label-cols-md="2"
              :label="currentUser.strict_accounting_mode ? $t(translationPath + 'labels.account') : $t(translationPath + 'labels.category')"
              :state="accountState"
            >
              <custom-select
                :key="'mass-edit-select'"
                v-model="account_group"
                :options="accountsOption"
                class="marginLeft-2"
                v-bind:class="{'is-valid': accountState === true}"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="currentUser.strict_accounting_mode ? $t('common.select_account') : $t('common.select_category')"
                :noResultText="currentUser.strict_accounting_mode ? $t('common.no_account_found') : $t('common.no_category_found')"
                @select="syncPercentage" />
            </b-form-group>
          </b-col>
        </b-row>
        <!--percentage-->
        <b-row class="mt-2" no-gutters>
          <b-col>
            <b-form-group
              label-for="account_percentage"
              label-class="customPadding"
              label-cols-md="2"
              label-align-lg="left"
              :state="percentageState"
            >
              <template slot="label"><strong>{{$t(translationPath + 'labels.percentage')}}</strong></template>
              <template slot="description">{{ $t(translationPath + 'percentage_help_text' , {active: percentage, passive: passivePercentageValue })}}</template>
              <b-col sm="12" md="10" class="float-left paddingLeftHide mobilePaddingLR-0 mb-2 mb-md-0 pb-2 pt-2">
                <b-form-input
                  id="account_percentage_slider"
                  class="slider"
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  :state="percentageState"
                  v-model="percentage"
                ></b-form-input>
              </b-col>
              <b-col sm="12" md="2" class="float-left mb-2 paddingLeftHide mobilePaddingLR-0 pr-0">
                <b-input-group append="%">
                  <b-form-input
                    id="account_percentage"
                    type="number"
                    min="0"
                    max="100"
                    :state="percentageState"
                    v-model="percentage"
                    :disabled="true"
                  ></b-form-input>
                </b-input-group>
              </b-col>
            </b-form-group>
          </b-col>
        </b-row>
        <!--amount-->
        <b-row no-gutters class="mb-0 mb-md-3">
          <b-col cols="2" md="2" class="text-left">
            <legend class="col-form-label customPadding">{{$t(translationPath + 'labels.amount')}}</legend>
          </b-col>
          <b-col cols="12" md="3" class="pr-0 pr-md-3 mb-3 mb-md-0">
            <b-form-group class="mb-0" :state="amountState" :invalid-feedback="amountInvalidFeed">
              <b-form-select :options="amountOptions" v-model="amount_operator" :state="amountState"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="mb-0" :state="amountValueState" :invalid-feedback="amountValueInvalidFeed">
              <currency-input
                v-model="amount_value"
                :distraction-free="options.amount"
                :state="amountValueState"
                :input-class="{'is-invalid': amountValueState === false, 'is-valid': amountValueState}"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!--action button-->
        <b-row class="mt-4 mb-3" no-gutters>
          <b-col class="pr-0 text-right">
            <b-button type="button" variant="secondary" class="btn-sm addTransButton" @click="hide">
              {{$t(translationPath + 'labels.cancel')}}</b-button>
            <b-button type="submit" variant="primary" class="btn-sm addTransButton ml-2">
              {{$t(translationPath + 'labels.submit')}}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import CustomSelect from './CustomSelect'
import CurrencyInput from './CurrencyInput'

export default {
  name: 'MassEditImportForm',
  props: {
    offsetAccountName: {
      type: String,
      default: null
    },
    currentUser: {
      type: Object,
      default: function () {
        return {}
      }
    },
    currentCOA: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  components: {
    CustomSelect,
    CurrencyInput
  },
  data () {
    return {
      displayForm: false,
      translationPath: 'common.masseditform.',
      submitted: false,
      date_operator: null,
      date_value: null,
      title_operator: null,
      title_value: null,
      transaction_type: null,
      account_group: null,
      percentage: null,
      amount_operator: null,
      amount_value: null,
      accountsOption: [],
      loadingAccounts: false,
      options: {
        title_values: ['lowercase', 'upper_first', 'capitalize'],
        amount: {
          hideCurrencySymbol: true
        }
      }
    }
  },
  computed: {
    dateOptions () {
      return [
        { value: null, text: '' },
        { value: 'set_date', text: this.$t(this.translationPath + 'operators.set_date') },
        { value: 'add_days', text: this.$t(this.translationPath + 'operators.add_days') },
        { value: 'add_months', text: this.$t(this.translationPath + 'operators.add_months') },
        { value: 'add_year', text: this.$t(this.translationPath + 'operators.add_year') },
        { value: 'subtract_days', text: this.$t(this.translationPath + 'operators.subtract_days') },
        { value: 'subtract_months', text: this.$t(this.translationPath + 'operators.subtract_months') },
        { value: 'subtract_years', text: this.$t(this.translationPath + 'operators.subtract_years') }
      ]
    },
    titleOptions () {
      return [
        { value: null, text: '' },
        { value: 'set_title', text: this.$t(this.translationPath + 'operators.set_title') },
        { value: 'remove_part', text: this.$t(this.translationPath + 'operators.remove_part') },
        { value: 'append_part', text: this.$t(this.translationPath + 'operators.append_part') },
        { value: 'prefix_part', text: this.$t(this.translationPath + 'operators.prefix_part') },
        { value: 'lowercase', text: this.$t(this.translationPath + 'operators.lowercase') },
        { value: 'upper_first', text: this.$t(this.translationPath + 'operators.upper_first') },
        { value: 'capitalize', text: this.$t(this.translationPath + 'operators.capitalize') }
      ]
    },
    transactionTypeOptions () {
      return [
        { value: 'income', text: this.$t('common.transaction_types.deposit') },
        { value: 'expense', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'refund', text: this.$t('common.transaction_types.refund') },
        { value: 'transfer_to', text: this.$t(this.translationPath + 'transaction_type_options.transfer_to', { offsetAccountName: this.offsetAccountName }) },
        { value: 'transfer_from', text: this.$t(this.translationPath + 'transaction_type_options.transfer_from', { offsetAccountName: this.offsetAccountName }) }
      ]
    },
    amountOptions () {
      return [
        { value: 'set_amount', text: this.$t(this.translationPath + 'operators.set_amount') },
        { value: 'multiply_with', text: this.$t(this.translationPath + 'operators.multiply_with') },
        { value: 'divide_by', text: this.$t(this.translationPath + 'operators.divide_by') },
        { value: 'subtract_by', text: this.$t(this.translationPath + 'operators.subtract_by') },
        { value: 'add_to', text: this.$t(this.translationPath + 'operators.add_to') }
      ]
    },
    dateState () {
      if (this.submitted) {
        if (this.date_value && !this.date_operator) {
          return false
        } else if (this.date_operator) {
          return true
        } else {
          return null
        }
      }
      return null
    },
    dateInvalidFeed () {
      if (this.submitted && this.date_value && !this.date_operator) {
        return this.$t(this.translationPath + 'errors.select_required')
      }
      return ''
    },
    dateValueState () {
      if (this.submitted) {
        if (this.date_operator && !this.date_value) {
          return false
        } else if (this.date_operator && this.date_operator !== 'set_date' && this.date_value && this.date_value <= 0) {
          return false
        } else if (this.date_operator && this.date_operator === 'set_date' && !this.$moment(this.date_value, 'YYYY-MM-DD', true).isValid()) {
          return false
        } else if (this.date_value) {
          return true
        }
      }
      return null
    },
    dateValueInvalidFeed () {
      if (this.submitted) {
        if (this.date_operator && !this.date_value) {
          return this.$t(this.translationPath + 'errors.field_required')
        } else if (this.date_operator && this.date_operator !== 'set_date' && this.date_value && this.date_value <= 0) {
          return this.$t(this.translationPath + 'errors.non_zero_error')
        } else if (this.date_operator && this.date_operator === 'set_date' && !this.$moment(this.date_value, 'YYYY-MM-DD', true).isValid()) {
          return this.$t(this.translationPath + 'errors.invalid_date')
        } else {
          return ''
        }
      }
      return ''
    },
    titleState () {
      if (this.submitted) {
        if (this.title_value && !this.title_operator) {
          return false
        } else if (this.title_operator) {
          return true
        } else {
          return null
        }
      }
      return null
    },
    titleInvalidFeed () {
      if (this.submitted && this.title_value && !this.title_operator) {
        return this.$t(this.translationPath + 'errors.select_required')
      }
      return ''
    },
    titleValueState () {
      if (this.submitted) {
        if (this.title_operator && this.options.title_values.indexOf(this.title_operator) === -1 && !this.title_value) {
          return false
        } else if (this.title_value) {
          return true
        }
        return null
      }
      return null
    },
    titleValueInvalidFeed () {
      if (this.submitted && this.title_operator && !this.title_value) {
        return this.$t(this.translationPath + 'errors.field_required')
      }
      return ''
    },
    disableTitleValue () {
      return this.options.title_values.indexOf(this.title_operator) > -1
    },
    typeState () {
      return this.submitted ? (this.transaction_type && this.transaction_type !== null ? true : null) : null
    },
    accountState () {
      return this.submitted ? (this.account_group && this.account_group !== null ? true : null) : null
    },
    percentageState () {
      return this.submitted ? (this.percentage || parseFloat(this.percentage) === 0 ? true : null) : null
    },
    passivePercentageValue () {
      return 100 - this.percentage
    },
    amountState () {
      if (this.submitted) {
        if (this.amount_value && !this.amount_operator) {
          return false
        } else if (this.amount_operator) {
          return true
        }
        return null
      }
      return null
    },
    amountInvalidFeed () {
      if (this.submitted && this.amount_value && !this.amount_operator) {
        return this.$t(this.translationPath + 'errors.select_required')
      }
      return ''
    },
    amountValueState () {
      if (this.submitted) {
        if (this.amount_operator && !this.amount_value) {
          return false
        } else if (this.amount_operator && this.amount_value && this.amount_value === 0) {
          return false
        } else if (this.amount_value) {
          return true
        } else {
          return null
        }
      }
      return null
    },
    amountValueInvalidFeed () {
      if (this.submitted) {
        if (this.amount_operator && !this.amount_value) {
          return this.$t(this.translationPath + 'errors.field_required')
        } else if (this.amount_operator && this.amount_value && this.amount_value === 0) {
          return this.$t(this.translationPath + 'errors.non_zero_error')
        } else {
          return ''
        }
      }
      return ''
    },
    validationCheck () {
      return this.dateState !== false && this.dateValueState !== false && this.titleState !== false && this.titleValueState !== false && this.amountState !== false && this.amountValueState !== false
    }
  },
  methods: {
    reset () {
      this.date_operator = null
      this.date_value = null
      this.title_operator = null
      this.title_value = null
      this.transaction_type = null
      this.account_group = null
      this.percentage = null
      this.amount_operator = null
      this.amount_value = null
      this.submitted = false
    },
    show () {
      this.displayForm = true
    },
    hide () {
      this.displayForm = false
    },
    submitForm (e) {
      e.preventDefault()
      this.submitted = true
      const submittedObj = {}
      if (this.transaction_type) {
        Object.assign(submittedObj, { transaction_type: this.transaction_type })
      }
      if (this.account_group) {
        Object.assign(submittedObj, { accounts: this.account_group })
      }
      if ((this.percentage || parseFloat(this.percentage) >= 0) && this.percentage >= 0) {
        Object.assign(submittedObj, { percentage: this.percentage })
      }
      if (this.date_operator && this.date_value) {
        Object.assign(submittedObj, { date_operator: this.date_operator, date_value: this.date_value })
      }
      if (this.title_operator || this.title_value) {
        Object.assign(submittedObj, { title_operator: this.title_operator, title_value: this.title_value })
      }
      if (this.amount_operator && this.amount_value) {
        Object.assign(submittedObj, { amount_operator: this.amount_operator, amount_value: parseFloat(this.amount_value) })
      }
      if ((this.date_operator && this.date_value) || (this.title_operator || this.title_value) || (this.amount_operator && this.amount_value)) {
        Object.assign(submittedObj, { duplicate: 0, is_duplicate: false })
      }
      if (Object.keys(submittedObj).length > 0 && this.validationCheck === true) {
        this.$emit('save-changes', submittedObj)
        this.hide()
      }
    },
    resetAccountGroup (value) {
      this.account_group = null
    },
    syncPercentage (selectedOpt, id) {
      this.percentage = selectedOpt.default_active_percentage
    },
    loadAccountsApiMethod () {
      // this.loadingAccounts = true
      let AccountType = null
      switch (this.transaction_type) {
        case 'income':
          AccountType = 'income'
          break
        case 'expense':
          AccountType = 'expense'
          break
        case 'refund':
          AccountType = 'expense'
          break
        default:
          AccountType = 'asset,liability'
      }
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=MassEditImportForm`)
        .then(response => response.data.data)
        .then(responseData => {
          const optionArray = []
          const accountGroups = responseData
          Object.keys(accountGroups).forEach(function (key) {
            const AssociateAccounts = accountGroups[key].accounts.filter((el) => el.status !== 'suspended')
            if (AssociateAccounts.length > 0) {
              if (AccountType === 'asset,liability') {
                optionArray.push({ title: (this.$te('common.' + accountGroups[key].type.toLowerCase()) ? this.$t('common.' + accountGroups[key].type.toLowerCase()) : accountGroups[key].type) + ' / ' + accountGroups[key].title, type: accountGroups[key].type, accounts: AssociateAccounts })
              } else {
                optionArray.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: AssociateAccounts })
              }
            }
          }.bind(this))
          return AccountType === 'asset,liability' ? optionArray.sort(function (a, b) {
            const nameA = a.type
            const nameB = b.type
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }) : optionArray
        })
        .catch(error => {
          console.error(error)
          return []
        })
    }
  },
  watch: {
    displayForm: function (newVal) {
      if (newVal === true) {
        this.$emit('show-import-form')
      } else {
        this.$emit('hide-import-form')
        this.reset()
      }
    },
    loadingAccounts: function (newVal) {
      if (newVal === true) {
        this.loadAccountsApiMethod()
          .then(accounts => {
            this.accountsOption = accounts
            this.loadingAccounts = false
          })
      }
    },
    transaction_type: function (newVal) {
      if (newVal) {
        this.loadingAccounts = true
      }
    }
  }
}
</script>

<style lang="scss">
.edit-row {
    padding: 10px;
    .paddingLeftHide {
      @media screen and (min-width: 768px) {
        padding-left: 0;
      }
    }
    .edit-form-import {
      display: inline-block;
      width: 100%;
      padding: 0px 15px 0px;
      .customPadding {
        padding-top:14px;
        @media screen and (max-width: 768px) {
          padding-top: 0px;
        }
      }
      .mobilePaddingLR-0 {
        @media screen and (max-width: 768px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .marginLeft-2 {
        margin-left: 2px;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
        }
      }
      label {
        font-weight: 900;
      }
      legend {
        font-weight: 900;
        align-self: center;
      }
      .slider {
        margin-top: 15px;
      }
      textarea{
        font-size:75%;
        font-family: "Lucida Console";
      }
      .custom-select{
        height:46px;
      }
      .action-button {
        border:1px solid #0065ff;
        background: #0065ff;
        padding: 5px 20px;
        color: #fff;
        .flaticon {
          color: #fff;
          &.trash-3 {
            color: #EF5350;
          }
        }
      }
    }
  .addTransButton{
    padding:9px 20px;
  }
}
</style>
