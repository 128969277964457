<template>
  <tab-basic :title="tabTitle ? tabTitle : $t(translationPath + 'tab_title')" :ref="$parent.$refs">
    <template v-slot:heading>
      {{ title ? title : $t(translationPath + 'title')}}
    </template>
    <template v-slot:description>
      {{ $t(translationPath + 'description')}}
    </template>
    <template v-slot:content>
      <div class="alert alert-light">
        <code>
          <div class="duplicate-details" v-for="elem in details" :key="'duplicate_' + elem.id">
            <a :href="'/transactions/view/transaction/' + elem.id" target="__blank"> {{ elem.date }} ,
            {{ elem.title }} ,
            {{ $t('common.' + elem.subtype) }} ,
            {{ elem.amount | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true, 2) }}</a>
          </div>
        </code>
      </div>
    </template>
  </tab-basic>
</template>

<script>
import TabBasic from './TabBasic'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'DuplicateInformation',
  components: {
    TabBasic
  },
  filters: { formatAmount },
  props: {
    details: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentCOA: {
      type: Object,
      default: function () {
        return {}
      }
    },
    tabTitle: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_wizard_v2.tab.duplicate.'
    }
  }
}
</script>

<style lang="scss">
.duplicate-details {
  font-size: 13px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: #e83e8c;
  }
}
</style>
