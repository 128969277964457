<template>
  <tab-basic :title="$t(translationPath + 'title')">
    <template v-slot:heading>
      {{ $t(translationPath + 'title')}}
    </template>
    <template v-slot:description>
      {{ $t(translationPath + 'description')}}
    </template>
    <template v-slot:content>
      <b-form @submit="onSubmit" ref="editFormModal">
            <b-form-group
                label-cols-md="1"
                label-cols-lg="1"
                :label="$t(translateKey + 'labels.date')"
                label-align-md="left"
                label-align-sm="left"
                label-for="transactiondate"
                :state="dateState"
                :invalid-feedback="invalidDateFeed"
                required
            >
                <datepicker
                  v-model="transaction.date"
                  :state="dateState"
                  :typeable="true"
                  :placeholder="'YYYY-MM-DD'"
                  :format="'yyyy-MM-dd'"
                  :monday-first="startWeekByMonday"
                  v-bind:input-class="{'form-control': true, 'is-valid': dateState === true, 'is-invalid': dateState === false}"
                ></datepicker>
            </b-form-group>
            <b-form-group
                label-cols-md="1"
                label-cols-lg="1"
                :label="$t(translateKey + 'labels.title')"
                label-align-md="left"
                label-align-sm="left"
                label-for="transactiontitle"
                :state="titleState"
                :invalid-feedback="invalidTitleFeed"
                required
            >
                <b-form-input type="text" id="transactiontitle" v-model="transaction.title" :state="titleState"></b-form-input>
            </b-form-group>
            <b-form-group
                label-cols-md="1"
                label-cols-lg="1"
                :label="$t(translateKey + 'labels.amount')"
                label-align-md="left"
                label-align-sm="left"
                label-for="transactionamount"
                :state="amountState"
                :invalid-feedback="invalidAmountFeed"
                required
            >
              <currency-input
                v-model.number="transaction.amount"
                :min="0"
                :state="amountState"
                :input-class="{'is-invalid': amountState === false, 'is-valid': amountState}"
              />
            </b-form-group>
            <b-row>
              <b-col xs="12" md="1" class="align-self-center">
                <label for="type">{{ currentUser.strict_accounting_mode ? $t(translateKey + 'labels.account') : $t(translateKey + 'labels.category') }}</label>
              </b-col>
              <b-col xs="12" md="4" class="pl-2">
                <b-form-group
                  label-for="type"
                  :state="typeState"
                  :invalid-feedback="invalidTypeFeed"
                  required
                >
                  <b-form-select v-model="transaction.type" :options="transactionTypeOptions" :state="typeState"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" md="7" class="pl-2">
                <b-form-group
                  :state="accountGroupState"
                  :invalid-feedback="accountGroupFeedback"
                >
                <custom-select
                  v-model="transaction.accounts"
                  :options="accountsGroupOption"
                  class="marginLeft-2"
                  v-bind:class="{'is-valid': accountGroupState === true, 'is-invalid': accountGroupState === false}"
                  groupingValues="accounts"
                  groupingLabel="title"
                  label="title"
                  :loading="loadingAccounts"
                  :search-placeholder="$t('common.type_to_filter')"
                  :placeholder="currentUser.strict_accounting_mode ? $t('common.select_account') : $t('common.select_category')"
                  :noResultText="currentUser.strict_accounting_mode ? $t('common.no_account_found') : $t('common.no_category_found')"
                  @select="syncPercentage" />
                </b-form-group>
              </b-col>
            </b-row>
            <!--<template v-if="accountOptions.enable">
              <b-form-group
                label-cols-md="1"
                label-cols-lg="1"
                label-align-md="left"
                label-align-sm="left"
                :state="accountGroupState"
                :invalid-feedback="accountGroupFeedback"
                :label="currentUser.strict_accounting_mode ? $t(translateKey + 'labels.account') : $t(translateKey + 'labels.category')"
              >
              <custom-select
                v-model="transaction.accounts"
                :options="accountsGroupOption"
                class="marginLeft-2"
                v-bind:class="{'is-valid': accountGroupState === true, 'is-invalid': accountGroupState === false}"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="currentUser.strict_accounting_mode ? $t('common.select_account') : $t('common.select_category')"
                :noResultText="currentUser.strict_accounting_mode ? $t('common.no_account_found') : $t('common.no_category_found')"
                @select="syncPercentage" />
              </b-form-group>
            </template>-->
            <template v-if="percentageOptions.enable">
              <b-form-group
                label-for="account_percentage"
                label-class="customPadding"
                label-cols-md="1"
                label-cols-lg="1"
                label-align-md="left"
                label-align-sm="left"
                :state="percentageState"
              >
                <template slot="label">{{$t(translateKey + 'labels.percentage')}}</template>
                <!-- <template slot="description">{{ $t(translateKey + 'percentage_help_text' , {active: percentage, passive: passivePercentageValue })}}</template> -->
                <b-col sm="12" md="10" class="float-left paddingLeftHide mobilePaddingLR-0 mb-2 mb-md-0 pb-2 pt-2">
                  <b-form-input
                    id="account_percentage_slider"
                    class="slider"
                    type="range"
                    min="0"
                    max="100"
                    step="5"
                    :state="percentageState"
                    v-model="transaction.percentage"
                  ></b-form-input>
                </b-col>
                <b-col sm="12" md="2" class="float-left mb-2 paddingLeftHide mobilePaddingLR-0 pr-0">
                  <b-form-input
                    id="account_percentage"
                    type="number"
                    min="0"
                    max="100"
                    :state="percentageState"
                    v-model="transaction.percentage"
                    :disabled="true"
                  ></b-form-input>
                </b-col>
              </b-form-group>
            </template>
            <b-form-group
                label-cols-md="1"
                label-cols-lg="1"
                :label="$t(translateKey + 'labels.description')"
                label-align-md="left"
                label-align-sm="left"
                label-for="transactiondescription"
                :state="descriptionState"
                required
            >
                <b-form-textarea rows="5" id="transactiondescription" v-model="transaction.description" :state="descriptionState"></b-form-textarea>
            </b-form-group>
            <div class="modal-form-button-group text-right mt-4 mb-3">
              <b-button pill type="submit" variant="primary">{{$t('common.save')}}</b-button>
            </div>
          </b-form>
    </template>
  </tab-basic>
</template>

<script>
import CurrencyInput from '@/components/common/CurrencyInput'
import { required, minLength } from 'vuelidate/lib/validators'
import formatDate from '@/assets/filters/formatDate'
import { mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import CustomSelect from '@/components/common/CustomSelect'
import axios from 'axios'
import TabBasic from './TabBasic'

const DecimalNumberCheck = (value) => (/^\d+(\.\d{1,2})?$/g).test(value) || (/^\d+(,\d{1,2})?$/g).test(value)
/* const validDateNotFuture = (value) => {
  let np = new Date()
  let today = new Date(np.getFullYear(), np.getMonth(), np.getDate())

  let chp = new Date(value)
  let check = new Date(chp.getFullYear(), chp.getMonth(), chp.getDate())

  if (check <= today) {
    return true
  }
  return false
} */

export default {
  name: 'InformationTab',
  components: { Datepicker, CurrencyInput, CustomSelect, TabBasic },
  props: {
    accounts: {
      type: Object,
      default: null
    },
    accountOptions: {
      type: Object,
      default: function () {
        return {
          enable: false,
          required: false
        }
      }
    },
    percentageOptions: {
      type: Object,
      default: function () {
        return {
          enable: false,
          required: false,
          defaultValue: 0
        }
      }
    },
    offsetAccountName: {
      type: String,
      default: null
    },
    transactionData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    parserMeta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      // translateKey: 'incomes_expenses.import.import_wizard.step4.modal',
      translateKey: 'common.add_transaction_modal.',
      translationPath: 'transactions.import.import_wizard_v2.tab.information.',
      validated: false,
      transaction: {
        date: new Date(),
        title: null,
        description: null,
        accounts: null,
        type: null,
        percentage: 0
      },
      loadingAccounts: false,
      accountsGroupOption: [],
      percentage: 0,
      money: {
        decimal: ',',
        thousands: ' ',
        precision: 2
      }
    }
  },
  validations: {
    transaction: {
      date: { required },
      title: { required, minLength: minLength(2) },
      amount: { required, DecimalNumberCheck },
      type: { required },
      description: {},
      accounts: {
        requiredValue: function () {
          if (this.accountOptions && Object.keys(this.accountOptions).length > 0) {
            if (Object.keys(this.accountOptions).indexOf('enable') > -1 && typeof this.accountOptions.enable === 'boolean' && this.accountOptions.enable === true) {
              if (Object.keys(this.accountOptions).indexOf('required') > -1 && typeof this.accountOptions.required === 'boolean' && this.accountOptions.required === true) {
                return this.transaction.accounts !== null
              }
              return true
            }
          }
          return true
        }
      },
      percentage: {
        requiredValue: function () {
          if (this.percentageOptions && Object.keys(this.percentageOptions).length > 0) {
            if (Object.keys(this.percentageOptions).indexOf('enable') > -1 && typeof this.percentageOptions.enable === 'boolean' && this.percentageOptions.enable === true) {
              if (Object.keys(this.percentageOptions).indexOf('required') > -1 && typeof this.percentageOptions.required === 'boolean' && this.percentageOptions.required === true) {
                return this.transaction.percentage !== null
              }
              return true
            }
          }
          return true
        }
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    passivePercentageValue () {
      return 100 - this.transaction.percentage
    },
    disabledDates () {
      const currentDate = new Date()
      return { from: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1) }
    },
    transactionTypeOptions () {
      return [
        { value: 'income', text: this.$t('common.transaction_types.deposit') },
        { value: 'expense', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'refund', text: this.$t('common.transaction_types.refund') },
        { value: 'transfer_to', text: this.offsetAccountName ? this.$t('common.transaction_types.transfer_to_account', { offsetAccountName: this.offsetAccountName }) : this.$t('common.transaction_types.transfer_to') },
        { value: 'transfer_from', text: this.offsetAccountName ? this.$t('common.transaction_types.transfer_from_account', { offsetAccountName: this.offsetAccountName }) : this.$t('common.transaction_types.transfer_from') }
        // { value: 'transfer', text: this.$t('common.transaction_types.transfer') }
      ]
    },
    dateState () {
      if (this.$v.transaction.date.$dirty && this.$v.transaction.date.$anyError) {
        return false
      } else if (this.$v.transaction.date.$dirty && !this.$v.transaction.date.$anyError) {
        return true
      } else {
        return null
      }
    },
    invalidDateFeed () {
      if (this.$v.transaction.date.$dirty && !this.$v.transaction.date.required) {
        return this.$t(this.translateKey + 'errors.date_required')
      } else {
        return ''
      }
    },
    titleState () {
      return this.$v.transaction.title.$dirty ? !this.$v.transaction.title.$anyError : null
    },
    invalidTitleFeed () {
      if (this.$v.transaction.title.$anyError && !this.$v.transaction.title.required) {
        return this.$t(this.translateKey + 'errors.title_required')
      } else if (this.$v.transaction.title.$anyError && !this.$v.transaction.title.minLength) {
        return this.$t(this.translateKey + 'errors.title_minlength')
      } else {
        return ''
      }
    },
    descriptionState () {
      return this.$v.transaction.title.$dirty ? true : null
    },
    amountState () {
      return this.$v.transaction.amount.$dirty ? !this.$v.transaction.amount.$anyError : null
    },
    invalidAmountFeed () {
      if (this.$v.transaction.amount.$anyError && !this.$v.transaction.amount.required) {
        return this.$t(this.translateKey + 'errors.amount_required')
      } else if (this.$v.transaction.amount.$anyError && !this.$v.transaction.amount.DecimalNumberCheck) {
        return this.$t(this.translateKey + 'errors.amount_invalid')
      } else {
        return ''
      }
    },
    typeState () {
      return this.$v.transaction.type.$dirty ? !this.$v.transaction.type.$anyError : null
    },
    invalidTypeFeed () {
      if (this.$v.transaction.type.$anyError) {
        return this.$t(this.translateKey + 'errors.type_required')
      } else {
        return ''
      }
    },
    accountGroupState () {
      return this.$v.transaction.accounts.$dirty ? !this.$v.transaction.accounts.$anyError : null
    },
    accountGroupFeedback () {
      if (this.$v.transaction.accounts.$dirty && !this.$v.transaction.accounts.requiredValue) {
        return 'Please select an account'
      }
      return ''
    },
    percentageState () {
      return this.$v.transaction.percentage.$dirty ? !this.$v.transaction.percentage.$anyError : null
    }
  },
  methods: {
    loadAccountsApiMethod () {
      // this.loadingAccounts = true
      let AccountType = null
      let cachedProp = null
      switch (this.transaction.type) {
        case 'income':
          AccountType = 'income'
          cachedProp = 'income'
          break
        case 'expense':
          AccountType = 'expense'
          cachedProp = 'expense'
          break
        case 'refund':
          AccountType = 'expense'
          cachedProp = 'expense'
          break
        default:
          AccountType = 'asset,liability'
          cachedProp = 'transfer'
      }
      if (this.accounts[cachedProp]) {
        return Promise.resolve(this.accounts[cachedProp])
      }
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=InformationTab`)
        .then(response => response.data.data)
        .then(responseData => {
          const optionArray = []
          const accountGroups = responseData
          Object.keys(accountGroups).forEach(function (key) {
            if (AccountType === 'asset,liability') {
              optionArray.push({ title: (this.$te('common.' + accountGroups[key].type.toLowerCase()) ? this.$t('common.' + accountGroups[key].type.toLowerCase()) : accountGroups[key].type) + ' / ' + accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            } else {
              optionArray.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            }
          }.bind(this))
          return AccountType === 'asset,liability' ? optionArray.sort(function (a, b) {
            const nameA = a.type
            const nameB = b.type
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }) : optionArray
        })
        .catch(error => {
          console.error(error)
          return []
        })
    },
    syncPercentage (selectedOpt) {
      this.transaction.percentage = selectedOpt.default_active_percentage
    },
    onSubmit (e) {
      e.preventDefault()
      this.$v.$touch()
      console.log('transaction', this.transaction)
      if (!this.$v.$invalid) {
        const date = this.$moment(this.transaction.date).format('YYYY-MM-DD')
        const postObject = Object.assign(this.transaction, { date, suggestion: false })
        console.log('transaction', postObject)
        this.$emit('success-submit', postObject)
      }
    },
    dateFormatter (date) {
      const local = this.currentCOA.locale
      return this.$moment(date).locale(local).format('YYYY-MM-DD')
    },
    defaultDescription () {
      let str = ''
      if (this.parserMeta) {
        for (const key in this.parserMeta) {
          str += this.$t(key) + ': ' + this.parserMeta[key] + '\n'
        }
      }
      return str.trim()
    }
  },
  watch: {
    loadingAccounts: function (newVal) {
      if (newVal === true) {
        this.loadAccountsApiMethod()
          .then(accounts => {
            this.accountsGroupOption = accounts
            this.loadingAccounts = false
          })
      }
    },
    'transaction.type': {
      handler: function (newVal, oldVal) {
        if (newVal && this.accountOptions.enable === true) {
          this.loadingAccounts = true
        }
      },
      immediate: true,
      deep: true
    },
    'transaction.date': {
      handler: function (newVal) {
        if (newVal) {
          this.transaction.date = this.dateFormatter(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    transactionData: {
      handler: function (value) {
        if (value && Object.keys(value).length > 0) {
          this.transaction = value
        }
      },
      immediate: true,
      deep: true
    },
    parserMeta: {
      deep: true,
      handler () {
        // this.transaction.description = this.defaultDescription()
      }
    }
  },
  created () {
    // this.transaction.description = this.defaultDescription()
  },
  filters: {
    formatDate
  }
}
</script>
<style lang="scss">
.custom-range {
    margin-top: 12px;
}
.informationTab {
  #transactiondescription {
    height: auto !important;
  }
  .ember-input {
    padding: 2px 5px !important;
    .placeholder {
      padding: 10px !important;
    }
    .inputText {
      padding: 10px !important;
    }
  }
  .paddingLeftHide {
    @media screen and (min-width: 768px) {
      padding-left: 0;
    }
  }
  .form-control {
    max-width: 100% !important;
    font-size: 14px !important;
    height: 46px !important;
    color: #232B36 !important;
  }
  .mobilePaddingLR-0 {
    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  label {
    align-self: center;
    &.customPadding {
      padding-top: 12px;
      align-self: start;
    }
  }
  legend {
    font-weight: 300;
    align-self: center;
  }
}
.is-invalid {
    .vdp-datepicker{
        input {
            border-color: #EF5350;
        }
    }
}
</style>
