<template>
    <b-tab :title="$t(translationPath + 'title')" class="px-3 pt-3 pb-2">
      <b-col xs="12" class="px-0">
        <h4>{{ $t(translationPath + 'title')}}</h4>
        <p class="col-lg-8 pl-0 ml-0">
          {{ $t(translationPath + 'description')}}
        </p>
      </b-col>
      <b-col xs="12" class="px-0">
        <div class="alert alert-light">
          <template v-if="parserMeta">
            <ul>
              <li v-for="(metaVal, metaKey) in parserMeta" :key="metaKey">
                <b>{{ $t(metaKey) }}</b>: {{ metaVal }}
              </li>
            </ul>
          </template>
          <template v-else>
            {{ $t(translationPath + 'no_information') }}
          </template>
        </div>
      </b-col>
    </b-tab>
</template>

<script>
export default {
  name: 'AdditionalTab',
  props: {
    parserMeta: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_wizard_v2.tab.additional.'
    }
  }
}
</script>
