<template>
  <b-container class="import-wizard-step-container import-wizard-step05">
    <b-row no-gutters>
      <b-col cols="12" class="form-body-container accounts_wrapper mb-3 mb-md-0 pt-0 pb-3 pr-0 py-lg-5">
        <b-row no-gutters class="detail-text">
          <b-col>
            <h2 class="text-dark">{{ $t(translationPath + 'title') }} </h2>
            <p>
              {{ $t(translationPath + 'intro_p1') }}
            </p>
            <b-form-group
              :state="importTitleState"
              :invalid-feedback="invalidImportTitleFeedback"
              class="mb-4"
              label-for="import_title"
              label-class=""
              label-align="left"
            >
              <template slot="label">{{ $t(translationPath + 'import_title_label') }}</template>
              <template slot="description">{{ $t(translationPath + 'import_title_help_text') }}</template>

              <b-form-input type="text"
                            v-model="import_title"
                            @input="$v.import_title.$touch()"
                            :state="importTitleState"
                            :placeholder="$t(translationPath + 'import_title_placeholder')"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <b-table hover responsive striped
              :items="Transactions"
              :fields="fieldValues"
              :per-page="TransactionTotalRows"
              @filtered="onFilteredTransactionAccounts"
              :filter="transaction_filter"
              ref="transactionTableView"
              stacked="md"
              class="spirecta-table-step4" show-empty>
                <template slot="top-row">
                  <td colspan="6">
                    <div class="d-flex align-items-center">
                      <i class="fa fa-search"></i>
                      <b-form-input v-model="transaction_filter" size="sm" :placeholder="$t(translationPath + 'table.filter_text')" />
                    </div>
                  </td>
                </template>
                <template v-slot:cell(type)="row">
                  {{ $t('common.' + row.item.type) }}
                </template>
                <template v-slot:cell(account)="row">
                  {{ row.item.accounts ? row.item.accounts.title : null }}
                </template>
                <template v-slot:cell(percentage)="row">
                  {{ row.item.percentage ? row.item.percentage : (row.item.accounts && row.item.accounts.default_active_percentage ? row.item.accounts.default_active_percentage: null ) | formatPercentage}}
                </template>
                <template v-slot:cell(amount)="row">
                  <span :class="'amount-' + (row.item.type !== 'transfer' && row.item.type !== 'transfer_from' && row.item.type !== 'transfer_to' ?  row.item.type : 'transfer') + (row.item.type === 'transfer' || row.item.type === 'transfer_from' || row.item.type === 'transfer_to' ? (row.item.type === 'transfer_from' ? ' transferSelf' : ' transferOther') : '')">{{row.item.amount | formatAmount(currentCOA.locale, currentCOA.currency, true)}}</span>
                </template>
                <template slot="empty">
                  <h4 class="font-weight-light mb-0">{{$t(translationPath + 'table.no_data')}}</h4>
                </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-toast id="saving-toast" variant="primary" solid :no-auto-hide="true" :no-close-button="true">
      <template v-slot:toast-title>
        {{ $t(translationPath + 'toast.title') }}
      </template>
      Saving ...
    </b-toast>
  </b-container>
</template>
<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import formatAmount from '@/assets/filters/formatAmount'
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'ImportAccountStatementWizardStep04',
  props: ['final-model'],
  data () {
    return {
      translationPath: 'transactions.import.import_wizard_v2.step4.',
      Transactions: [],
      TransactionPerPage: this.$store.getters.getImportPaginationRowsAction ? parseInt(this.$store.getters.getImportPaginationRowsAction) : 25,
      transaction_filter: null,
      TransactionTotalRows: 0,
      TransactionFinalData: [],
      import_title: null
    }
  },
  validations: {
    import_title: { required },
    form: ['import_title']
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return [
        { key: 'date', label: this.$t(this.translationPath + 'table.head.date'), sortable: true },
        { key: 'title', label: this.$t(this.translationPath + 'table.head.title') },
        { key: 'type', label: this.$t(this.translationPath + 'table.head.type') },
        { key: 'account', label: this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'table.head.account') : this.$t(this.translationPath + 'table.head.category') },
        { key: 'amount', label: this.$t(this.translationPath + 'table.head.amount'), class: 'text-left text-md-right' },
        { key: 'percentage', label: this.$t(this.translationPath + 'table.head.percentage'), class: 'text-left text-md-right' }
      ]
    },
    importTitleState () {
      return this.$v.import_title.$anyError ? !this.$v.import_title.$error : null
    },
    invalidImportTitleFeedback () {
      if (this.$v.$anyError && this.$v.import_title.$error) {
        return this.$i18n.t(this.translationPath + 'import_title_required')
      } else {
        return ''
      }
    }
  },
  methods: {
    async validate () {
      this.$v.import_title.$touch()
      const isValid = !this.$v.form.$invalid
      if (isValid && this.Transactions.length > 0) {
        const NoAccounts = this.Transactions.filter(item => !item.accounts)
        if (NoAccounts.length === 0) {
          this.$bvToast.show('saving-toast')
          this.$emit('disable-save', true)
          const importId = Object.prototype.hasOwnProperty.call(this.finalModel, 'import_id') && this.finalModel.import_id ? this.finalModel.import_id : null
          const offsetAccount = Object.prototype.hasOwnProperty.call(this.finalModel, 'offset_account') && this.finalModel.offset_account ? this.finalModel.offset_account : {}
          const offsetAccountId = Object.prototype.hasOwnProperty.call(offsetAccount, 'id') && offsetAccount.id ? offsetAccount.id : null
          const parseResult = Object.prototype.hasOwnProperty.call(this.finalModel, 'parse_result') && this.finalModel.parse_result ? this.finalModel.parse_result : {}
          // raw data statement
          const rawDataStatement = Object.prototype.hasOwnProperty.call(parseResult, 'raw_data_string') && parseResult.raw_data_string ? parseResult.raw_data_string : ''
          // parser result
          const parserResult = Object.prototype.hasOwnProperty.call(this.finalModel, 'parse_result') && this.finalModel.parse_result ? this.finalModel.parse_result : {}
          // used parser class
          const usedParser = Object.prototype.hasOwnProperty.call(parserResult, 'parser_class_name') && parserResult.parser_class_name ? parserResult.parser_class_name : null
          // success rate
          const successRate = Object.prototype.hasOwnProperty.call(parserResult, 'success_rate') && parserResult.success_rate ? parserResult.success_rate : 0
          // final post object
          const postObjectImport = {
            account_id: offsetAccountId,
            title: this.import_title,
            raw_account_statement: rawDataStatement,
            used_parser: usedParser,
            success_rate: successRate,
            is_completed: 1
          }
          try {
            const statementRequest = await axios.put(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/${importId}/update`, postObjectImport)
            const importResponse = statementRequest.status === 200 ? statementRequest.data.data : {}
            if (!Object.prototype.hasOwnProperty.call(importResponse, 'id')) {
              return false
            }
            const postData = {
              // check_potential_duplicates: 1,
              transactions: this.TransactionFinalData
            }
            await axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, postData)
            this.$bvToast.hide('saving-toast')
            return true
          } catch (e) {
            console.warn(e)
            this.$emit('disable-save', false)
            this.$bvToast.hide('saving-toast')
            return false
          }
        } else {
          this.$emit('validate-success', { postObject: [] })
          return false
        }
      }
      return false
    },
    onFilteredTransactionAccounts (filteredItems) {
      this.TransactionTotalRows = filteredItems.length
    }
  },
  watch: {
    finalModel: {
      handler: function (newVal) {
        // set title
        const parserResult = newVal && Object.prototype.hasOwnProperty.call(newVal, 'parse_result') && newVal.parse_result ? newVal.parse_result : {}
        const offsetAccountTitle = newVal && Object.prototype.hasOwnProperty.call(newVal, 'offset_account') && newVal.offset_account ? newVal.offset_account.title : null
        const firstTransactionDate = Object.prototype.hasOwnProperty.call(parserResult, 'first_transaction_date') && parserResult.first_transaction_date ? parserResult.first_transaction_date : null
        const lastTransactionDate = Object.prototype.hasOwnProperty.call(parserResult, 'last_transaction_date') && parserResult.last_transaction_date ? parserResult.last_transaction_date : null
        moment.locale(this.currentCOA.locale)
        this.import_title = firstTransactionDate && lastTransactionDate ? this.$t(this.translationPath + 'import_title_default_suggestion', {
          account_title: offsetAccountTitle,
          first_date: firstTransactionDate,
          last_date: lastTransactionDate
        }) : this.$t(this.translationPath + 'import_title_default_suggestion_error', {
          account_title: offsetAccountTitle,
          date: moment(new Date()).format('L')
        })
        // set properties
        const BankAccountId = newVal && Object.prototype.hasOwnProperty.call(newVal, 'offset_account') && newVal.offset_account && Object.prototype.hasOwnProperty.call(newVal.offset_account, 'id') && newVal.offset_account.id ? newVal.offset_account.id : null
        const ParsedImportId = newVal && Object.prototype.hasOwnProperty.call(newVal, 'import_id') && newVal.import_id ? newVal.import_id : null
        // const ParsedImportTitle = newVal && Object.prototype.hasOwnProperty.call(newVal, 'title') && newVal.title ? newVal.title : null
        const ParseTransaction = newVal && Object.prototype.hasOwnProperty.call(newVal, 'transactions') && newVal.transactions ? newVal.transactions : []
        const AddedTrasactions = newVal && newVal.user_transactions ? newVal.user_transactions : []
        const MergeTransactions = [...ParseTransaction, ...AddedTrasactions]
        let AccountTransaction = {}
        let BankTransaction = {}
        const FinalTransactions = MergeTransactions.map(item => {
          const tinkTransactionId = Object.prototype.hasOwnProperty.call(item, 'tink_transaction_id') ? item.tink_transaction_id : null
          let transactionType = null
          switch (item.type) {
            case 'income':
              transactionType = 'deposit'
              break
            case 'expense':
              transactionType = 'withdrawal'
              break
            default:
              transactionType = 'transfer'
          }
          if (transactionType === 'transfer') {
            if (item.type === 'transfer_from' || item.type === 'refund') {
              AccountTransaction = { account_id: item.accounts ? item.accounts.id : null, active_percentage: item.percentage ? item.percentage : null, dctype: 'cr', amount: item.amount, description: item.description || null }
              BankTransaction = { account_id: BankAccountId, active_percentage: item.percentage ? item.percentage : null, dctype: 'dr', amount: item.amount, description: item.description || null }
            } else {
              AccountTransaction = { account_id: item.accounts ? item.accounts.id : null, active_percentage: item.percentage ? item.percentage : null, dctype: 'dr', amount: item.amount, description: item.description || null }
              BankTransaction = { account_id: BankAccountId, active_percentage: item.percentage ? item.percentage : null, dctype: 'cr', amount: item.amount, description: item.description || null }
            }
          } else {
            AccountTransaction = { account_id: item.accounts ? item.accounts.id : null, active_percentage: item.percentage ? item.percentage : null, dctype: item.type === 'expense' ? 'dr' : 'cr', amount: item.amount, description: item.description || null }
            BankTransaction = { account_id: BankAccountId, active_percentage: item.percentage ? item.percentage : null, dctype: item.type === 'expense' ? 'cr' : 'dr', amount: item.amount, description: item.description || null }
          }
          const entries = [AccountTransaction, BankTransaction]
          return Object.assign({}, { title: item.title, date: item.date, type: transactionType, description: BankTransaction.description ? BankTransaction.description : this.import_title, import_id: ParsedImportId, tink_transaction_id: tinkTransactionId, entries: entries })
        })
        this.TransactionFinalData = FinalTransactions

        MergeTransactions.sort(function (a, b) {
          const item1Date = new Date(a.date)
          const item2Date = new Date(b.date)
          if (item1Date.getTime() < item2Date.getTime()) {
            return 1
          }
          if (item1Date.getTime() > item2Date.getTime()) {
            return -1
          }
          return 0
        })
        this.Transactions = MergeTransactions
        this.TransactionTotalRows = MergeTransactions.length
      },
      immediate: true
    }
  },
  filters: {
    formatAmount,
    formatPercentage: function (value) {
      return value ? value + '%' : 0 + '%'
    },
    formatType (value) {
      return value ? _.upperFirst(value) : null
    }
  }
}
</script>
<style lang="scss">
  .spirecta-table-step4 {
    caption {
      background: #fff;
      padding: 10px;
      font-size: 1.4em;
      font-weight: 200;
    }
    thead {
      background:#fff;
      th {
        vertical-align: middle;
        outline: none;
        border-bottom: 1px dashed #CACACA;
      }
    }
    tbody
    {
      tr {
        &:nth-of-type(odd){
          background: transparent;
        }
        &:nth-child(even) {
          background: #f1f2f8;
        }
        td {
          border-top: 1px dashed #CACACA;

          span {
            &.amount-expense {
              color:#da5b5b;
              &::before{
                content: "-"
              }
            }
            &.amount-income {
              color: #36b373;
            }
            &.amount-refund {
              color: #36b373;
            }
            &.amount-transfer {
              &.transferOther {
                color: #da5b5b;
                &::before{
                  content: "-"
                }
              }
              &.transferSelf {
                color: #36b373;
              }
            }
          }
        }
        &.b-table-top-row {
          td {
            padding:4px 4px 4px 15px;
            box-shadow:inset 0px 7px 16px -5px rgba(3, 3, 3, 0.23);
            .form-control {
              background:transparent;
              border: none;
            }
          }
        }
        &:hover {
          background:#fff;
        }
      }
    }
  }
</style>
