<template>
  <b-container class="import-wizard-step01">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath+'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <b-table ref="importTable" class="spirecta-simple-table accounts-table spirecta-table-where-row-is-selected" show-empty hover responsive striped
                 :items="accounts"
                 :fields="fields"
                 :busy="busyState"
                 selectable
                 select-mode="single"
                 :filter="filter"
                 :filter-included-fields="['title','title_with_code']"
                 @row-selected="rowSelected"
                 stacked="md"
                 :emptyText="$t(translationPath + 'no_data')"
          >
          <!--loader-->
            <template v-slot:table-busy>
              <loader/>
            </template>
          <!--loader ends here-->

          <!-- FILTER -->
          <template slot="top-row">
            <td colspan="2">
              <div class="d-flex align-items-center">
                <i class="fa fa-search text-secondary"></i>
                <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
              </div>
            </td>
          </template>

          <template v-slot:head(title)>
            {{ $t(translationPath + 'table_head') }}
            <br />
            <i18n :path="translationPath + 'table_heading_description_missing_account'" tag="small" class="text-gray">
              <b-link slot="importable_accounts_read_more" href="https://forum.spirecta.se/t/lagg-till-eller-andra-konton-att-importera-fran/190" target="_blank" v-b-toggle.account_missing>{{$t('common.read_in_manual')}}</b-link>
            </i18n>

          </template>

          <template v-slot:cell(title)="data">
            {{ currentCOA.prefix_account_title_with_code ? data.item.title_with_code : data.item.title }}
          </template>
          <template v-slot:cell(importable_type)="data">
            <b-link class="text-gray"> {{data.value}} </b-link>
          </template>
          <template v-slot:cell(importable_default_parser)="data">
            <b-link class="text-gray"> {{data.value ? $t('common.' + findParserName(data.value)) : $t(translationPath + 'unknown')}} </b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'

export default {
  name: 'ImportAccountStatementWizardStep01',
  components: { Loader },
  props: {
    parsers: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      accounts: [],
      translationPath: 'transactions.import.import_wizard_v2.step1.',
      busyState: false,
      selected: [],
      filter: ''
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fields () {
      return [
        { label: this.$t('common.source'), key: 'title', sortable: false },
        /* { label: this.$t('common.type'), key: 'importable_type', sortable: false, formatter: (value) => { return value !== null ? this.$t('common.' + value) : '' }, class: 'text-left text-md-left' }, */
        { label: this.$t(this.translationPath + 'default_parser'), key: 'importable_default_parser', sortable: false }
      ]
    }
  },
  async created () {
    this.busyState = true
    this.loadAccounts()
  },
  methods: {
    async loadAccounts (selectedAccount = null) {
      const sSortTitle = this.currentCOA.prefix_account_title_with_code === 1 ? 'code' : 'title'
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=asset,liability&only_importable=1&hide_inactive=1&sort[0][key]=${sSortTitle}&sort[0][direction]=asc`)
        .then(response => {
          this.accounts = response.data.data
          this.busyState = false
          if (selectedAccount) {
            const findSelectedIndex = this.accounts.findIndex((el) => el.id === selectedAccount)
            if (findSelectedIndex > -1) {
              this.$nextTick(() => {
                this.$refs.importTable.selectRow(findSelectedIndex)
              })
              this.selected = [this.accounts[findSelectedIndex]]
              this.$emit('validate-success', { offset_account: this.selected[0] })
            }
          }
        })
        .catch(error => {
          console.error(error)
          this.busyState = false
        })
    },
    rowSelected (items) {
      this.selected = items
      this.emitEventRow()
    },
    emitEventRow () {
      this.$emit('import-selected', this.selected.length === 0)
    },
    async validate () {
      if (this.selected.length === 0) {
        return false
      }
      this.$emit('validate-success', { offset_account: this.selected[0] })
      return true
    },
    reset () {
      this.$refs.importTable.clearSelected()
    },
    findParserName (value) {
      return Object.prototype.hasOwnProperty.call(this.parsers, value) ? this.parsers[value].parser_nice_name : ''
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
  .import-wizard-step01 {

    .accounts_wrapper {
      border-left: 1px solid #D7D7D7;
      @media screen and (max-width:991.98px) {
        border-left: none;
      }
    }
  }
</style>
