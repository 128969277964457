<template>
  <b-modal
    ref="categorizeLaterTransactionModal"
    size="lg"
    centered
    hide-footer
    no-close-on-backdrop
    modal-class="spirecta-modal transactionModel"
  >
    <div slot="modal-header" class="w-100 mx-auto text-center">
      <button
        type="button"
        class="close"
        aria-label="Close"
        @click="closeModal"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="mt-3 mt-lg-4 border-bottom pb-2 pb-lg-3 mb-2 mb-lg-4">
        <h1 class="modal-header-primary">{{ $t(translationPath + 'title') }}</h1>
      </div>
      <div class="mt-3 mt-lg-4 pb-2" v-html="this.$t(translationPath + 'description')"></div>
    </div>
    <div slot="default">
      <b-row>
        <b-col lg="8" offset-lg="2">
          <b-alert variant="danger" :show="hasError">{{ $t(translationPath + 'select_any')}}</b-alert>
          <h4>{{$t(translationPath + 'labels.choose_categories')}}</h4>
          <b-form ref="categorizeLaterForm" @submit="onSubmit" :validated="validated">
            <b-form-group
              label-cols-md="4"
              :label="$t(translationPath + 'labels.income')"
              label-align-md="right"
              label-align-sm="left"
              label-for="incomeCategory"
            >
              <custom-select
                v-model="income_category"
                :options="accountOptions.income"
                class="marginLeft-2"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="
                  currentUser.strict_accounting_mode
                    ? $t('common.select_account')
                    : $t('common.select_category')
                "
                :noResultText="
                  currentUser.strict_accounting_mode
                    ? $t('common.no_account_found')
                    : $t('common.no_category_found')
                "
              />
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              :label="$t(translationPath + 'labels.expense')"
              label-align-md="right"
              label-align-sm="left"
              label-for="expenseCategory"
            >
              <custom-select
                v-model="expense_category"
                :options="accountOptions.expense"
                class="marginLeft-2"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="
                  currentUser.strict_accounting_mode
                    ? $t('common.select_account')
                    : $t('common.select_category')
                "
                :noResultText="
                  currentUser.strict_accounting_mode
                    ? $t('common.no_account_found')
                    : $t('common.no_category_found')
                "
              />
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              :label="$t(translationPath + 'labels.refund')"
              label-align-md="right"
              label-align-sm="left"
              label-for="refundCategory"
            >
              <custom-select
                v-model="refund_category"
                :options="accountOptions.expense"
                class="marginLeft-2"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="
                  currentUser.strict_accounting_mode
                    ? $t('common.select_account')
                    : $t('common.select_category')
                "
                :noResultText="
                  currentUser.strict_accounting_mode
                    ? $t('common.no_account_found')
                    : $t('common.no_category_found')
                "
              />
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              :label="$t(translationPath + 'labels.transfer_from')"
              label-align-md="right"
              label-align-sm="left"
              label-for="transferfromCategory"
            >
              <custom-select
                v-model="transfer_from_category"
                :options="accountOptions.asset_liability"
                class="marginLeft-2"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="
                  currentUser.strict_accounting_mode
                    ? $t('common.select_account')
                    : $t('common.select_category')
                "
                :noResultText="
                  currentUser.strict_accounting_mode
                    ? $t('common.no_account_found')
                    : $t('common.no_category_found')
                "
              />
            </b-form-group>
            <b-form-group
              label-cols-md="4"
              :label="$t(translationPath + 'labels.transfer_to')"
              label-align-md="right"
              label-align-sm="left"
              label-for="incomeCategory"
            >
              <custom-select
                v-model="transfer_to_category"
                :options="accountOptions.asset_liability"
                class="marginLeft-2"
                groupingValues="accounts"
                groupingLabel="title"
                label="title"
                :loading="loadingAccounts"
                :search-placeholder="$t('common.type_to_filter')"
                :placeholder="
                  currentUser.strict_accounting_mode
                    ? $t('common.select_account')
                    : $t('common.select_category')
                "
                :noResultText="
                  currentUser.strict_accounting_mode
                    ? $t('common.no_account_found')
                    : $t('common.no_category_found')
                "
              />
            </b-form-group>
            <div class="modal-form-button-group text-center mt-4 mb-3">
              <b-button class="mx-1" pill variant="" @click="closeModal">{{$t('common.cancel')}}</b-button>
              <b-button class="mx-1" pill type="submit" variant="primary">{{$t(translationPath + 'categorize')}}</b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import CustomSelect from '@/components/common/CustomSelect'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'categorizeLaterTransactionModal',
  components: {
    CustomSelect
  },
  data () {
    return {
      translationPath: 'transactions.re-categorize.modal.',
      loadingAccounts: false,
      validated: false,
      income_category: null,
      expense_category: null,
      refund_category: null,
      transfer_from_category: null,
      transfer_to_category: null,
      hasError: false,
      accountOptions: {
        income: [],
        expense: [],
        asset_liability: []
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser'])
  },
  mounted () {
    this.loadAccountOptions()
  },
  methods: {
    onSubmit (e) {
      this.hasError = false
      e.preventDefault()
      if (!this.income_category &&
      !this.expense_category &&
      !this.refund_category &&
      !this.transfer_from_category &&
      !this.transfer_to_category) {
        this.hasError = true
        return
      }
      this.$emit('success-submit', {
        income_category: this.income_category,
        expense_category: this.expense_category,
        refund_category: this.refund_category,
        transfer_to_category: this.transfer_to_category,
        transfer_from_category: this.transfer_from_category
      })
    },
    openModal () {
      this.$refs.categorizeLaterTransactionModal.show()
    },
    closeModal () {
      this.income_category = null
      this.expense_category = null
      this.refund_category = null
      this.transfer_from_category = null
      this.transfer_to_category = null
      this.hasError = false
      this.$refs.categorizeLaterTransactionModal.hide()
    },
    incomeExpenseSort (a, b) {
      const nameA = a.type
      const nameB = b.type
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    },
    loadAccountOptions () {
      const _self = this
      _self.loadingAccounts = true
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=income,expense,asset,liability&log=categorizeLaterTransactionModal`)
        .then(response => response.data.data)
        .then(responseData => {
          const incomeOption = []
          const expenseOption = []
          const assetLiabilityOption = []
          const accountGroups = responseData
          Object.keys(accountGroups).forEach(function (key) {
            if (accountGroups[key].type === 'asset' || accountGroups[key].type === 'liability') {
              assetLiabilityOption.push({ title: (_self.$te('common.' + accountGroups[key].type.toLowerCase()) ? _self.$t('common.' + accountGroups[key].type.toLowerCase()) : accountGroups[key].type) + ' / ' + accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            } else if (accountGroups[key].type === 'income') {
              incomeOption.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            } else {
              expenseOption.push({ title: accountGroups[key].title, type: accountGroups[key].type, accounts: accountGroups[key].accounts })
            }
          })
          _self.accountOptions = Object.assign({}, _self.accountOptions, {
            income: incomeOption.sort(_self.incomeExpenseSort),
            expense: expenseOption.sort(_self.incomeExpenseSort),
            asset_liability: assetLiabilityOption
          })
          // ('_self.accountOptions :', _self.accountOptions)
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          _self.loadingAccounts = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/modal.scss";
.custom-range {
  margin-top: 12px;
}
.transactionModel {
  .paddingLeftHide {
    @media screen and (min-width: 768px) {
      padding-left: 0;
    }
  }
  .mobilePaddingLR-0 {
    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  label {
    align-self: center;
    &.customPadding {
      padding-top: 12px;
      align-self: start;
    }
  }
  legend {
    font-weight: 300;
    align-self: center;
  }
}
.is-invalid {
  .vdp-datepicker {
    input {
      border-color: #ef5350;
    }
  }
}
</style>
