<template>
    <b-modal id="deleteModelImport" centered ref="deleteModelImport" modal-class="spirecta-modal" @hide="hideHandler">
      <template slot="modal-title">
        {{ $t(translationPath + (multiple ? 'alert.delete_multiple_transactions' : 'alert.delete_single_transaction')) }}
      </template>
      <template slot="modal-footer">
        <b-button variant="outline" class="float-left" @click="hide">{{$t('common.cancel')}}</b-button>
        <b-button variant="danger" class="float-left" @click="emitEventClick">{{$t('common.delete')}}</b-button>
      </template>
      <slot name="message"></slot>
    </b-modal>
</template>

<script>
/*
  Components: DeleteModal
  Methods: show, hide and emitEventClick
  show (args: mulitple = true/false)
*/
export default {
  name: 'DeleteModal',
  props: {
    translationPath: {
      type: String,
      default: 'transactions.import.import_wizard_v2.step3.'
    }
  },
  data () {
    return {
      multiple: false,
      isSingle: true,
      isHidden: true
    }
  },
  methods: {
    show (multipleOptions) {
      this.multiple = multipleOptions || false
      if (this.multiple === true) {
        this.isSingle = false
      } else {
        this.isSingle = true
      }
      this.isHidden = false
      this.$refs.deleteModelImport.show()
      if (this.multiple === true) {
        this.$emit('show-multiple')
      } else {
        this.$emit('show-single')
      }
    },
    hide () {
      this.$refs.deleteModelImport.hide()
    },
    hideHandler () {
      if (this.multiple === true) {
        this.$emit('hide-multiple')
      } else {
        this.$emit('hide-single')
      }
      this.multiple = false
      this.isHidden = true
    },
    emitEventClick () {
      if (this.multiple === true) {
        this.$emit('delete-multiple')
      } else {
        this.$emit('delete-single')
      }
    }
  }
}
// deleteCurrentTransaction
</script>
