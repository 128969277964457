<template>
  <b-container class="import-wizard-step-container import-wizard-step02">
    <b-row no-gutters>

      <!-- START SIDEBAR -->
      <b-col cols="12" lg="4" class="sidebar-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5" >
        <h2 class="text-dark">{{ $t(translationPath + 'title') }} </h2>

        <div v-html="$t(translationPath+'sidebar_description')"></div>
      </b-col>
      <!-- END SIDEBAR -->

      <b-col cols="12" lg="8" class="form-body-container accounts_wrapper py-3 pl-2 pr-0 py-lg-5 pl-lg-5">

        <!-- START - Parse failed alert -->
        <b-alert variant="warning" :show="showAlertDanger" id="alert-danger">
          <h3 class="font-weight-bold">{{ $t(translationPath + 'alert.no_identified_transactions')}}</h3>
          <div v-html="$t(translationPath + 'alert.no_identified_transactions_description')"></div>
        </b-alert>
        <!-- END - Parse failed alert -->

        <!-- START select parser -->
        <b-form-group
          :label="$t(translationPath + 'select_parser_label')"
          label-class="font-weight-bold"
          :invalid-feedback="invalidParserFeedback"
          :state="$v.parser.$dirty ? !$v.parser.$error : null"
        >
          <custom-select
            class="parserSelect"
            v-model="$v.parser.$model"
            :options="parserOptions"
            label="text"
            valueKey="value"
            :placeholder="''"
            v-bind:class="{'is-valid': $v.parser.$dirty ? !$v.parser.$error : false, 'is-invalid': $v.parser.$dirty ? $v.parser.$error : false}"
            :search-placeholder="$t('common.type_to_filter')"
          />
          <template slot="description" >
            {{ $t(translationPath + 'select_parser_help_text') }}
          </template>
          <template slot="invalid-feedback">
            {{ invalidParserFeedback }}
            <b-link v-if="showAlertDanger" :to="'#'" @click="scrollToError">{{ $t(translationPath + 'scroll_to_error') }}</b-link>
          </template>
        </b-form-group>
        <template v-if="defaultParser === null && parser !== null">
          <b-button variant="outline-primary" class="setDefaultButton mb-3 px-4" @click="setAsDefaultParser">{{ $t(translationPath + 'set_default_parser')}}</b-button>
        </template>

        <!-- START parser info box -->
        <template v-if="parser !== null">
          <b-alert variant="light" :show="parserHasInfo">
            <i18n :path="translationPath + 'info_about_parser'" tag="b">
              <span slot="parser">"{{parser.text}}"</span>
            </i18n><br/>
            <span v-if="parserHasTranslatedDescription">{{ parser.value && typeof parsers[parser.value] !== 'undefined' ? $t('common.' + parsers[parser.value].description) : '' }}</span>
            <ul v-if="currentUser.default_lang !== null" class="mb-0 mt-2">
              <li v-if="parserHasManualUrl">

                <template v-if="isParserAuto === false">
                  <b-link :href="parsers[parser.value].manual_urls[parserLangKey]" target="_blank">{{ $t(translationPath + 'more_info_in_the_manual' ,{parser_name: this.parser.text}) }} →</b-link>
                </template>
                <template v-else>
                  <b-link href="https://forum.spirecta.se/t/var-automagiska-importmall/161" target="_blank">{{ $t(translationPath + 'more_info_in_the_manual_auto_parser') }} →</b-link>
                </template>

              </li>
              <li v-if="parserHasLoginUrl">
                <b-link :href="parser.value && typeof parsers[parser.value] !== 'undefined' ? parsers[parser.value].login_urls[parserLangKey]: '#'" target="_blank">{{ $t(translationPath + 'login_to_get_account_statement', {parser: parser.text}) }} →</b-link>
              </li>
            </ul>
          </b-alert>
        </template>
        <!-- END parser info box -->

        <div class="tabs white-tabs ">
          <b-tabs content-class="mt-0" v-model="tabIndex">
            <b-tab title="Klistra in kontoutdrag" active>
              <div class="px-3 mt-0 py-3 white-tab-content">
                <!-- START Textarea -->
                <b-form-group id="accountStatementFormGroup"
                              label-for="account_statement"
                              label-class="font-weight-bolder"
                              :invalid-feedback="invalidAccountStatementFeedback"
                              :state="$v.account_statement.$dirty ? !$v.account_statement.$error : null">
                  <template slot="label">{{ $t(translationPath + 'textarea_label', { account_name: offsetAccountName}) }}
                    (<a href="#csv-upload-wrapper" @click="tabIndex++">{{ $t(translationPath + 'btn_import_file') }}</a>)
                  </template>
                  <template slot="description">{{ $t(translationPath + 'textarea_help_text') }}</template>
                  <b-form-textarea
                    class=""
                    id="account_statement"
                    :placeholder="$t(translationPath + 'textarea_placeholder')"
                    size="lg"
                    required
                    rows="30"
                    max-rows="30"
                    :state="$v.account_statement.$dirty ? !$v.account_statement.$error : null"
                    v-model="$v.account_statement.$model"
                    @input="handleInputChange"
                    trim
                  >
                  </b-form-textarea>
                </b-form-group>
                <!-- END Textarea -->

                <!-- START - Too many rows alert -->
                <b-alert :show="limitToast"  :dismissible="true"  variant="warning" >
                  <h3>{{$t(translationPath+'warning_to_many_rows_title')}}</h3>
                  <p>{{$t(translationPath+'warning_to_many_rows_description')}}</p>
                </b-alert>
                <!-- END - Too many rows alert -->
              </div>
            </b-tab>
            <b-tab title="Läs in .csv-fil" >
              <div class="px-4 mt-0 py-3 white-tab-content" id="csv-upload-wrapper">
                <b-row>
                  <b-col cols="12">
                    <div v-html="$t(translationPath + 'upload_csv_modal_description')"></div>
                  </b-col>
                </b-row>
                <b-form-group class="pt-3 px-0 col-md-12">
                  <b-form-file
                    ref="doc"
                    id="file"
                    encoding="ISO-8859-1"
                    @change="handleInputFileChange"
                    :placeholder="$t(translationPath + 'select_drag_file')"
                    :browse-text="$t('common.browse')"
                  />
                </b-form-group>
                <b-row>
                  <b-col cols="12">
                    <p class="mb-3">{{ $t(translationPath + 'upload_csv_modal_description2') }}</p>
                  </b-col>
                </b-row>
                <b-button variant="primary" class="btn btn-sm" @click="readFile">{{ $t(translationPath + 'btn_read_data') }}</b-button>
              </div>

            </b-tab>
            <b-tab title="Senaste transaktionerna" v-if="offsetAccountName && latestTransaction">
              <template>
                <div class="px-3 mt-0 py-3 white-tab-content">
                  <i18n path="common.latest_transaction_saved" tag="p" class="mb-1">
                    <template slot="account_name">{{ offsetAccountName }}</template>
                  </i18n>
                  <p class="mb-0">{{ latestTransaction.date }} - {{ latestTransaction.title }} - {{ latestTransaction.amount | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso, true) }}</p>
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>

      </b-col>
    </b-row>
    <b-row v-if="debug">
      <b-col>
        <b-alert show variant="success">
          <h4> PARSER DEBUGGER: </h4>
          <pre> {{ form_account_data }} </pre>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CustomSelect from '@/components/common/CustomSelect'
import jschardet from 'jschardet'
import Papa from 'papaparse'
import { required, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'
import moment from 'moment'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import { SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, SET_IMPORT_WIZARDS_ROWS_PER_PAGE_VIEW, SET_IMPORT_WIZARDS_ROWS_PER_PAGE_ACTIONS } from '@/store/actions/settings'

const inValidParser = getter => function () {
  return !this.showAlertDanger
}
const requiredParser = (value) => value !== null

export default {
  name: 'ImportAccountStatementWizardStep02',
  components: { CustomSelect },
  filters: {
    formatAmount
  },
  props: {
    selectedAccountId: {
      type: Number,
      default: null
    },
    offsetAccountName: {
      type: String,
      default: ''
    },
    offsetAccountId: {
      type: Number,
      default: 0
    },
    defaultParser: {
      type: String,
      default: null
    },
    debug: {
      type: Boolean,
      default: false
    },
    parsers: {
      type: Object,
      default: function () {
        return {}
      }
    },
    parserOpt: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      account_statement: null,
      latestTransaction: null,
      form_account_data: {
        parse_result: {},
        account_statement: null,
        parsed_account_statement: null,
        addTransaction: [],
        user_transactions: [],
        transactions: [],
        transactions_error: []
      },
      translationPath: 'transactions.import.import_wizard_v2.step2.',
      parser: null,
      limitToast: false,
      fileError: false,
      tabIndex: 0
    }
  },
  validations: {
    account_statement: {
      required,
      minLength: minLength(10)
    },
    parser: {
      requiredParser,
      inValidParser: inValidParser()
    },
    form: ['account_statement', 'parser']
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    parserLangKey () {
      if (this.currentUser.default_lang === 'sv') {
        return 'se'
      }
      return this.currentUser.default_lang
    },
    parserHasInfo () {
      if (!this.parsers[this.parser.value]) {
        return false
      }
      if (this.parserHasTranslatedDescription || this.parserHasManualUrl || this.parserHasLoginUrl) {
        return true
      }
      return false
    },
    parserHasTranslatedDescription () {
      return Object.keys(this.parsers).length > 0 && this.parser.value && typeof this.parsers[this.parser.value] !== 'undefined' ? this.$te('common.' + this.parsers[this.parser.value].description) : 'n/a'
    },
    isParserAuto () {
      if (this.parser.value === 'auto') {
        return true
      }
      return false
    },
    parserHasManualUrl () {
      if (this.parser.value === 'auto') {
        return true
      }
      return this.parsers[this.parser.value].manual_urls && Object.prototype.hasOwnProperty.call(this.parsers[this.parser.value].manual_urls, this.parserLangKey)
    },
    parserHasLoginUrl () {
      return this.parser.value && Object.prototype.hasOwnProperty.call(this.parsers, this.parser.value) && this.parsers[this.parser.value].login_urls && Object.prototype.hasOwnProperty.call(this.parsers[this.parser.value].login_urls, this.parserLangKey)
    },
    showAlertDanger () {
      const successRate = this.form_account_data && Object.prototype.hasOwnProperty.call(this.form_account_data, 'parse_result') && this.form_account_data.parse_result && Object.prototype.hasOwnProperty.call(this.form_account_data.parse_result, 'success_rate') ? this.form_account_data.parse_result.success_rate : -1
      return successRate === 0
    },
    parserPostData () {
      const postObject = {
        data: this.account_statement ? this.account_statement : null,
        account_id: this.offsetAccountId ? this.offsetAccountId : null
      }
      if (this.parser.value !== 'auto') {
        Object.assign(postObject, { parser: this.parser.value })
      }
      return postObject
    },
    invalidAccountStatementFeedback () {
      if (this.$v.account_statement.$error && !this.$v.account_statement.required) {
        return this.$i18n.t(this.translationPath + 'required_account_statement')
      } else if (this.$v.account_statement.$error && !this.$v.account_statement.minLength) {
        return this.$i18n.t(this.translationPath + 'minlength_account_statement')
      } else {
        return ''
      }
    },
    invalidParserFeedback () {
      if (this.$v.parser.$error && !this.$v.parser.requiredParser) {
        return this.$i18n.t(this.translationPath + 'required_parser')
      } else if (this.$v.parser.$error && !this.$v.parser.inValidParser) {
        return this.$i18n.t(this.translationPath + 'invalid_parser')
      } else {
        return ''
      }
    },
    parserOptions () {
      let parserMap = this.parserOpt.map(item => {
        return Object.assign({}, { text: this.$te('common.' + item.parser_nice_name) ? this.$t('common.' + item.parser_nice_name) : item.parser_nice_name, value: item.class_name })
      })

      parserMap = parserMap.sort((a, b) => a.text > b.text ? 1 : -1)

      return parserMap
    }
  },
  watch: {
    selectedAccountId: {
      handler: function (value) {
        if (value) {
          this.loadLatestTransaction(value)
        } else {
          this.latestTransaction = null
        }
      }
    },
    account_statement (value) {
      if (value) {
        const covertStatement = value.toString()
        const statementLength = covertStatement.trim().length
        const statementRows = statementLength > 0 ? value.split(/\r\n|\r|\n/).length : 0
        // ROWS EQUAL TO OR GREATER THAN 100 and words is equal to or greater than 10000
        // display warning
        if (statementRows >= 100 && statementLength >= 10000) {
          this.limitToast = true
        } else {
          this.limitToast = false
        }
      } else {
        this.limitToast = false
      }
    },
    defaultParser (newVal) {
      if (newVal) {
        const filterParser = this.parserOptions.filter(item => item.value === newVal)
        if (filterParser.length > 0) {
          this.parser = filterParser[0]
        } else {
          this.parser = { text: this.$t('common.bankparser.auto_parse'), value: 'auto' }
        }
      } else {
        this.parser = { text: this.$t('common.bankparser.auto_parse'), value: 'auto' }
      }
    },
    parser (newVal) {
      if (newVal) {
        this.form_account_data = {
          parse_result: {},
          account_statement: null,
          parsed_account_statement: null,
          addTransaction: [],
          user_transactions: [],
          transactions: [],
          transactions_error: []
        }
      }
    }
  },
  methods: {
    handleInputFileChange ({ target }) {
      if (target.files.length === 0) return
      const file = target.files[0]
      const reader = new FileReader()
      reader.onload = function (e) {
        const csvResult = e.target.result.split(/\r|\n|\r\n/)
        console.log('detected encoding ::: ', jschardet.detect(csvResult.toString()).encoding)
        document.getElementById('file').setAttribute('encoding', jschardet.detect(csvResult.toString()).encoding)
      }
      reader.readAsBinaryString(file)
    },
    loadLatestTransaction (id) {
      axios.get(`${process.env.VUE_APP_ROOT_API}/recent/transaction/${id}`)
        .then((response) => response.data.data)
        .then((response) => {
          const transactions = response[0]
          this.latestTransaction = transactions.length > 0 ? transactions[0] : null
        })
    },
    readFile () {
      const _self = this
      const fileElement = document.getElementById('file')
      if (fileElement.files.length === 0) return
      const fileName = fileElement.files[0].name
      if (fileName.includes('.txt') || fileName.includes('.csv')) {
        const encoding = fileElement.getAttribute('encoding')
        Papa.parse(fileElement.files[0], {
          encoding: encoding,
          quotes: true,
          quoteChar: "'",
          complete: function ({ data, errors, meta }) {
            const { delimiter, linebreak } = meta
            if (errors.length !== 0) {
              _self.$bvToast.toast(_self.$t('common.read_file_error_title'), {
                title: _self.$t('common.read_file_error_message'),
                variant: 'danger',
                solid: true,
                'auto-hide-delay': 3000
              })
              return
            }
            const mapResult = data.map((el) => el.reduce((acc, iner) => `${acc}${delimiter}${iner}`)).join(linebreak)
            _self.account_statement = mapResult
            _self.tabIndex = 0
            _self.$bvToast.toast(_self.$t(_self.translationPath + 'toast.read_file_success_message'), {
              title: _self.$t(_self.translationPath + 'toast.read_file_success_title'),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          }
        })
      } else {
        _self.$bvToast.toast(_self.$t('common.read_file_error_title'), {
          title: _self.$t('common.read_file_error_message'),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
      }
    },
    handleInputChange () {
      this.$v.$reset()
      this.form_account_data = Object.assign(this.form_account_data, {
        parse_result: {},
        account_statement: null,
        parsed_account_statement: null,
        addTransaction: [],
        user_transactions: [],
        transactions: [],
        transactions_error: []
      })
      this.$v.account_statement.$touch()
    },
    setAsDefaultParser () {
      const postData = {
        importable_default_parser: this.parser ? this.parser.value : null
      }
      axios.put(process.env.VUE_APP_ROOT_API + `/accounts/${this.selectedAccountId}`, postData)
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.success_set_default'), {
            title: this.$t(this.translationPath + 'toast.transactions'),
            variant: 'info',
            solid: true
          })
          this.$emit('default-parser-set', postData.importable_default_parser)
          setTimeout(function () {
            this.$v.form.$reset()
          }.bind(this), 2000)
        })
        .catch(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.failed_set_default'), {
            title: this.$t(this.translationPath + 'toast.transactions'),
            variant: 'info',
            solid: true
          })
        })
    },
    async validate () {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.form_account_data.account_statement = this.account_statement
      if (isValid) {
        try {
          // Start parse
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.trying_to_parse_description'), {
            title: this.$t(this.translationPath + 'toast.trying_to_parse_title'),
            variant: 'info',
            solid: true
          })
          const parseResponse = await axios.post(`${process.env.VUE_APP_ROOT_API}/accountant/parse-and-suggest`, this.parserPostData)
          const responseData = parseResponse.status === 200 ? parseResponse.data.data : {}
          // reset paginations
          this.$store.dispatch(SET_SETTINGS_PAGINATION_ROWS_PER_PAGE, 25)
          this.$store.dispatch(SET_IMPORT_WIZARDS_ROWS_PER_PAGE_VIEW, 25)
          this.$store.dispatch(SET_IMPORT_WIZARDS_ROWS_PER_PAGE_ACTIONS, 25)
          if (Object.keys(responseData).length === 0) {
            return false
          }
          // reset edit
          this.$emit('reset-edit', true)
          // property assigned
          const responseObject = Object.prototype.hasOwnProperty.call(responseData, 'data') && responseData.data ? responseData.data : {}
          const parsedTransaction = Object.prototype.hasOwnProperty.call(responseObject, 'with_parse_ok') ? responseObject.with_parse_ok : []
          const parsedError = Object.prototype.hasOwnProperty.call(responseObject, 'with_parse_error') ? responseObject.with_parse_error : []
          const parsedRawData = Object.keys(responseData).filter(item => item !== 'data').map(item => {
            const Obj = {}
            Obj[item] = responseData[item]
            return Obj
          }).reduce((acc, item) => Object.assign(acc, item))

          // assign new values to object
          const mutatedTransactions = parsedTransaction.map(item => {
            const itemType = item.suggested_accounts && Object.prototype.hasOwnProperty.call(item.suggested_accounts, 'primary_suggestion_account_id') && item.suggested_accounts.primary_suggestion_account_id != null && item.suggested_accounts.primary_suggestion_account_id >= 0 ? item.suggested_accounts.suggestions[item.suggested_accounts.primary_suggestion_account_id].transaction_type : item.type
            const itemTypeIdentified = item.is_identified_as_transfer === true ? item.is_identified_as_transfer_type : itemType
            const returnObj = {
              title: item.title,
              date: item.date,
              amount: item.amount ? Number(item.amount) : 0,
              raw_string: item.raw_string,
              balance: item.balance,
              transaction_type: item.transaction_type,
              transaction_category: item.transaction_category,
              duplicate: item.is_potential_duplicate || 0,
              duplicate_transfer: item.is_potential_duplicate_transfer || 0,
              suggestion_account_id: item.suggested_accounts && Object.prototype.hasOwnProperty.call(item.suggested_accounts, 'primary_suggestion_account_id') && item.suggested_accounts.primary_suggestion_account_id != null && item.suggested_accounts.primary_suggestion_account_id >= 0 ? item.suggested_accounts.primary_suggestion_account_id : -1, // See if we got a suggestion id, if yes, set it or else set -1
              type: itemTypeIdentified, // Check if suggestion comes with a suggestion of transcation_type, if yes, set it. Else use the one returned by parser
              accounts: null,
              meta: item.meta || null,
              duplicate_information: item.potential_duplicates || null,
              description: item.description || null
            }

            if (returnObj.suggestion_account_id !== -1 && returnObj.suggestion_account_id === this.offsetAccountId) { // if suggestion id same as selected, then null it.
              Object.assign(returnObj, { suggestion_account_id: -1 })
            }
            const defaultActivePercentage = returnObj.suggestion_account_id > -1 && Object.prototype.hasOwnProperty.call(item.suggested_accounts, 'suggestions') && item.suggested_accounts.suggestions && Object.keys(item.suggested_accounts.suggestions).indexOf(returnObj.suggestion_account_id.toString()) > -1 && Object.prototype.hasOwnProperty.call(item.suggested_accounts.suggestions[returnObj.suggestion_account_id], 'default_active_percentage') && item.suggested_accounts.suggestions[returnObj.suggestion_account_id].default_active_percentage ? item.suggested_accounts.suggestions[returnObj.suggestion_account_id].default_active_percentage : 0
            return Object.assign(returnObj, { percentage: defaultActivePercentage, is_duplicate: returnObj.duplicate === 1, is_duplicate_transfer: returnObj.duplicate_transfer === 1 })
          })

          this.form_account_data = Object.assign({}, this.form_account_data, {
            parsed_account_statement: responseObject,
            parsed_transactions: parsedTransaction,
            transactions: mutatedTransactions,
            transactions_error: parsedError,
            parse_result: parsedRawData
          })
          // check whether the all transaction failed
          // let currentData = new Date()
          const transactionRawData = Object.prototype.hasOwnProperty.call(this.form_account_data, 'parse_result') && this.form_account_data.parse_result && Object.prototype.hasOwnProperty.call(this.form_account_data.parse_result, 'raw_data_string') && this.form_account_data.parse_result.raw_data_string ? this.form_account_data.parse_result.raw_data_string : ''
          // save transaction Object
          const importTitle = this.$t('transactions.import.import_wizard_v2.step4.import_title_default_suggestion_v2', {
            date: moment(new Date()).format('YYYY-MM-DD H:mm:ss'),
            parser: this.form_account_data.parse_result.parser_nice_name === null ? this.form_account_data.parse_result.parser_class_name : this.$t('common.' + this.form_account_data.parse_result.parser_nice_name)
          })
          const createImportObject = {
            account_id: this.offsetAccountId,
            title: importTitle,
            raw_account_statement: transactionRawData,
            used_parser: this.form_account_data.parse_result.parser_class_name,
            success_rate: this.form_account_data.parse_result.success_rate
          }
          const bankStatementResponse = await axios.post(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/create`, createImportObject)
          const bankResponse = bankStatementResponse.status === 201 ? bankStatementResponse.data.data : {}
          if (Object.keys(bankResponse).length === 0) {
            return false
          }
          const importId = Object.prototype.hasOwnProperty.call(bankResponse, 'id') ? bankResponse.id : null
          if (this.form_account_data.parse_result.entries_passed > 0) {
            // toast success message
            const toastSuccessMessage = this.$t(this.translationPath + 'alert.successfully_identified_transactions')
            // toast error message
            const toastErroreMessage = this.$t('transactions.import.import_wizard_v2.step3.all_transcations_could_not_be_parsed_title', { parserName: this.$t('common.' + this.form_account_data.parse_result.parser_nice_name) })
            // toast heading
            const toastHeading = this.form_account_data.parse_result.entries_failed === 0 ? toastSuccessMessage : toastErroreMessage
            // toast message
            const toastMessage = this.$t(this.translationPath + 'alert.successfully_identified_transactions_description', { number: this.form_account_data.parse_result.entries_passed })
            // toast variant
            const toastvariant = this.form_account_data.parse_result.entries_failed === 0 ? 'success' : 'warning'
            // toast
            this.$bvToast.toast(toastMessage, {
              title: toastHeading,
              variant: toastvariant,
              solid: true,
              id: 'parseTransactions',
              autoHideDelay: 5000
            })
          }
          this.$emit('validate-success', Object.assign({}, { import_id: importId }, this.form_account_data))
          this.$emit('reset-edit-state')
          if (this.showAlertDanger) {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.parse_failed_description'), {
              title: this.$t(this.translationPath + 'toast.parse_failed_title'),
              variant: 'danger',
              solid: true
            })
            this.scrollToError()

            return false
          }
          return true
        } catch (err) {
          console.error('Transactions:', err)
          return false
        }
      }
      return isValid
    },
    reset () {
      this.account_statement = null
      if (this.defaultParser) {
        const filterParser = this.parserOptions.filter(item => item.value === this.defaultParser)
        if (filterParser.length > 0) {
          this.parser = filterParser[0]
        } else {
          this.parser = { text: this.$t('common.bankparser.auto_parse'), value: 'auto' }
        }
        // this.parser = this.initial || null
      }
      this.form_account_data = { account_statement: null, parsed_account_statement: null, addTransaction: [], user_transactions: [], transactions: [], transactions_error: [] }
      this.$v.form.$reset()
      this.$emit('reset-edit-state')
    },
    scrollToError (e) {
      e = e || null
      if (e) {
        e.preventDefault()
      }

      if (!this.showAlertDanger) {
        return false
      }

      const errBox = document.getElementById('alert-danger')
      let elem = errBox
      let top = 0
      while (elem) {
        top = top + parseFloat(elem.offsetTop)
        elem = elem.offsetParent
      }

      window.scroll(0, top - 20)
    }
  }
}
</script>

<style lang="scss">
  .import-wizard-step-container.import-wizard-step02 {
    div[role="alert"] a:hover {
      text-decoration: underline;
    }
    .setDefaultButton {
      font-size: 14px;
    }
    .actionButtons:hover {
      .flaticon {
        color: #fff !important;
      }
    }
  }
  .form-body-container label{
    font-weight: 900;
  }
  .form-body-container textarea{
    font-size:75%;
    font-family: "Lucida Console";
  }

  .intro_step_list{padding-left:15px;}
  .intro_step_list li{font-weight: 100;}
  .parserSelect
  {
    .suggestion-list ul li.ember-grouping.empty.active{background: #36b373!important;} // selected value
    .suggestion-list ul li:hover{background: #36b373!important;}
  }
</style>
