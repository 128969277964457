import moment from 'moment'
// Docs: https://momentjs.com/

export default function formatDate (value, locale, format) {
  moment.locale(locale)

  if (format == null) {
    format = 'L'
  }

  return moment(String(value)).format(format)
}
