<template>
  <b-sidebar :id="id" right shadow backdrop>
    <div class="px-3 py-2 text-left">
      <h2>{{ title !== '' ? $t( title ) : '' }}</h2>
      <p v-if="p1 !== ''">{{ $t( p1 ) }}</p>
      <p v-if="p2 !== ''">{{ $t( p2 ) }}</p>
      <p v-if="p3 !== ''">{{ $t( p3 ) }}</p>
      <p>
        <b-link v-if="link_href !== ''" :href="link_href" target="_blank">{{ $t( link_text ) }} →</b-link>
        <b-link v-if="link_to !== ''" :to="link_to">{{ $t( link_text ) }} →</b-link>
      </p>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: 'SpirectaSidebar',
  components: {},
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'common.sidebar.help_title',
      required: false
    },
    p1: {
      type: String,
      default: ''
    },
    p2: {
      type: String,
      default: ''
    },
    p3: {
      type: String,
      default: ''
    },
    link_text: {
      type: String,
      default: ''
    },
    link_href: {
      type: String,
      default: ''
    },
    link_to: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  }
}
</script>
