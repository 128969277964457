<template>
    <b-tab :title="title" class="px-3 pt-3 pb-2">
      <b-col xs="12" class="px-0">
        <h4><slot name="heading"></slot></h4>
        <p class="col-lg-8 pl-0 ml-0">
          <slot name="description"></slot>
        </p>
      </b-col>
      <b-col xs="12" class="px-0">
        <slot name="content"></slot>
      </b-col>
      <slot name="footer"></slot>
    </b-tab>
</template>

<script>
export default {
  name: 'TabBasic',
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
